import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Box, Button, Grid, Typography, Link } from "@mui/material";
import Question from "../mui/Question";
import FormFieldsContainer from "../layout/FormFieldsContainer";
import { handleChoiceSelection, sortChoices } from "../../../utils/textHelper";
import SubForm from "./SubForm";
import { QUESTION_TYPE } from "../../../constants/QuestionConstants";
import NotEligibleDisclaimer from "../NotEligibleDisclaimer";
import DOMPurify from "dompurify";

/**
 * Display questions whenever user clicks on "Next" button and a new payload is returned by backend.
 */
function FormPage(props) {
  const {
    questionSchemaToRender,
    setNextRequestPayload,
    nextRequestPayload,
    handleNext,
    isStateNotEligible,
    allStates,
    userSelectedBrand,
    setUserSelectedBrand,
    notAcceptableError,
    setRecaptchaPool,
  } = props;
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  //useful when the logic requires to disable button even if the above state (nextButtonDisabled) is false. That means even there is a value
  const [nextButtonDisabledForceFully, setNextButtonDisabledForceFully] =
    useState(false);
  const [pageType, setPageType] = useState(null);

  const questions = questionSchemaToRender?.questions;

  const [errorMessages, setErrorMessages] = useState(null);

  useEffect(() => {
    const hasClickableQueAns = questions?.some(
      (requestBody) =>
        requestBody?.questionType === QUESTION_TYPE.CLICKABLE_ANSWER
    );
    if (hasClickableQueAns) {
      const hasOneOrMoreWrongAnswers = nextRequestPayload?.some(
        (requestBody) => {
          const question = questions?.find(
            (requestBody_) => requestBody_?.id === requestBody?.questionId
          );
          const selectedChoiceId = requestBody?.choiceIds[0];
          //If the selected choice id doesn't matches with the correct answer's id --> Next button should be disabled
          return selectedChoiceId !== question?.rightAnswer?.id;
        }
      );
      setNextButtonDisabled(hasOneOrMoreWrongAnswers);
    } else {
      const requiredOnlyFields = nextRequestPayload?.filter((requestBody) => {
        //filter out the optional fields
        const question = questions?.find(
          (requestBody_) => requestBody_?.id === requestBody?.questionId
        );
        return question?.mandatory;
      });
      const hasOneOrMoreUnfilledEntry = requiredOnlyFields?.some(
        (requestBody) => {
          const { value, choiceIds } = requestBody;
          return (
            typeof value !== "boolean" &&
            !Boolean(value) &&
            !Boolean(choiceIds?.length)
          );
        }
      );
      setNextButtonDisabled(hasOneOrMoreUnfilledEntry);
    }
  }, [nextRequestPayload]);

  useEffect(() => {
    setErrorMessages(notAcceptableError); //Array of error messages from consumer validation
  }, [notAcceptableError]);

  /**
   * Handle Form's field change
   * @param questionId the question id the current/choice value is for
   * @param selectedChoice if DROPDOWN or RADIO, this is provided
   * @param value for FREETEXT this is provided
   */
  const handleFormFieldChange = ({
    questionId,
    selectedChoice,
    value,
    field,
  }) => {
    console.log("FIELD", field);
    setNextRequestPayload((prev) =>
      prev?.map((requestBody_) => {
        if (requestBody_?.questionId === questionId) {
          return {
            ...requestBody_,
            choiceIds: selectedChoice ? [selectedChoice] : [],
            value,
            field,
          };
        }
        return requestBody_;
      })
    );
  };

  if (isStateNotEligible)
    return (
      <Box my={4}>
        <NotEligibleDisclaimer userSelectedBrand={userSelectedBrand} />
      </Box>
    );

  return (
    <>
      {errorMessages ? (
        <>
          {errorMessages.map((errorMessage) => {
            return (
              <Box my={4}>
                <Alert severity={"info"} icon={false}>
                  <Typography
                    fontSize={"large"}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(errorMessage.ruleFailReason),
                    }}
                  />
                </Alert>
              </Box>
            );
          })}
          <Link href={"/"}>
            <Button
              sx={{
                background: "#214D94",
                ":hover": { background: "#214D94" },
              }}
              variant={"contained"}
              color={"secondary"}
              disableRipple
            >
              Go to Home Page
            </Button>
          </Link>
        </>
      ) : (
        <FormFieldsContainer
          formElements={
            <>
              {questionSchemaToRender.isForm ? (
                <Grid item xs={12}>
                  <SubForm
                    formSchema={questionSchemaToRender}
                    nextRequestPayload={nextRequestPayload}
                    handleFieldChange={handleFormFieldChange}
                    nextButtonDisabled={nextButtonDisabled}
                    setNextButtonDisabledForceFully={
                      setNextButtonDisabledForceFully
                    }
                    allStates={allStates}
                    setRecaptchaPool={setRecaptchaPool}
                  />
                </Grid>
              ) : (
                questions
                  ?.sort((a, b) =>
                    a?.questionIndex > b?.questionIndex ? 0 : -1
                  )
                  ?.map((data, i) => {
                    const {
                      id,
                      userId,
                      question: label,
                      choices,
                      questionIndex: index,
                      questionType: type,
                      demoForm,
                      rightAnswer,
                      comment,
                      category,
                      field,
                    } = data;

                    return (
                      <Grid item xs={12} key={`question-${id}-${i}`} mb={4}>
                        <Question
                          label={label}
                          type={type}
                          id={id}
                          index={index}
                          choices={sortChoices(choices)} // For every sub question having choices & positions will get sorted before going to Question Landing page.
                          userId={userId}
                          handleChange={(selectedChoice) =>
                            handleChoiceSelection({
                              setNextRequestPayload,
                              nextRequestPayload,
                              questionId: id,
                              field: field,
                              selectedChoice,
                            })
                          }
                          demoForm={demoForm}
                          nextRequestPayload={nextRequestPayload}
                          selectedChoices={
                            nextRequestPayload?.find(
                              (req) => req.questionId === id
                            )?.choiceIds
                          }
                          forcefullyDisabledNextButton={setNextButtonDisabled}
                          rightAnswerId={rightAnswer?.id}
                          comment={comment}
                          category={category}
                          setPageType={setPageType}
                          setUserSelectedBrand={setUserSelectedBrand}
                        />
                      </Grid>
                    );
                  })
              )}
            </>
          }
          actions={
            <Box textAlign={pageType === "END" ? "" : "right"}>
              {pageType !== "END" && (
                <Button
                  disabled={nextButtonDisabled || nextButtonDisabledForceFully}
                  color={"secondary"}
                  variant={"contained"}
                  onClick={handleNext}
                  disableRipple
                >
                  Next
                </Button>
              )}
              {pageType === "END" && (
                <Link href={"/"}>
                  <Button
                    sx={{
                      background: "#214D94",
                      ":hover": { background: "#214D94" },
                    }}
                    variant={"contained"}
                    color={"secondary"}
                    disableRipple
                  >
                    Go to Home Page
                  </Button>
                </Link>
              )}
            </Box>
          }
        />
      )}
    </>
  );
}

FormPage.propTypes = {
  questionSchemaToRender: PropTypes.shape({
    isForm: PropTypes.bool,
    formTitle: PropTypes.string,
    questions: PropTypes.array,
  }),
  nextRequestPayload: PropTypes.array,
  setNextRequestPayload: PropTypes.func,
  handleNext: PropTypes.func,
  isStateNotEligible: PropTypes.bool,
  allStates: PropTypes.array.isRequired,
  userSelectedBrand: PropTypes.string,
  /**
   * Required for the question type DRUG_SELECTION. When user selects any brand, the FE stores that information
   * to later change text from Nalaxone to Narcan or Kloxxado.
   * For more: read function convertLabelToSelectedDrugNameText in component FormMainPage
   */
  setUserSelectedBrand: PropTypes.func,
};

export default FormPage;
