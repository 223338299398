import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import QuotaDataRow from "./quota-data-row.component";
import {useDispatch, useSelector} from "react-redux";
import {AdminDashboardActions} from "../../redux/actions";
import NewQuota from "./quota-new.component";

function QuotaTable() {
  const [isAddNew, setIsAddNew] = useState(false);

  // ignore typescript error for now
  // @ts-ignore
  const {quotas, isLoading} = useSelector((state) => state.adminDashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!quotas?.length) dispatch(AdminDashboardActions.getQuotas());
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="quota table">
          <TableHead sx={{ backgroundColor: "#e0e0e0" }}>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Period</TableCell>
              <TableCell align="center">Limit (units)</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignContent={"center"}
                  >
                    <CircularProgress size={25} color="primary" />
                  </Box>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : quotas?.length ? (
              quotas?.map((row: any) => (
                <QuotaDataRow
                  key={row.state}
                  period={row.quotaPeriod}
                  state={row.state}
                  limit={row.quantity}
                />
              ))
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">
                  <Typography color={"textSecondary"}>
                    Nothing to display
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
            {isAddNew && (
              <NewQuota
                onCancel={() => setIsAddNew(false)}
                onConfirm={() => setIsAddNew(false)}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={4} ml={"auto"} width={"fit-content"} display={"flex"} gap={2}>
        {!isAddNew && (
          <Button variant="outlined" onClick={() => setIsAddNew(true)}>
            Add New
          </Button>
        )}
      </Box>
    </>
  );
}

export default QuotaTable;
