import * as React from "react";
import { theme } from "../../themes/naloxoneRightNow";
import CustomMenu from "./CustomMenu";
import logo from "../../assets/images/logo.svg";
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  styled,
  Link as LinkMui,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  MARKETING_SITE_ABOUT_US,
  MARKETING_SITE_CONTACT_US,
  MARKETING_SITE_NEWS,
  MARKETING_SITE_OPIOIDS,
  MARKETING_SITE_RESOURCES,
  MARKETING_SITE_ROOT_URL,
  MARKETING_SITE_TRAINING,
  TRACK_MY_SHIPMENT,
} from "../../constants/marketingSite";

const horizontalPadding = 32;

const StyledMenuWrap = styled("nav")`
  li {
    list-style: none;
  }
  li + li {
    margin-left: 32px;
  }
  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

const StyledHeaderTop = styled(StyledMenuWrap)`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.secondary.contrastText};
  text-align: right;
  padding: 12px ${horizontalPadding}px 12px 0;
  font-size: ${theme.typography.headerTop.fontSize};
`;

const StyledMobileHeaderTop = styled(StyledHeaderTop)`
  font-size: 16px;
  padding-left: ${horizontalPadding}px;
  display: flex;
  align-items: center;
  justify-content: center;
  li + li {
    margin-left: 24px;
  }
  //For smaller width devices, the word gets break because of less available space
  @media (max-width: 420px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledPrimaryMenuWrap = styled(StyledMenuWrap)`
  li + li {
    margin-left: 48px;
  }
`;

const StyledHeaderBottom = styled("div")`
  color: ${theme.palette.primary.main};
  font-size: ${theme.typography.headerBottom.fontSize};
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  height: 89px;
  align-items: center;
  justify-content: space-between;
  padding-left: ${horizontalPadding}px;
  padding-right: ${horizontalPadding}px;
`;

const StyledHeader = styled("header")`
  border-bottom: 2px solid ${theme.palette.header.bottomBorder};
  position: sticky;
  top: 0;
  right: 0;
  background-color: ${theme.palette.background.default};
  width: 100%;
  z-index: ${theme.zIndex.appBar};
`;

export default function Header() {
  const primaryMenuItems = [
    { label: `Opioids`, href: MARKETING_SITE_OPIOIDS },
    { label: `Training`, href: MARKETING_SITE_TRAINING },
    { label: `Resources`, href: MARKETING_SITE_RESOURCES },
    { label: `About Us`, href: MARKETING_SITE_ABOUT_US },
  ];

  const secondaryMenuItems = [
    { label: `Track My Shipment`, href: TRACK_MY_SHIPMENT },
    { label: `News`, href: MARKETING_SITE_NEWS },
    { label: `Contact Us`, href: MARKETING_SITE_CONTACT_US },
    { label: "GPO Membership", href: "/register" },
    { label: `Log In`, href: "/login" },
  ];

  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setMobileMenuAnchor(null);
  };

  return (
    <StyledHeader>
      {/* Desktop. */}
      <Box sx={{ display: { xs: `none`, md: `block` } }}>
        <StyledHeaderTop>
          <CustomMenu items={secondaryMenuItems} align={`right`} />
        </StyledHeaderTop>
      </Box>

      {/* Mobile. */}
      <Box sx={{ display: { xs: `block`, md: `none` } }}>
        <StyledMobileHeaderTop>
          <CustomMenu items={secondaryMenuItems} align={`right`} />
        </StyledMobileHeaderTop>
      </Box>

      <StyledHeaderBottom>
        <AppBar position={`sticky`} top={0} elevation={0}>
          <Toolbar disableGutters>
            <Stack
              direction={`row`}
              justifyContent={`space-between`}
              sx={{ flex: 1 }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{ a: { display: "flex", alignItems: "center" } }}
              >
                <LinkMui href={MARKETING_SITE_ROOT_URL}>
                  <img
                    src={logo}
                    alt={`Naloxone Right Now logo`}
                    width="240px"
                  />
                </LinkMui>
              </Box>
              {/* Mobile menu. */}
              <Box
                sx={{
                  display: { xs: `block`, lg: `none` },
                  position: `relative`,
                }}
              >
                <IconButton
                  size={`large`}
                  aria-label={`Open mobile menu`}
                  aria-controls={`menu-appbar`}
                  aria-haspopup={true}
                  onClick={handleOpenNavMenu}
                  color={`inherit`}
                  disableRipple
                  sx={{ borderRadius: 0 }}
                >
                  <FontAwesomeIcon icon={faBars} color={`#000`} />
                </IconButton>
                <Menu
                  id={`menu-primary-mobile`}
                  open={Boolean(mobileMenuAnchor)}
                  keepMounted
                  anchorEl={mobileMenuAnchor}
                  anchorOrigin={{ vertical: `bottom`, horizontal: `left` }}
                  onClose={handleCloseNavMenu}
                >
                  {primaryMenuItems.map((item, index) => (
                    <LinkMui key={index} href={item.href} underline="none">
                      <MenuItem onClick={handleCloseNavMenu}>
                        <Typography variant={`body1`}>{item.label}</Typography>
                      </MenuItem>
                    </LinkMui>
                  ))}
                </Menu>
              </Box>
              {/* Desktop menu. */}
              <Box
                sx={{
                  display: { xs: `none`, lg: `flex` },
                  fontWeight: 600,
                  alignItems: `center`,
                }}
              >
                <StyledPrimaryMenuWrap>
                  <CustomMenu items={primaryMenuItems} />
                </StyledPrimaryMenuWrap>
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>
      </StyledHeaderBottom>
    </StyledHeader>
  );
}
