import PropTypes from "prop-types";
import {Box, RadioGroup, Typography} from "@mui/material";
import React, {useState} from "react";
import RadioOptionBox from "../mui/RadioOptionBox";
import DOMPurify from "dompurify";

function ClickableAnswersPage(props) {
  const {choices, selectedChoices, handleChange, rightAnswerId, comment} = props;

  const [displayComment, setDisplayComment] = useState(Boolean(false));
  const [displayAnswerStatus, setDisplayAnswerStatus] = useState("");

  return (
    <div>
      <RadioGroup
        onChange={(e) => {
          const val = parseInt(e?.target?.value);
          const status = val === rightAnswerId;
          if (status) {
            setDisplayComment(false);
            setDisplayAnswerStatus("Correct !");
          } else {
            setDisplayComment(true);
            setDisplayAnswerStatus("Incorrect !");
          }
          handleChange(val, status);
        }}
      >
        <Box spacing={3}>
          {choices?.map((val) => (
            <RadioOptionBox
              key={val?.id}
              value={val?.id}
              label={val?.choice}
              checked={Boolean(
                selectedChoices && selectedChoices?.findIndex((id) => id === val?.id) !== -1
              )}
              answerstatus={displayAnswerStatus}
            />
          ))}
        </Box>
      </RadioGroup>
      <Box mt={2}>
        {displayAnswerStatus !== "" && (
          <Typography variant="h6" component="h2">
            {displayAnswerStatus}
          </Typography>
        )}
        {displayComment && (
          <Box>
            <Typography
              sx={{
                textDecoration: "underline",
                display: "inline",
              }}
              variant="body2"
            >
              Hint:
            </Typography>
            <Typography sx={{display: "inline"}} variant="body2">
              &nbsp;
              <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(comment)}} />
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}

ClickableAnswersPage.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedChoices: PropTypes.array,
  comment: PropTypes.string,
  rightAnswerId: PropTypes.number,
  handleChange: PropTypes.func,
};

export default ClickableAnswersPage;
