import * as React from "react";

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import ScrollToTop from "../hooks/scrollToTop";
import UserManagement from "../pages/user-management";
import LoginPage from "../pages/login";
import Landing from "../pages/assessment-tool/landing";
import { UserManagementActions } from "../redux/actions";
import Welcome from "../pages/assessment-tool/welcome";
import Form from "../pages/assessment-tool/form";
import UserSetup from "../pages/user-setup";
import NotFoundPage from "../pages/errorPages/notFound";
import Progress from "../pages/progress";
import Quotas from "../pages/quotas";
import ReportSubscriptions from "../pages/report-subscriptions";
import RegisterUser from "../pages/register-user";
import BulkOrders from "../pages/bulk-orders";
import Customers from "../pages/customers";
import ConsumerOrders from "../pages/consumer-orders";

const Routes = (props: any) => {
  const authenticated = () => {
    if (props.authUser || sessionStorage.getItem("authUser")) {
      const sessionAuthUser = sessionStorage.getItem("authUser");
      const authUser = JSON.parse(sessionAuthUser || "{}");
      props.setAuthUser(authUser);
      return true;
    }
    return false;
  };

  const getSecureRoute = (path: string, component: any) => {
    if (!authenticated()) return <Redirect to={"/login"} />;
    return <Route exact path={path} component={component} />;
  };

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/register" component={RegisterUser} />
        <Route exact path="/user-setup" component={UserSetup} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/form" component={Form} />
        {getSecureRoute("/user-management", UserManagement)}
        {getSecureRoute("/progress", Progress)}
        {getSecureRoute("/quotas", Quotas)}
        {getSecureRoute("/report-subscriptions", ReportSubscriptions)}
        {getSecureRoute("/consumer-orders", ConsumerOrders)}
        {getSecureRoute("/bulk-orders", BulkOrders)}
        {getSecureRoute("/customers", Customers)}
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
});
const mapDispatchToProps = (dispatch: any) => ({
  setAuthUser: (authUser: any) => UserManagementActions.setAuthUser(authUser),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
