import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import BasePage from "../common/base";
import AdminPageContainer from "../../components/admin-components/page-container";
import { AdminDashboardActions } from "../../redux/actions";
import ActionsMenu from "../../components/actions-menu";
import { Box, Button, FormControl, Grid, MenuItem, Modal } from "@mui/material";
import MUIDataTable from "mui-datatables";
import dataList from "../../constants/dataList";
import Loading from "../../components/loading";
import SearchFilter from "../../components/searchFilter";
import {
  StyledFormControl,
  StyledTextField,
} from "../../hooks/styles/formStyles";

const COLORS = {
  green: "#61d836",
  yellow: "#fff056",
  red: "#ee220c",
  default: "#ffffff",
};

const Progress = (props: any) => {
  const [filter, setFilter] = React.useState<any>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
  const [pagination, setPageState] = React.useState({
    offset: 0,
    limit: 10,
    page: 0,
  });

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((monthName: string, index: number) => {
    return { label: monthName, value: index + 1 };
  });

  let yearOptions: number[] = [];

  for (let i = 2022; i <= new Date().getFullYear() + 1; i++) {
    yearOptions.push(i);
  }

  useEffect(() => {
    filterProgress();
  }, [filter]);

  const filterProgress = () => {
    const filterParams = Object.keys(filter).map((key: string) => {
      return { key: key, value: filter[key] };
    });
    props.getProgress(filterParams);
  };

  const columnData = [
    {
      label: "State",
      name: "state",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Month",
      name: "month",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Year",
      name: "year",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Shipped",
      name: "orderedQty",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Remaining",
      name: "remaining",
      change: true,
      viewColumns: true,
      display: true,
      customBodyRender: (value: any) => {
        const rawValue = value.replace("%", "");
        const thresholdColor = getThresholdColor(rawValue);
        return (
          <Box sx={{ color: thresholdColor }}>
            <b>{value}</b>
          </Box>
        );
      },
    },
  ];

  const threshold = [
    { color: COLORS.green, value: 50 },
    { color: COLORS.yellow, value: 10 },
    { color: COLORS.red, value: 0 },
  ];

  const getThresholdColor = (value: number) => {
    if (value === 0) return COLORS.default;
    const item = threshold.find((x) => value >= x.value);
    return item ? item.color : COLORS.default;
  };

  const formatData = (data: any[]) => {
    return data.map((row: any) => {
      return {
        state: row.state,
        month: row.month,
        year: row.year,
        orderedQty: `${row.orderedQty} of ${row.quotaLimit}`,
        remaining: `${parseFloat(
          ((row.remaining / row.quotaLimit) * 100).toFixed(2)
        )}%`,
      };
    });
  };

  const getTableColumns = () => {
    return columnData.map((column: any, index: number) => {
      return {
        label: column.label,
        name: column.name,
        options: {
          filter: true,
          display: tableStatePersist
            ? tableStatePersist.columns[index].display
            : column.display,
          change: column.change,
          sort: column.sort ?? true,
          viewColumns: column.viewColumns,
          ...(column.customBodyRender && {
            customBodyRender: column.customBodyRender,
          }),
        },
      };
    });
  };

  const showLoadingMsg = () => !props.authUser && props.progress.length === 0;

  return (
    <BasePage title={"Progress"}>
      <AdminPageContainer>
        <div>
          {showLoadingMsg() ? (
            <div>
              <Loading message="" />
            </div>
          ) : (
            <div>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledFormControl>
                      <StyledTextField
                        variant="outlined"
                        id="user-role-selector"
                        value={filter.month}
                        placeholder="Month"
                        label="Month"
                        select
                        onChange={(event: any) => {
                          const value = event.target.value;
                          setFilter({ ...filter, month: value });
                        }}
                      >
                        {monthOptions.map((option: any, index: number) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </StyledTextField>
                    </StyledFormControl>
                    <StyledFormControl>
                      <StyledTextField
                        variant="outlined"
                        id="user-role-selector"
                        value={filter.year}
                        placeholder="Year"
                        label="Year"
                        select
                        onChange={(event: any) => {
                          const value = event.target.value;
                          setFilter({ ...filter, year: value });
                        }}
                      >
                        {yearOptions.map((option: any, index: number) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </StyledTextField>
                    </StyledFormControl>
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginTop: 20 }}>
                <MUIDataTable
                  data={formatData(props.progress || [])}
                  columns={getTableColumns()}
                  options={{
                    rowsPerPage: pagination.limit,
                    rowsPerPageOptions: dataList.PageLimitOptions,
                    onChangeRowsPerPage: (numberOfRows: number) => {
                      setPageState({ ...pagination, limit: numberOfRows });
                    },
                    filterType: "dropdown",
                    responsive: "simple",
                    filter: false,
                    search: false,
                    download: true,
                    print: true,
                    selectableRows: "none",
                    serverSide: false,
                    page: pagination.page,
                    count: props.progress?.length || 0,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </AdminPageContainer>
    </BasePage>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  orders: state.adminDashboard.consumerOrders
    ? state.adminDashboard.consumerOrders
    : [],
  progress: state.adminDashboard.progress ? state.adminDashboard.progress : [],
  products: state.adminDashboard.products ? state.adminDashboard.products : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getProgress: (filter: any) =>
    dispatch(AdminDashboardActions.getProgress(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
