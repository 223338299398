import React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {UserManagementActions} from "../../../redux/actions";
import {capitalize} from "@mui/material";

const MenuListComposition = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [authenticatedUser, setAuthenticatedUser] = React.useState({} as any);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    //if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //return;
    //}
    setOpen(false);
  };
  const handleLogout = () => {
    props.logout(history);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      // setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      //anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open, props]);

  React.useEffect(() => {
    if (!props.authUser) {
      const sessionAuthUser = sessionStorage.getItem("authUser");
      const authUser = JSON.parse(sessionAuthUser || "{}");
      setAuthenticatedUser(authUser);
      props.setAuthUser(authUser);
    }
    setAuthenticatedUser(props.authUser);
  }, [props.authUser]);

  const firstName = capitalize(authenticatedUser?.firstName || "");
  const lastName = capitalize(authenticatedUser?.lastName || "");

  return (
    <div>
      <Button
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ color: "white" }}
      >
        <Avatar>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Avatar>
        <MenuItem ref={anchorRef}>{`${firstName} ${lastName}`}</MenuItem>
        <ArrowDropDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
});
const mapDispatchToProps = (dispatch: any) => ({
  setAuthUser: (payload: any) => dispatch(UserManagementActions.setAuthUser(payload)),
  logout: (history: any) => dispatch(UserManagementActions.logout(history)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuListComposition);
