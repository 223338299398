import React from "react";
import {Box, FormControl, FormLabel, Grid, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";
import FormFieldsContainer from "../layout/FormFieldsContainer";
import {sortChoices} from "../../../utils/textHelper";
import NotEligibleDisclaimer from "../NotEligibleDisclaimer";

/**
 * Component dedicated only and only to render the state selection dropdown. That is the first question of form.
 */
function StateSelectorPage(props) {
  const {stateSelectorQuestion, handleStateSelection, selectedStateCode, isStateNotEligible} =
    props;

  const {question: ssQuestion, choices: ssChoices, id: ssQuestionId} = stateSelectorQuestion ?? {};

  /**
   * At beginning of every component/page  the choices will be sorted according to the choicePositions returned from BE
   * function  sortChoices(choices, positions) does the sort...
   * */
  const sortedChoices = sortChoices(ssChoices);

  const handleStateDropdownChange = (e) => {
    handleStateSelection({
      userId: null,
      questionId: ssQuestionId,
      choice: e?.target?.value,
    });
  };

  return (
    <FormFieldsContainer
      formElements={
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth>
            <FormLabel id={ssQuestion}>{ssQuestion}</FormLabel>
            <Select
              labelId={ssQuestion}
              onChange={handleStateDropdownChange}
              value={selectedStateCode ?? ""}
              sx={{maxWidth: 400}}
            >
              {sortedChoices?.map((ssChoice) => {
                const {id, choice: code} = ssChoice;
                return (
                  <MenuItem key={id} value={id}>
                    {code}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {isStateNotEligible && (
            <Box my={4}>
              <NotEligibleDisclaimer />
            </Box>
          )}
        </Grid>
      }
    />
  );
}

StateSelectorPage.propTypes = {
  stateSelectorQuestion: PropTypes.shape({
    question: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    questionType: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  handleStateSelection: PropTypes.func.isRequired,
  selectedStateCode: PropTypes.string,
  isStateNotEligible: PropTypes.bool,
};

export default StateSelectorPage;
