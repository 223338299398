const error4xx = new RegExp("4..");
const error5xx = new RegExp("5..");

let networkStats = "ONLINE";

if (typeof window !== "undefined") {
  networkStats = window.navigator.onLine ? "ONLINE" : "OFFLINE";
}

/**
 * Extract content from the API errored response
 * @param error errors promise
 * @returns {object} error {message, code}
 */
export function errorExtractor(error) {
  const {response} = error ?? {};

  const errorResponse = {
    //response to send back
    message: "",
    code: null,
  };

  if (response) {
    const {status} = error.response;
    errorResponse.code = status;
    // if 4xx
    if (error4xx.test(status.toString())) {
      errorResponse.message =
        response?.data?.message ?? "Failed to Submit Request. Please try again.";
    }
    //if 5xx
    else if (error5xx.test(status.toString())) {
      errorResponse.message =
        response?.data?.message ?? "We've encountered an error. Please try again later.";
    }
  } else {
    if (networkStats === "OFFLINE")
      errorResponse.message = "You are offline, please check your internet connection.";
    else errorResponse.message = `Something went wrong. Please try again.`;
  }

  return errorResponse;
}
