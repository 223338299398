import {alpha} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {buttonClasses} from "@mui/material/Button";
import {toggleButtonClasses} from "@mui/material/ToggleButton";
import fonts from "./fonts";

const defaultTheme = createTheme();

/**
 * Helpers we will use in the theme for calculating font size and line height.
 */
const body = {
  body1: 22,
  body2: 18,
  lineHeight: 1.73,
};
const headings = {
  // In pixels.
  h1: 60,
  h2: 52,
  h3: 42,
  h4: 36,
  h5: 32,
  h6: 26,
  // Shared styling.
  common: {
    color: `#133257`,
    ...fonts.segoeUi.bold,
  },
  // Used only as a multiplier ratio.
  lineHeight: 1.3334,
};

export const margins = {
  gutterBottom: 42,
};

export const paddings = {
  inputIndent: 16,
  labelLeftPosition: 4,
  headerLeftRight: 32,
};

export const positions = {
  label: {
    left: 2,
    top: 14,
  },
};

export const theme = createTheme({
  typography: {
    ...fonts.segoeUi.regular,
    useNextVariants: true,
    headerTop: {
      fontSize: `18px`,
    },
    headerBottom: {
      fontSize: `22px`,
    },
    body1: {
      color: `#000`,
      fontSize: `${body.body1}px`,
      lineHeight: `${body.body1 * body.lineHeight}px`,
    },
    body2: {
      color: `#000`,
      fontSize: `${body.body2}px`,
      lineHeight: `${body.body2 * body.lineHeight}px`,
    },
    h1: {
      ...headings.common,
      fontSize: `${headings.h1}px`,
      lineHeight: `${headings.h1 * headings.lineHeight}px`,
    },
    h2: {
      ...headings.common,
      fontSize: `${headings.h2}px`,
      lineHeight: `${headings.h2 * headings.lineHeight}px`,
    },
    h3: {
      ...headings.common,
      fontSize: `${headings.h3}px`,
      lineHeight: `${headings.h3 * headings.lineHeight}px`,
    },
    h4: {
      ...headings.common,
      fontSize: `${headings.h4}px`,
      lineHeight: `${headings.h4 * headings.lineHeight}px`,
    },
    h5: {
      ...headings.common,
      fontSize: `${headings.h5}px`,
      lineHeight: `${headings.h5 * headings.lineHeight}px`,
    },
    h6: {
      ...headings.common,
      fontSize: `${headings.h6}px`,
      lineHeight: `${headings.h6 * headings.lineHeight}px`,
    },
    subtitle1: {
      color: `#00ADE1`,
      fontSize: `32px`,
      fontWeight: 500,
      lineHeight: 1,
    },
    subtitle2: {
      color: `#00ADE1`,
      fontSize: `26px`,
      fontWeight: 500,
      lineHeight: 1,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          fonts.segoeUi.regular,
          fonts.segoeUi.bold,
          fonts.segoeUi.boldItalic,
          fonts.segoeUi.italic,
        ],
      },
    },
  },
  palette: {
    primary: {
      main: `#133257`,
      light: `rgba(255, 255, 255, 0.7)`,
      contrastText: `#FFF`,
    },
    secondary: {
      main: `#94C83D`,
      contrastText: `#FFF`,
    },
    header: {
      bottomBorder: `#E3E3E3`,
    },
    action: {
      disabledBackground: ``,
      disabled: ``,
    },
    background: {
      paper: `#FFF`,
      default: `#FFF`,
    },
    error: {
      main: `#D74040`,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          b: {
            color: `#133257`,
          },
        },
        gutterBottom: {
          marginBottom: `${margins.gutterBottom}px`,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          margin: 0,
          padding: 0,
        },
        body: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: `${paddings.inputIndent}px`,
        },
        root: {
          minHeight: `65px`,
          fontSize: `20px`,
          lineHeight: `32px`,
          fontWeight: 500,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: `20px`,
          fontWeight: 500,
          lineHeight: `32px`,
          color: `#133257`,
        },
        root: {
          minWidth: `110px`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingLeft: `${paddings.inputIndent}px`,
        },
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
            border: `2px solid #E3E3E3`,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `2px solid #133257`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: `none`,
          textTransform: `none`,
          padding: `15px 32px 18px 32px`,
          [defaultTheme.breakpoints.down("md")]: {
            minWidth: `160px`,
          },
          [defaultTheme.breakpoints.up("md")]: {
            minWidth: `238px`,
          },
          fontSize: `25px`,
          lineHeight: 1,
          fontWeight: 500,
          minHeight: 0,
          [`&.${buttonClasses.outlined}`]: {
            borderRadius: `35px`,
            border: `2px solid #E3E3E3`,
            fontSize: `20px`,
            lineHeight: 1,
            padding: `17px ${paddings.inputIndent}px 20px ${paddings.inputIndent}px`,
            //height: `64px`,
          },
          [`&.${buttonClasses.sizeSmall}`]: {
            padding: `10px`,
            fontSize: "16px",
            fontWeight: 400,
          },
          [`&.${buttonClasses.sizeMedium}`]: {
            fontSize: "20.5px",
          },
          [`&.${buttonClasses.sizeLarge}`]: {
            fontSize: "25px",
          },
          [`&.${buttonClasses.contained}`]: {
            borderRadius: `35px`,
          },
          [`&.${buttonClasses.text}`]: {
            borderRadius: `35px`,
          },
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.7,
          },
          // Fix ButtonGroup disabled styles.
          [`&.${toggleButtonClasses.root}.${buttonClasses.disabled}`]: {
            color: defaultTheme.palette.action.disabled,
            borderColor: defaultTheme.palette.action.disabledBackground,
          },
        },
        text: {
          "&:hover": {
            backgroundColor: `transparent`,
          },
        },
        textInherit: {
          "&:hover": {
            color: `#72a94e`,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: `24px`,
          fontWeight: 600,
          color: `#133257`,
          marginBottom: `${margins.gutterBottom * 0.5}px`,
          display: `block`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            marginBottom: 0,
            fontSize: `20px`,
            color: `#707070`,
            opacity: 0.5,
            fontWeight: 600,
          },
          "& .MuiFormLabel-root.MuiInputLabel-shrink": {
            fontSize: `14px`,
            top: `${positions.label.top}px`,
            left: `${positions.label.left}px`,
          },
          "& .MuiOutlinedInput-notchedOutline legend": {
            display: `none`,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            fontSize: `20px`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiMenu-paper": {
            boxShadow: defaultTheme.shadows[2],
            "&::-webkit-scrollbar": {
              width: `10px`,
            },
            "&::-webkit-scrollbar-track": {
              background: alpha(defaultTheme.palette.action.selected, 0.07),
            },
            "&::-webkit-scrollbar-thumb": {
              background: defaultTheme.palette.action.selected,
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 5,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 600,
          fontSize: `20px`,
          color: `#133257`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          fontSize: 32,
        },
        root: {
          fontStyle: `italic`,
        },
        standardError: {
          backgroundColor: `#FBEFEF`,
          color: `#E27474`,
        },
      },
    },
    MuiAppBar: {
      // TODO change background
      // TODO remove margins
      styleOverrides: {
        root: {
          background: `#FFF`,
          height: `auto`,
          color: `#133257`,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          fontSize: "16px",
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontSize: "16px",
        },
      },
    },
  },
  shadows: [
    `none`,
    `rgba(149, 157, 165, 0.2) 0 1px 3px`,
    `rgba(149, 157, 165, 0.2) 0 4px 12px`,
    `rgba(149, 157, 165, 0.2) 0 6px 18px`,
    `rgba(149, 157, 165, 0.2) 0 8px 24px`,
    `rgba(149, 157, 165, 0.2) 0 10px 30px`,
    `rgba(149, 157, 165, 0.2) 0 12px 36px`,
    `rgba(149, 157, 165, 0.2) 0 14px 42px`,
    `rgba(149, 157, 165, 0.2) 0 16px 48px`,
    `rgba(149, 157, 165, 0.2) 0 18px 54px`,
    `rgba(149, 157, 165, 0.2) 0 20px 60px`,
    `rgba(149, 157, 165, 0.2) 0 22px 66px`,
    `rgba(149, 157, 165, 0.2) 0 24px 72px`,
    `rgba(149, 157, 165, 0.2) 0 26px 78px`,
    `rgba(149, 157, 165, 0.2) 0 28px 84px`,
    `rgba(149, 157, 165, 0.2) 0 30px 90px`,
    `rgba(149, 157, 165, 0.2) 0 32px 96px`,
    `rgba(149, 157, 165, 0.2) 0 34px 102px`,
    `rgba(149, 157, 165, 0.2) 0 36px 108px`,
    `rgba(149, 157, 165, 0.2) 0 38px 114px`,
    `rgba(149, 157, 165, 0.2) 0 40px 120px`,
    `rgba(149, 157, 165, 0.2) 0 42px 126px`,
    `rgba(149, 157, 165, 0.2) 0 44px 132px`,
    `rgba(149, 157, 165, 0.2) 0 46px 138px`,
    `rgba(149, 157, 165, 0.2) 0 48px 144px`,
  ],
});
