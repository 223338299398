import React, {useEffect} from "react";
import {MenuItem, Select} from "@mui/material";
import {AdminDashboardService} from "../../../redux/services/admin-dashboard.service";
import {toast} from "react-toastify";
import { US_STATES } from "../../../constants/Constants";

function SelectState(props: any) {
  const usStates: any = US_STATES;
  const [states, setStates] = React.useState(
    Object.keys(usStates).map((stateCode: string) => ({
      code: stateCode,
      name: usStates[stateCode],
    }))
  );
  const { userSelectedState, setUserSelectedState } = props;

  return (
    <Select
      value={userSelectedState}
      label="State"
      onChange={(e) => setUserSelectedState(e.target.value)}
      fullWidth
    >
      {states?.map((state, idx) => (
        <MenuItem key={idx} value={state.code}>
          {state.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectState;
