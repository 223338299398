import React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { StyledModal } from "../../hooks/styles";
import Loading from "../loading";
import { StyledFormControl } from "../../hooks/styles/formStyles";
import { theme } from "../../themes/naloxoneRightNow";

interface DeleteModalProps {
  deleteId: any;
  title: any;
  message: any;
  deleteLabel?: string;
  cancelLabel?: string;
  onDelete: (payload: any) => void;
  onSuccess: () => void;
  closeModal: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleCancel = () => {
    props.closeModal();
  };

  const handleDelete = () => {
    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      props.onSuccess();
      props.closeModal();
    };

    const onError = () => {
      setIsLoading(false);
    };

    const payload = {
      deleteId: props.deleteId,
      success: onSuccess,
      error: onError,
    };

    props.onDelete(payload);
  };

  return (
    <StyledModal>
      <div>
        <h3 id="simple-modal-title">{props.title}</h3>
        <p>{props.message}</p>
        <Grid container>
          {isLoading ? (
            <Loading message="" />
          ) : (
            <Grid item xs={12}>
              <StyledFormControl>
                <Button
                  onClick={handleDelete}
                  type="button"
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="error"
                >
                  {" "}
                  {props.deleteLabel ?? "DELETE"}{" "}
                </Button>
              </StyledFormControl>
              <StyledFormControl>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  variant="contained"
                >
                  {" "}
                  {props.cancelLabel ?? "CANCEL"}{" "}
                </Button>
              </StyledFormControl>
            </Grid>
          )}
        </Grid>
      </div>
    </StyledModal>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
