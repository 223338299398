import { call, put, select } from "redux-saga/effects";
import { UIActions, UserManagementActions } from "../actions";
import { UserManagementService } from "../services/user-management.service";
import { FirebaseService } from "../services/firebase.service";
import authClient from "../../auth/ping";
import { toast } from "react-toastify";

export function* getUsers(
  action: ReturnType<typeof UserManagementActions.getUsers>
) {
  try {
    const { data } = yield call(
      UserManagementService.getInstance().getUsers,
      action.payload
    );
    yield put(UserManagementActions.setUsers(data));
  } catch (err) {
    yield put(UserManagementActions.setUsers(null));
    if (err instanceof Error) {
      // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
      if (err.message === "JWT_EXPIRED") {
        yield put(UIActions.setSessionExpired(true));
      }
    }
  }
}

export function* inviteUser(
  action: ReturnType<typeof UserManagementActions.inviteUser>
) {
  try {
    yield call(
      UserManagementService.getInstance().inviteUser,
      action.payload.requestBody
    );
    action.payload.success();
  } catch (err) {
    action.payload.error();
    if (err instanceof Error) {
      // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
    }
  }
}

export function* updateUser(
  action: ReturnType<typeof UserManagementActions.updateUser>
) {
  try {
    yield call(
      UserManagementService.getInstance().updateUser,
      action.payload.requestBody
    );
    action.payload.success();
  } catch (err) {
    action.payload.error();
    console.log("Update User Saga Error:", err);
  }
}

export function* deleteUser(
  action: ReturnType<typeof UserManagementActions.deleteUser>
) {
  try {
    yield call(
      UserManagementService.getInstance().deleteUser,
      action.payload.deleteId
    );
    action.payload.success();
  } catch (err) {
    console.log("DELETE USER SAGA ERROR:", err);
    action.payload.error();
  }
}

export function* setupUser(
  action: ReturnType<typeof UserManagementActions.sendUserSetup>
) {
  try {
    yield put(UIActions.showLoader(true));
    yield put(UserManagementActions.setUserSetupError(null));
    yield call(UserManagementService.getInstance().setupUser, action.payload);
    window.location.href = "/";
    yield put(UIActions.showLoader(false));
  } catch (err: any) {
    const errorBody = err?.response?.data || "Unknown Error";
    console.log("User setup saga error:", errorBody);
    yield put(UserManagementActions.setUserSetupError(errorBody));
    yield put(UIActions.showLoader(false));
  }
}

export function* login(
  action: ReturnType<typeof UserManagementActions.login>
): any {
  try {
    // Get ping access token from auth code
    const tokenResponse = yield authClient.getAccessToken(
      action.payload.pingCode
    );
    const accessToken = tokenResponse.access_token;
    sessionStorage.setItem("pingToken", accessToken);
    // Get custom firebase token from user service
    const { data } = yield call(UserManagementService.getInstance().login, {
      accessToken: accessToken,
    });
    let authUser = data;
    const customToken = authUser.customToken;
    delete authUser.customToken;
    const { token, refreshToken } = yield call(
      FirebaseService.getInstance().getToken,
      customToken
    );
    authUser.apiToken = token;
    authUser.refreshToken = refreshToken;
    yield put(UserManagementActions.setAuthUser(authUser));
    sessionStorage.setItem("authUser", JSON.stringify(authUser));
    yield put(UIActions.setSessionExpired(false));
    action.payload.onSuccess(authUser);
  } catch (err) {
    console.error("LOGIN ERROR:", err);
    const thisError: any = Object.assign({}, err);
    let errorMessage: string = "";
    if (thisError.message) {
      errorMessage = thisError.message;
    }
    if (thisError.response) {
      errorMessage = thisError.response.data;
    }
    if (thisError.error_description) {
      errorMessage = thisError.error_description;
    }
    action.payload.onError(errorMessage);
  }
}

export function* refreshSession(
  action: ReturnType<typeof UserManagementActions.refreshSession>
): any {
  try {
    let authUser = yield select((state) => state.userManagement.authUser);
    console.log("REFRESH SESSION FOR", authUser.email);
    const token = yield call(
      FirebaseService.getInstance().refreshToken,
      authUser.refreshToken
    );
    authUser.apiToken = token;
    yield put(UserManagementActions.setAuthUser(authUser));
    sessionStorage.setItem("authUser", JSON.stringify(authUser));
    yield put(UIActions.setSessionExpired(false));
  } catch (err) {
    if (action.payload?.onError) {
      action.payload.onError();
    } else {
      yield put(UIActions.setSessionExpired(true));
    }
    console.log("REFRESH SESSION ERROR", err);
    // let errorMessage = err.response.data
  }
}

export function* logout(
  action: ReturnType<typeof UserManagementActions.logout>
): any {
  const backendPoller = yield select((state) => state.ui.backendPoller);
  clearInterval(backendPoller);
  sessionStorage.removeItem("authUser");
  yield authClient.signOff();
  const url = window.location.pathname;
  sessionStorage.setItem("url", url);
  // yield (window.location.href = "/");
}

export function* pingUserService(
  action: ReturnType<typeof UserManagementActions.pingUserService>
): any {
  try {
    let authUser = yield select((state) => state.userManagement.authUser);
    const { data } = yield call(
      UserManagementService.getInstance().getUserById,
      authUser.id
    );
    const refreshedAuthUser = { ...authUser, ...data };
    yield put(UserManagementActions.setAuthUser(refreshedAuthUser));
    sessionStorage.setItem("authUser", JSON.stringify(refreshedAuthUser));
  } catch (err) {
    const thisErrorString: any = JSON.stringify(err);
    const thisErrorObj = JSON.parse(thisErrorString);
    const message = thisErrorObj.message || "";
    if (message === "Request failed with status code 401") {
      yield put(UIActions.setSessionExpired(true));
    }
  }
}

export function* resendInvite(
  action: ReturnType<typeof UserManagementActions.resendInviteEmail>
) {
  try {
    yield put(UIActions.showLoader(true));
    yield call(
      UserManagementService.getInstance().resendInvite,
      action.payload
    );
    yield put(UIActions.showLoader(false));
    toast.success("Invite Email Sent");
  } catch (err: any) {
    const errorBody = err?.response?.data || "Unknown Error";
    console.log("Resend user invite saga error:", errorBody);
    yield put(UIActions.showLoader(false));
  }
}

export function* setPassword(
  action: ReturnType<typeof UserManagementActions.setOneTimePassword>
) {
  try {
    yield put(UIActions.showLoader(true));
    yield call(UserManagementService.getInstance().setPassword, action.payload);
    yield put(UIActions.showLoader(false));
    toast.success("Password Reset");
  } catch (err: any) {
    const errorBody = err?.response?.data || "Unknown Error";
    yield put(UIActions.showLoader(false));
  }
}

export function* register(
  action: ReturnType<typeof UserManagementActions.sendRegistrationForm>
) {
  try {
    yield put(UIActions.showLoader(true));
    yield call(
      UserManagementService.getInstance().register,
      action.payload.requestBody
    );
    yield put(UIActions.showLoader(false));
    toast.success("Your application has been submitted!");
    action.payload.success();
  } catch (err: any) {
    console.error("REGISTRATON SAGA ERROR", err);
    const errorBody = err?.response?.data || "Unknown Error";
    yield put(UIActions.showLoader(false));
    toast.error("An error occurred. Your application was not submitted.");
    action.payload.error();
  }
}
