import React from "react";
import PropTypes from "prop-types";
import {FormControlLabel, Radio, styled} from "@mui/material";
import {paddings} from "../../../themes/naloxoneRightNow";
import RightIcon from "@mui/icons-material/CheckOutlined";
import WrongIcon from "@mui/icons-material/ClearOutlined";
import {Box} from "@mui/system";

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({theme, checked, answerstatus, ...props}) => ({
    ".MuiRadio-root": {
      display: "none",
    },
    flex: 0.9,
    cursor: "pointer",
    padding: `10px ${paddings.inputIndent}px 10px ${paddings.inputIndent}px`,
    fontSize: "20px",
    marginLeft: 0,
    border:
      (checked && answerstatus !== "Incorrect !") === true
        ? `2px solid ${theme.palette.success.main}`
        : (checked && answerstatus === "Incorrect !") === true
        ? `2px solid ${theme.palette.error.main}`
        : `2px solid #E3E3E3`,
    background:
      (checked && answerstatus !== "Incorrect !") === true
        ? `rgba(19, 50, 87, 0.04)`
        : (checked && answerstatus === "Incorrect !") === true
        ? `rgba(19, 50, 87, 0.04)`
        : "transparent",
    borderRadius: 5,
    minWidth: 350,
    // height: 64,
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    ":hover": {
      background: `rgba(19, 50, 87, 0.04)`,
    },
  })
);

function RadioOptionBox(props) {
  const {label, value, disabled, checked, answerstatus} = props;
  return (
    <Box display={"flex"} alignItems="center" minWidth={"100%"}>
      <StyledFormControlLabel
        label={label}
        value={value}
        control={<Radio />}
        checked={checked}
        disabled={disabled}
        answerstatus={answerstatus}
        {...props}
      />
      <Box flex={0.1} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
        {checked && answerstatus === "Incorrect !" && <WrongIcon color="error" />}
        {checked && answerstatus === "Correct !" && <RightIcon color="success" />}
      </Box>
    </Box>
  );
}

RadioOptionBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  answerstatus: PropTypes.string,
};

export default RadioOptionBox;
