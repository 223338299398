import React from 'react';
import {styled} from "@mui/material";

const StyledFormActions = styled("div")`
  border-top: 2px solid #e3e3e3;
  padding: 40px 0;
  text-align: right;
`;

export default function FormActions({children}) {
  return <StyledFormActions>{children}</StyledFormActions>;
}
