import * as React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import { theme } from "../../../themes/naloxoneRightNow";
import { useRef } from "react";
import Header from "../../../components/assessment-tool-components/Header";
import MainContent from "../../../components/assessment-tool-components/layout/MainContent";
import Footer from "../../../components/assessment-tool-components/Footer";
import { ToastContainer } from "react-toastify";

export default function AssessmentToolBase(props: any) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position={"top-center"}
          hideProgressBar={false}
          closeOnClick={true}
          autoClose={12000}
        />
        <Header />
        <MainContent>{props.children}</MainContent>
        <Footer />
      </ThemeProvider>
    </>
  );
}
