import { Constants } from "../constants/Constants";
export default {
  CabinetType: ["EM", "RFID", "Virtual"],
  Actions: [
    { "label": "INSERT", "value": "INSERT" },
    { "label": "REMOVAL", "value": "REMOVAL" },
    { "label": "NO USAGE", "value": "NO_USAGE" },
    { "label": "REVIEW REQD", "value": "REVIEW_REQD" },
    { "label": "VEND", "value": "VEND" },
  ],
  Reasons: [
    { "label": "REFILL", "value": "REFILL" },
    { "label": "PRE PAY", "value": "PRE PAY" },
    { "label": "VEND", "value": "VEND" },
    { "label": "UNDO VEND", "value": "UNDO_VEND" },
    { "label": "REVIEW REQD", "value": "REVIEW_REQD" },
    { "label": "RECEIPT", "value": "RECEIPT" },
    { "label": "COMPLETE", "value": "COMPLETE" },
    { "label": "SHORTAGE", "value": "SHORTAGE" },
    { "label": "OVERAGE", "value": "OVERAGE" },
    { "label": "PENDING", "value": "PENDING" },
    { "label": "RE-INSERT", "value": "RE-INSERT" },
    { "label": "UNKNOWN", "value": "UNKNOWN" },
    { "label": "INTERNAL TRANSFER", "value": "INTERNAL TRANSFER" },
    { "label": "INVENTORY SYNC", "value": "INVENTORY SYNC" },
  ],
  Invoiced: [
    { "label": "INVOICED", "value": "INVOICED" },
    { "label": "NOT INVOICED", "value": "NOT INVOICED" },
  ],
  SetupStatus: [
    { "label": "Complete", "value": "1" },
    { "label": "Incomplete", "value": "0" }
  ],
  CustomerSearchFilters: [
    //{ "label": Constants.SEARCH.CABINET_ID, "value": "CABINET_ID" },
    { "label": Constants.SEARCH.STATE, "value": "STATE" },
    { "label": Constants.SEARCH.ZIP_CODE, "value": "ZIP_CODE" },
    { "label": Constants.SEARCH.CREATED_DATE, "value": "CREATED_DATE" },
    //{"label":"Cancel Date","value":"CANCEL_DATE"}
  ],

  UserSearchFilters: [
    { "label": Constants.SEARCH.FIRST_NAME, "value": "FIRST_NAME" },
    { "label": Constants.SEARCH.LAST_NAME, "value": "LAST_NAME" },
    { "label": Constants.SEARCH.EMAIL, "value": "EMAIL" },
  ],

  IncidentSeverity: [
    { label: 'High', value: 'high' },
    { label: 'Low', value: 'low' },
  ],

  IncidentStatus: [
    { label: 'Open', value: 'Open' },
    { label: 'Acknowledged', value: 'Acknowledged' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Ignored', value: 'Ignored' },
  ],

  InventorySearchFilters: [
    { "label": Constants.SEARCH.CABINET_ID, "value": "CABINET_ID" },
    { "label": Constants.SEARCH.PRODUCT_NAME, "value": "PRODUCT_NAME" },
    { "label": Constants.SEARCH.LOT, "value": "LOT" },
  ],
  CabinetSearchFilters: [
    { "label": Constants.SEARCH.CABINET_TYPE, "value": "TYPE" },
    { "label": Constants.SEARCH.CABINET_STATE, "value": "STATE" },

  ],
  CabinetTypeDropdownList: [{ "label": "MinibarRx", "value": "EM" }, { "label": "VIPc", "value": "RFID" }, { "label": "Virtual", "value": "Virtual" }],
  OrderTypeDropdownList: [{ "label": "MinibarRx", "value": "MBRX" }, { "label": "VIPc", "value": "VIPc" }, { "label": "Virtual", "value": "Virtual" }],
  InvoicePageFilters: [
    { "label": Constants.SEARCH.INVOICE_DATE, "value": "INVOICE_DATE" },
    //{ "label": Constants.SEARCH.PAYMENT_STATUS, "value": "PAYMENT_STATUS" },
  ],
  HistoryPageFilters: [
    { "label": Constants.SEARCH.CABINET_ID, "value": "CABINET_ID" },
    { "label": Constants.SEARCH.CABINET_TYPE, "value": "CABINET_TYPE" },
    { "label": Constants.SEARCH.LOT, "value": "LOT_NUMBER" },
    { "label": Constants.SEARCH.PRODUCT_NAME, "value": "PRODUCT_ID" },
  ],
  TransactionLogPageFilters: [
    { "label": Constants.SEARCH.CABINET_ID, "value": "CABINET_ID" },
    { "label": Constants.SEARCH.LOT, "value": "LOT_NUMBER" },
    { "label": "Product", "value": "PRODUCT_NAME" },
    { "label": Constants.SEARCH.ACTION, "value": "ACTION" },
    { "label": Constants.SEARCH.REASON, "value": "REASON" },
    { "label": Constants.SEARCH.INVOICED, "value": "INVOICED" },
    { "label": Constants.SEARCH.SERIAL_NUMBER, "value": "SERIAL_NUMBER" },
  ],
  AlertLevels: [
    { "label": "None", "value": "None" },
    { "label": "Notice", "value": "Notice" },
    { "label": "Info", "value": "Info" },
    { "label": "Warning", "value": "Warning" },
    { "label": "Error", "value": "Error" },
    { "label": "Fatal", "value": "Fatal" },
  ],
  PageLimitOptions: [10, 20, 30, 50, 100],
  FilterConfig: {
    'INVENTORY': {
      'CUSTOMER_NAME': {},
      'CABINET_TYPE': {},
      'EXPIRY_DATE': {},
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID'
        },
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name'
        },
        'LOT_NUMBER': {
          name: 'LOT_NUMBER',
          displayName: 'Lot Number'
        },
        'SERIAL_NUMBER': {
          name: 'SERIAL_NUMBER',
          displayName: 'Serial Number'
        }
      }
    },
    'PAR': {
      'CUSTOMER_NAME': {}
    },
    'INVENTORY_HISTORY': {
      'CUSTOMER_NAME': {},
      'HISTORY_DATE': {},
      'CABINET_TYPE': { multiple: true }
    },
    'TRANSACTION_LOG': {
      'CUSTOMER_NAME': {},
      'DATE_RANGE': {
        required: true
      },
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID'
        },
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name'
        },
        'LOT_NUMBER': {
          name: 'LOT_NUMBER',
          displayName: 'Lot Number'
        },
        'ACTION': {
          name: 'ACTION',
          displayName: 'Action'
        },
        'REASON': {
          name: 'REASON',
          displayName: 'Reason'
        },
        'SERIAL_NUMBER': {
          name: 'SERIAL_NUMBER',
          displayName: 'Serial Number'
        }
      }
    },
    'DISPENSED_PRODUCTS': {
      'CUSTOMER_NAME': {
        required: true
      },
      'DATE_RANGE': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name'
        },
        'LOT_NUMBER': {
          name: 'LOT_NUMBER',
          displayName: 'Lot Number'
        }
      },
      'SHOW_EXPORT_BUTTON': true
    },
    'WAREHOUSE_SHIPMENTS': {
      'CUSTOMER_NAME': {
        required: true
      },
      'DATE_RANGE': {
        required: true
      },
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name'
        },
        'ORDER_NUMBER': {
          name: 'ORDER_NUMBER',
          displayName: 'Order Number',
        },
        'WAREHOUSE': {
          name: 'WAREHOUSE',
          displayName: 'Warehouse',
        }
      },
      'SHOW_EXPORT_BUTTON': true
    },
    'CABINET_DETAILS': { //aka "Cabinet List" page
      'CUSTOMER_NAME': {},
      'CABINET_PROPERTIES': {
        name: 'CABINET_PROPERTIES',
        displayName: 'Cabinet Properties'
      },
      'MORE_FILTERS': {
        'CABINET_TYPE': {
          name: 'CABINET_TYPE',
          displayName: 'Cabinet Type'
        },
        'CABINET_STATE': { //aka "status"
          name: 'CABINET_STATE',
          displayName: 'Cabinet State'
        },
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID'
        }
      }
    },
    'CABINET_TEMPERATURE': {
      'CUSTOMER_NAME': {
        required: true
      },
      'DATE_RANGE': {
        required: true
      },
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
          autoShow: true,
          required: true
        }
      }
    },
    'RESTOCK_ORDERS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name',
        },
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
        },
        'ORDER_TYPE': {
          name: 'ORDER_TYPE',
          displayName: 'Order Type',
        },
        'ORDER_STATUS': {
          name: 'ORDER_STATUS',
          displayName: 'Order Status',
          //autoShow: true
        },
        'ORDER_NUMBER': {
          name: 'ORDER_NUMBER',
          displayName: 'Order Number',
        }
      }
    },
    'RESTOCK_ORDERS_GROUPED': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
        },
        'ORDER_TYPE': {
          name: 'ORDER_TYPE',
          displayName: 'Order Type',
        },
        'GROUPED_ORDER_STATUS': {
          name: 'ORDER_STATUS',
          displayName: 'Order Status'
        },
        'ORDER_NUMBER': {
          name: 'ORDER_NUMBER',
          displayName: 'Order Number',
        }
      }
    },
    'RESTOCK_RECOMMENDATIONS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
          autoShow: true
        }
      }
    },
    'SHIPMENTS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'WAREHOUSE': {
          name: 'WAREHOUSE',
          displayName: 'Warehouse',
          autoShow: true
        },
        'SHIPMENT_STATUS': {
          name: 'SHIPMENT_STATUS',
          displayName: 'Shipment Status',
          autoShow: true
        }
      }
    },
    'INCIDENTS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'INCIDENT_SEVERITY': {
          name: 'INCIDENT_SEVERITY',
          displayName: 'Severity',
          autoShow: true
        },
        'INCIDENT_STATUS': {
          name: 'INCIDENT_STATUS',
          displayName: 'Status',
          autoShow: true
        },
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
          autoShow: true
        },
        'ALERT_TYPE': {
          name: 'ALERT_TYPE',
          displayName: 'Alert Type',
          autoShow: true
        }
      }
    },
    'TELEMETRY': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
          autoShow: true
        },
        'TELEMETRY_ALERT_LEVEL': {
          name: 'TELEMETRY_ALERT_LEVEL',
          displayName: 'Alert Level',
          autoShow: true
        },
      }
    },
    'ADMIN_USERS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'USER_FIRST_NAME': {
          name: 'USER_FIRST_NAME',
          displayName: 'First Name'
        },
        'USER_LAST_NAME': {
          name: 'USER_LAST_NAME',
          displayName: 'Last Name'
        },
        'USER_EMAIL': {
          name: 'USER_EMAIL',
          displayName: 'Email'
        },
      }
    },
    'ADMIN_CUSTOMERS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'CUSTOMER_STATE': {
          name: 'CUSTOMER_STATE',
          displayName: 'State'
        },
        'CUSTOMER_ZIP_CODE': {
          name: 'CUSTOMER_ZIP_CODE',
          displayName: 'Zip Code'
        }
      }
    },
    'ADMIN_PRODUCTS': {
      'MORE_FILTERS': {
        'PRODUCT_NAME': {
          name: 'PRODUCT_NAME',
          displayName: 'Product Name',
          autoShow: true
        },
        // 'SALES_TYPE': {
        //   name: 'SALES_TYPE',
        //   displayName: 'Sales Type',
        //   autoShow: true
        // },
        'DOSE_TYPE': {
          name: 'DOSE_TYPE',
          displayName: 'Dose Type',
          autoShow: true
        }
      }
    },
    'ADMIN_SUSPENDED_TRANSACTIONS': {
      'CUSTOMER_NAME': {},
      'MORE_FILTERS': {
        'CABINET_ID': {
          name: 'CABINET_ID',
          displayName: 'Cabinet ID',
          autoShow: true
        },
        'TRANSACTION_STATUS': {
          name: 'TRANSACTION_STATUS',
          displayName: 'Transaction Status',
          autoShow: true
        },
        'TRANSACTION_TYPE': {
          name: 'TRANSACTION_TYPE',
          displayName: 'Transaction Type',
          autoShow: true
        }
      }
    },
    'INVOICE': {
      'CUSTOMER_NAME': {
        required: true
      },
      'DATE_RANGE': {},
      'MORE_FILTERS': {}
    },
    'AUDITLOG': {
      'CUSTOMER_NAME': {},
      'DATE_RANGE': {},
      'MORE_FILTERS': {
        'RESOURCE_TYPE': {
          name: 'RESOURCE_TYPE',
          displayName: 'Resource Type',
          autoShow: true
        },
        'INITIATOR_TYPE': {
          name: 'INITIATOR_TYPE',
          displayName: 'Initiator Type',
          autoShow: true
        },
      },
    },
    'SAVE_SEARCH': {
      'TITLE': {},
    }
  },

  //which filter tools are available on each page
  DefaultFilterVals: {
    'CABINET_ID': '',
    'PRODUCT_ID': '',
    'LOT_NUMBER': '',
    'ACTION': '',
    'REASON': '',
    'INVOICED': '',
    'SERIAL_NUMBER': '',
    'PRODUCT_NAME': '',
    'CABINET_TYPE': '',
    'CABINET_STATE': '',
    'ORDER_STATUS': '',
    'SHIPMENT_STATUS': '',
    'WAREHOUSE': '',
    'INCIDENT_STATUS': '',
    'INCIDENT_SEVERITY': '',
    'TELEMETRY_ALERT_LEVEL': '',
    'USER_FIRST_NAME': '',
    'USER_LAST_NAME': '',
    'USER_EMAIL': '',
    'CUSTOMER_SETUP_STATUS': '',
    'CUSTOMER_STATE': '',
    'CUSTOMER_ZIP_CODE': '',
    'CUSTOMER_CREATED_DATE': '',
    'SALES_TYPE': '',
    'DOSE_TYPE': '',
    'TRANSACTION_STATUS': '',
    'ORDER_TYPE': '',
    'TRANSACTION_TYPE': ''
  },

  DateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-DD" },
    { "label": "YY-MM-DD", "value": "YY-MM-DD" },
    { "label": "MM-DD-YYYY", "value": "MM-DD-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-DD-YY" },
    { "label": "DD-MM-YYYY", "value": "DD-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "DD-MM-YY" },
  ],

  PickerDateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-dd" },
    { "label": "YY-MM-DD", "value": "yy-MM-dd" },
    { "label": "MM-DD-YYYY", "value": "MM-dd-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-dd-yy" },
    { "label": "DD-MM-YYYY", "value": "dd-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "dd-MM-yy" },
  ],

  TimeFormats: [
    { "label": "24 Hour", "value": "HH:mm:ss zz" },
    { "label": "12 Hour", "value": "hh:mm:ssA zz" },
  ],

  IgnoreDurations: [
    { "label": "15 Minutes", "value": "15" },
    { "label": "30 Minutes", "value": "30" },
    { "label": "60 Minutes", "value": "60" },
    { "label": "120 Minutes", "value": "120" },
  ],

  ConnectivityStatus: [
    { "label": "OFFLINE (<15min)", "value": "2" },
    { "label": "OFFLINE", "value": "3" },
    { "label": "ONLINE", "value": "4" },
  ],

  TransActionStatuses: [
    { label: "Approved", value: 'Approved' },
    { label: "Denied", value: 'Deny' },
    { label: "Ignored", value: 'Ignore' },
    { label: "Suspended", value: 'Suspended' }
  ],

  SalesTypes: [
    { label: 'FBB', value: 'FBB' },
    { label: 'FCP', value: 'FCP' }
  ],

  ShipmentStatuses: [
    { label: "Success", value: 'Success' },
    { label: "Pending", value: 'Pending' },
    { label: "Error", value: 'Error' },
    { label: "N/A", value: 'NA' }
  ],

  Warehouses: [
    { label: 'NCDC', value: 'NCDC' },
    { label: 'SCDC', value: 'SCDC' },
    { label: 'TXDC', value: 'TXDC' }
  ],

  DefaultInventories: [
    { "label": "Lot Inventory", "value": "LOT" },
    { "label": "Cartridge Inventory", "value": "CARTRIDGE" },
    { "label": "Serial Inventory", "value": "SERIAL" },
  ],

  ResourceTypes: [
    { label: 'CABINET', value: 'CABINET' },
    { label: 'CABINET PRODUCT', value: 'CABINET PRODUCT' },
    { label: 'CABINET PROVIDER', value: 'CABINET PROVIDER' },
    { label: 'CABINET USER', value: 'CABINET USER' },
    { label: 'CHANNEL GROUP', value: 'CHANNEL GROUP' },
    { label: 'CUSTOMER ACCOUNT', value: 'CUSTOMER ACCOUNT' },
    { label: 'ORDER', value: 'ORDER' },
    { label: 'PERSONA', value: 'PERSONA' },
    { label: 'PRODUCT', value: 'PRODUCT' },
    { label: 'USER', value: 'USER' },
  ],

  InitiatedByList: [
    { "label": "MACHINE", "value": "MACHINE" },
    { "label": "USER", "value": "USER" }
  ],

  SaveSearchPages: [
    { "label": "INVENTORY", "value": "INVENTORY" },
    { "label": "PAR", "value": "PAR" },
    { "label": "INVENTORY HISTORY", "value": "INVENTORY_HISTORY" },
    { "label": "TRANSACTION LOG", "value": "TRANSACTION_LOG" },
    { "label": "DISPENSED PRODUCTS", "value": "DISPENSED_PRODUCTS" },
    { "label": "WAREHOUSE SHIPMENTS", "value": "WAREHOUSE_SHIPMENTS" },
    { "label": "CABINET DETAILS", "value": "CABINET_DETAILS" },
    { "label": "CABINET TEMPERATURE", "value": "CABINET_TEMPERATURE" },
    { "label": "RESTOCK ORDERS", "value": "RESTOCK_ORDERS" },
    { "label": "RESTOCK RECOMMENDATIONS", "value": "RESTOCK_RECOMMENDATIONS" },
    { "label": "INVOICE", "value": "INVOICE" },
    { "label": "SHIPMENTS", "value": "SHIPMENTS" },
    { "label": "INCIDENTS", "value": "INCIDENTS" },
    { "label": "TELEMETRY", "value": "TELEMETRY" },
    { "label": "ADMIN USERS", "value": "ADMIN_USERS" },
    { "label": "ADMIN CUSTOMERS", "value": "ADMIN_CUSTOMERS" },
    { "label": "ADMIN PRODUCTS", "value": "ADMIN_PRODUCTS" },
    { "label": "ADMIN SUSPENDED TRANSACTIONS", "value": "ADMIN_SUSPENDED_TRANSACTIONS" },
    { "label": "AUDITLOG", "value": "AUDITLOG" }
  ],

  TransactionTypes: [
    { "label": "Cycle Count", "value": "CYCLE COUNT" },
    { "label": "Insert", "value": "INSERT" },
    { "label": "Removal", "value": "REMOVAL" },
  ],
  ScheduledReportTemplates: [
    { "label": "Dispensed Report", "value": "DispensedReport" },
    //{ "label": "Provider Report", "value": "ProviderReport" },
    //{ "label": "Shipment Report", "value": "ShipmentReport" },
    { "label": "Temperature Extract Report", "value": "TemperatureExtractReport" },
    { "label": "Current Inventory Report", "value": "CurrentInventoryReport" },
    { "label": "Restock Recommendations Report", "value": "RestockRecommendationsReport" },
  ],
  ScheduledReportFormats: [
    { "label": "csv", "value": "csv" },
    { "label": "pdf", "value": "pdf" }
  ],
  ScheduledReportDeliveryMethods: [
    { "label": "email", "value": "Email" },
    //{ "label": "SMS", "value": "SMS" }
  ],
  ScheduledReportDeliveryFrequencies: [
    { "label": "Daily", "value": "Daily" },
    { "label": "Weekly", "value": "Weekly" },
    { "label": "Monthly", "value": "Monthly" }
  ],
  DayOfWeekChoices: [
    { "label": "Monday", "value": 1 },
    { "label": "Tuesday", "value": 2 },
    { "label": "Wednesday", "value": 3 },
    { "label": "Thursday", "value": 4 },
    { "label": "Friday", "value": 5 },
    { "label": "Saturday", "value": 6 },
    { "label": "Sunday", "value": 7 },
  ],
  DayOfWeekMap: {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday"
  },
}


