import { call, put, select } from "redux-saga/effects";
import { FormStepsActions } from "../actions";
import { FormStepsService } from "../services/form-steps.service";
import { toast } from "react-toastify";
import { errorExtractor } from "../../utils/httpErrorHelpers";
import { DtcUserValidationError } from "../lib/errors";

export function* submitFormStep(
  action: ReturnType<typeof FormStepsActions.submitFormStep>
): any {
  let responseError: any = null;
  try {
    toast.dismiss();

    let currentStep = yield select((state) => state.formSteps.number);
    if (!currentStep) {
      currentStep = 1;
    } else {
      currentStep++;
    }

    let answers = yield select((state) => state.formSteps.answers);
    if (!answers) {
      answers = [];
    }
    const payloadAnswers = action.payload.requestBody;
    answers = [...answers, ...payloadAnswers];

    if (currentStep === 2) {
      const stateAnswer = answers.find(
        (answer: any) => answer.field === "state"
      );
      const selectedState = stateAnswer.choiceIds[0];
      const remainingUnitsResponse = yield call(
        FormStepsService.getInstance().getQuotaRemainingUnits,
        selectedState
      );
      yield put(
        FormStepsActions.setUnitsRemaining(
          remainingUnitsResponse.data[0].remaining
        )
      );
    }

    if (currentStep === 6) {
      let consumerOrderPayload: any = {};
      answers.forEach((answer: any) => {
        if (answer.field) {
          let value = answer.value || answer.choiceIds[0];
          consumerOrderPayload[answer.field] = value;
        }
      });

      const validConsumerResponse = yield call(
        FormStepsService.getInstance().dtcValidateUser,
        {
          firstName: consumerOrderPayload.firstName,
          lastName: consumerOrderPayload.lastName,
          phone: consumerOrderPayload.phone,
          email: consumerOrderPayload.email,
          dateOfBirth: consumerOrderPayload.dob,
          gender: consumerOrderPayload.gender,
          address: consumerOrderPayload.personalAddress,
          address2: consumerOrderPayload.personalAddress2 || undefined, // Not required
          state: consumerOrderPayload.state,
          county: consumerOrderPayload.shippingCounty || undefined, // Not required
          city: consumerOrderPayload.personalCity,
          zipCode: consumerOrderPayload.personalZip,
          shipToAddressType: consumerOrderPayload.shippingAddressType,
          shipToAddress: consumerOrderPayload.shippingAddress,
          shipToAddress2: consumerOrderPayload.shippingAddress2 || undefined, // Not required
          shipToState: consumerOrderPayload.state,
          shipToCounty: consumerOrderPayload.shippingCounty || undefined, // Not required
          shipToCity: consumerOrderPayload.shippingCity,
          shipToZipCode: consumerOrderPayload.shippingZip,
        }
      );

      consumerOrderPayload.consumerId = validConsumerResponse.consumerId;

      yield put(FormStepsActions.setConsumerOrderPayload(consumerOrderPayload));
    }

    yield put(FormStepsActions.setFormAnswers(answers));

    if (currentStep === 11) {
      let consumerOrderPayload = yield select(
        (state) => state.formSteps.consumerOrderPayload
      );
      yield call(FormStepsService.getInstance().submitConsumerOrder, {
        consumerId: consumerOrderPayload.consumerId,
        requestReason: consumerOrderPayload.requestReason,
        ndc: consumerOrderPayload.ndc,
        qty: consumerOrderPayload.qty,
        why: consumerOrderPayload.why,
      });
    }

    let remainingUnits = yield select(
      (state) => state.formSteps.remainingUnits
    );

    const stepQuestions = yield call(
      FormStepsService.getInstance().getFormStep,
      currentStep,
      answers,
      { remainingUnits: remainingUnits }
    );

    yield put(FormStepsActions.setFormStep(currentStep));

    yield put(FormStepsActions.setFormStepResponse(stepQuestions));
  } catch (err) {
    console.error(err);
    yield put(FormStepsActions.setFormStepResponse(null));
    if (err instanceof DtcUserValidationError) {
      responseError = err.messages;
    } else {
      const { code, message } = <any>errorExtractor(err);
      toast.error(message);
    }
  }
  console.log("RESPONSE ERROR", responseError);
  action.payload.onComplete(responseError);
}

export function* submitConsumerOrder(
  action: ReturnType<typeof FormStepsActions.submitConsumerOrder>
) {
  try {
    yield call(
      FormStepsService.getInstance().submitConsumerOrder,
      action.payload
    );
  } catch (err) {
    toast.error("An error occurred while submitting your order.");
    if (err instanceof Error) {
      // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
    }
  }
}
