import {action} from "typesafe-actions";
import {ADMIN_DASHBOARD} from "../constants/actions";
import {LoadingQuotasPayload, QuotaUpdatePayload} from "../../interfaces/quota";

export const getQuotas = () => action(ADMIN_DASHBOARD.GET_QUOTAS);
export const setQuotas = (payload: any) => action(ADMIN_DASHBOARD.SET_QUOTAS, payload);
export const setQuotasLoading = (payload: LoadingQuotasPayload) =>
  action(ADMIN_DASHBOARD.SET_QUOTAS_LOADING, payload);
export const createQuota = (payload: any) => action(ADMIN_DASHBOARD.CREATE_QUOTA, payload);
export const deleteQuota = (state: string) =>
  action(ADMIN_DASHBOARD.DELETE_QUOTA, state);
export const setIsDeletingQuota = (isDeleting: boolean) =>
  action(ADMIN_DASHBOARD.DELETING_QUOTA, isDeleting);
export const updateQuota = (payload: QuotaUpdatePayload) =>
  action(ADMIN_DASHBOARD.UPDATE_QUOTA, payload);
export const setIsUpdatingQuota = (isUpdating: boolean) =>
  action(ADMIN_DASHBOARD.DELETING_QUOTA, isUpdating);

// Progress Tab
export const getProgress = (payload: any) =>
  action(ADMIN_DASHBOARD.GET_PROGRESS, payload);
export const setProgress = (payload: any) => action(ADMIN_DASHBOARD.SET_PROGRESS, payload);
export const setProgressLoading = (isLoading: boolean) =>
  action(ADMIN_DASHBOARD.SET_PROGRESS_LOADING, isLoading);

// Customers
export const addCustomer = (payload: any) =>
  action(ADMIN_DASHBOARD.ADD_CUSTOMER, payload);
export const getCustomers = (payload: any) =>
  action(ADMIN_DASHBOARD.GET_CUSTOMERS, payload);
export const setCustomers = (payload: any) =>
  action(ADMIN_DASHBOARD.SET_CUSTOMERS, payload);

// Products
export const setProducts = (payload: any) => action(ADMIN_DASHBOARD.SET_PRODUCTS, payload);

// US States
export const setUsStates = (payload: any) => action(ADMIN_DASHBOARD.SET_US_STATES, payload);

// Orders
export const getBulkOrders = (payload: any) =>
  action(ADMIN_DASHBOARD.GET_BULK_ORDERS, payload);
export const setBulkOrders = (payload: any) =>
  action(ADMIN_DASHBOARD.SET_BULK_ORDERS, payload);
export const createBulkOrder = (payload: any) =>
  action(ADMIN_DASHBOARD.CREATE_BULK_ORDER, payload);
export const updateBulkOrder = (payload: any) =>
  action(ADMIN_DASHBOARD.UPDATE_BULK_ORDER, payload);
export const getConsumerOrders = (payload: any) =>
  action(ADMIN_DASHBOARD.GET_CONSUMER_ORDERS, payload);
export const setConsumerOrders = (payload: any) =>
  action(ADMIN_DASHBOARD.SET_CONSUMER_ORDERS, payload);