import { action } from "typesafe-actions";
import { FORM_STEPS } from "../constants/actions";

export const submitFormStep = (payload: any) =>
  action(FORM_STEPS.GET_STEP_QUESTIONS, payload);
export const setFormStepResponse = (payload: any) =>
  action(FORM_STEPS.SET_STEP_QUESTIONS, payload);
export const setFormStep = (payload: any) =>
  action(FORM_STEPS.SET_STEP, payload);
export const setFormAnswers = (payload: any) =>
  action(FORM_STEPS.SET_ANSWERS, payload);
export const setConsumerOrderPayload = (payload: any) =>
  action(FORM_STEPS.SET_CONSUMER_ORDER_PAYLOAD, payload);
export const submitConsumerOrder = (payload: any) =>
  action(FORM_STEPS.SUBMIT_CONSUMER_ORDER, payload);
  export const setUnitsRemaining = (payload: any) =>
    action(FORM_STEPS.SET_UNITS_REMAINING, payload);
