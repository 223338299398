import config from "../../config";

export const API_BASE_URL = {
  PRIVATE: config.privateApi.url,
};

export const FORM_STEPS = {
  URL: `${API_BASE_URL.PRIVATE}/assessment-tool/form-id`,
};

export const USER = {
  URL: `${API_BASE_URL.PRIVATE}/user`,
};

export const ADMIN_SERVICE = {
  URL: `${API_BASE_URL.PRIVATE}/admin-service`,
};

export const ADMIN = {
  URL: `${API_BASE_URL.PRIVATE}/admin`,
};
