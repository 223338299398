export const UI = {
  NAVIGATE: "UI_NAVIGATE",
  NAVIGATE_RESPONSE: "UI_NAVIGATE_RESPONSE",
  SHOW_LOADER: "SHOW_LOADER",
  SET_API_ERROR: "SET_API_ERROR",
  SET_NAV_DRAWER: "SET_NAV_DRAWER",
  SET_URL_FILTER: "SET_URL_FILTER",
  SET_NAV_STATE: "SET_NAV_STATE",
  AUTH_PROCESSING: "AUTH_PROCESSING",
  SET_GLOBAL_ROWS_PER_PAGE: "SET_GLOBAL_ROWS_PER_PAGE",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  PRELOAD_DATA: "PRELOAD_DATA",
  SET_CUSTOMERS: "SET_PRELOADED_CUSTOMERS",
  SET_BACKEND_POLLER: "SET_BACKEND_POLLER",
  SET_PRELOADED_SERVICE_TYPES: "SET_PRELOADED_SERVICE_TYPES",
};

export const FORM_STEPS = {
  GET_STEP_QUESTIONS: "GET_STEP_QUESTIONS",
  SET_STEP_QUESTIONS: "SET_STEP_QUESTIONS",
  SET_STEP: "SET_STEP",
  SET_ANSWERS: "SET_ANSWERS",
  SET_CONSUMER_ORDER_PAYLOAD: "SET_CONSUMER_ORDER_PAYLOAD",
  SUBMIT_CONSUMER_ORDER: "SUBMIT_CONSUMER_ORDER",
  SET_UNITS_REMAINING: "SET_UNITS_REMAINING",
};

export const USER_MANAGEMENT = {
  LIST_REQUEST: "GET_USER_LIST_REQUEST",
  LIST_RESPONSE: "GET_USER_LIST_RESPONSE",
  INVITE_USER: "INVITE_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  LOGIN: "LOGIN",
  SET_AUTH_USER: "SET_AUTH_USER",
  REFRESH_SESSION: "REFRESH_SESSION",
  REFRESH_SESSION_IN_BACKGROUND: "REFRESH_SESSION_IN_BACKGROUND",
  LOGOUT: "LOGOUT",
  PING_USER_SERVICE: "PING_USER_SERVICE",
  SETUP_USER: "SETUP_USER",
  SETUP_ERROR: "SETUP_ERROR",
  RESEND_INVITE: "RESEND_INVITE",
  SET_PASSWORD: "SET_PASSWORD",
  SEND_REGISTRATION_FORM: "SEND_REGISTRATION_FORM",
};

export const ADMIN_DASHBOARD = {
  GET_QUOTAS: "GET_QUOTAS",
  SET_QUOTAS: "SET_QUOTAS",
  SET_QUOTAS_LOADING: "SET_QUOTAS_LOADING",
  CREATE_QUOTA: "CREATE_QUOTA",
  UPDATE_QUOTA: "UPDATE_QUOTA",
  DELETE_QUOTA: "DELETE_QUOTA",
  DELETING_QUOTA: "DELETING_QUOTA",
  UPDATING_QUOTA: "UPDATING_QUOTA",
  GET_PROGRESS: "GET_PROGRESS",
  SET_PROGRESS: "SET_PROGRESS",
  SET_PROGRESS_LOADING: "SET_PROGRESS_LOADING",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  SET_CUSTOMERS: "SET_CUSTOMERS",
  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_BULK_ORDERS: "GET_BULK_ORDERS",
  SET_BULK_ORDERS: "SET_BULK_ORDERS",
  GET_CONSUMER_ORDERS: "GET_CONSUMER_ORDERS",
  SET_CONSUMER_ORDERS: "SET_CONSUMER_ORDERS",
  CREATE_BULK_ORDER: "CREATE_BULK_ORDER",
  UPDATE_BULK_ORDER: "UPDATE_BULK_ORDER",
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_US_STATES: "SET_US_STATES",
};
