import React from "react";
import PropTypes from "prop-types";
import {TextField} from "@mui/material";
import {DatePicker as DatePickerMui} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

function DatePicker(props) {
  const {name, id, value, label, onChange, error, size, required, helperText} = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerMui
        label={label}
        inputFormat={"MM/dd/yyyy"}
        value={value}
        id={id}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            name={name}
            id={id}
            fullWidth
            size={size}
            required={required}
            {...params}
            helperText={helperText}
            error={error}
            InputLabelProps={{disableAnimation: true}}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}

DatePicker.defaultProps = {
  size: "medium",
};

DatePicker.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
};

export default DatePicker;
