import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Alert, Box} from "@mui/material";
import useOnScreen from "../../hooks/useOnScreen";

function PdfViewer({pdfUrl, onCompleteRead}) {
  const ref = useRef(null);
  const [isIframeReady, setIframeReady] = useState(false);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible && isIframeReady) {
      onCompleteRead();
    }
  }, [isVisible]);

  return (
    <Box width={"100%"} height={"100%"}>
      <Box my={2}>
        <Alert severity={"info"}>
          To activate the next button, please read the complete document and scroll the page till
          bottom.
        </Alert>
      </Box>
      <Box
        component={"iframe"}
        id={"iframe"}
        src={pdfUrl}
        width={{xs: "100%", sm: "500px", lg: "700px", xl: "900px"}}
        height={"100vh"}
        onLoad={() => setIframeReady(true)}
      />
      {/* A dummy element below iframe added to that if the user has scrolled, the Next button can be activated */}
      <Box ref={ref} height={100} />
    </Box>
  );
}

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  onCompleteRead: PropTypes.func.isRequired,
};

export default PdfViewer;
