import { ADMIN, ADMIN_SERVICE, API_BASE_URL } from "../constants/api.url";
import Api from "../lib/api";

export class AdminDashboardService {
  private static instance: AdminDashboardService;
  private constructor() {}

  public static getInstance(): AdminDashboardService {
    if (!AdminDashboardService.instance) {
      AdminDashboardService.instance = new AdminDashboardService();
    }
    return AdminDashboardService.instance;
  }

  public getCustomers = async (params: any[] = []): Promise<any> => {
    return await Api.get(`${API_BASE_URL.PRIVATE}/customers`, params);
  };

  public addCustomer = async (customerId: string): Promise<any> => {
    return await Api.post(
      `${API_BASE_URL.PRIVATE}/customers/${customerId}`,
      {}
    );
  };

  public getQuotas = async (): Promise<any> => {
    return await Api.get(`${API_BASE_URL.PRIVATE}/quotas`);
  };

  public getQuotasByStateCode = async (code: string): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/quotas/state/${code}`);
  };

  public getQuotasByProductId = async (productId: number): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/quotas/product/${productId}`);
  };

  public getQuotaById = async (id: number): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/quotas/id/${id}`);
  };

  public createQuota = async (payload: any): Promise<any> => {
    return await Api.post(`${API_BASE_URL.PRIVATE}/quotas`, payload);
  };

  public updateQuota = async (payload: any): Promise<any> => {
    return await Api.patch(
      `${API_BASE_URL.PRIVATE}/quotas/${payload.state}`,
      payload
    );
  };

  public deleteQuota = async (state: string): Promise<any> => {
    return await Api.delete(`${API_BASE_URL.PRIVATE}/quotas/${state}`, {});
  };

  public getBulkOrders = async (params: any[] = []): Promise<any> => {
    return await Api.get(`${API_BASE_URL.PRIVATE}/bulk-orders`, params);
  };

  public getConsumerOrders = async (params: any[] = []): Promise<any> => {
    return await Api.get(`${API_BASE_URL.PRIVATE}/consumer-orders`, params);
  };

  public getOrdersByStateCode = async (code: string): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/orders/state/${code}`);
  };

  public getOrdersProgress = async (filter: any[]): Promise<any> => {
    return await Api.get(`${API_BASE_URL.PRIVATE}/orders/progress`, filter);
  };

  public getQOrdersByProductId = async (productId: number): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/orders/product/${productId}`);
  };

  public getOrderById = async (id: number): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/orders/id/${id}`);
  };

  public getOrdersByGpoUser = async (gpoUserEmail: string): Promise<any> => {
    return await Api.get(
      `${ADMIN_SERVICE.URL}/orders/gpo-user/${gpoUserEmail}`
    );
  };

  public createBulkOrder = async (payload: any): Promise<any> => {
    return await Api.post(`${API_BASE_URL.PRIVATE}/bulk-orders`, payload);
  };

  public updateBulkOrder = async (payload: any): Promise<any> => {
    return await Api.patch(`${API_BASE_URL.PRIVATE}/bulk-orders`, payload);
  };

  public getProducts = async (): Promise<any> => {
    return await Api.get(`${API_BASE_URL.PRIVATE}/products`);
  };

  public searchProductsByName = async (productName: string): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/products/search/${productName}`);
  };

  public getProductById = async (id: number): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/products/id/${id}`);
  };

  public getUsStates = async (): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/usstates?size=999`);
  };

  public searchUsStatesByName = async (name: string): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/usstates/search/${name}`);
  };

  public getUsStateByCode = async (code: string): Promise<any> => {
    return await Api.get(`${ADMIN_SERVICE.URL}/usstates/id/${code}`);
  };
}
