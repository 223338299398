import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, {useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationDialog from "../../components/assessment-tool-components/ConfirmationDialog";
import {useDispatch, useSelector} from "react-redux";
import {deleteQuota, updateQuota} from "../../redux/actions/admin-dashboard.actions";
import {QuotaDataProps} from "../../interfaces/quota";

export default function QuotaDataRow(props: QuotaDataProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [updatedLimitValue, setUpdatedLimitValue] = useState(props.limit);

  const isMoreOpen = Boolean(anchorEl);

  const dispatch = useDispatch();

  // @ts-ignore
  const {isDeletingQuota, isUpdatingQuota} = useSelector((state) => state.adminDashboard);

  const handleMoreClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(deleteQuota(props.state));
  };

  const handleUpdate = () => {
    dispatch(
      updateQuota({
        state: props.state,
        quantity: updatedLimitValue,
        onSuccess: () => setIsEdit(false),
      })
    );
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {props.state}
        </TableCell>
        <TableCell component="th" scope="row">
          {props.period}
        </TableCell>
        <TableCell align="center">
          {isEdit ? (
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              spacing={1}
            >
              <TextField
                type="number"
                variant="outlined"
                style={{ width: "100px" }}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                value={updatedLimitValue}
                size="small"
                onChange={(e) =>
                  setUpdatedLimitValue(parseInt(e?.target?.value || "0"))
                }
                disabled={isUpdatingQuota}
              />
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
                width={"100%"}
              >
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ minWidth: "55px !important" }}
                  onClick={() => setIsEdit(false)}
                  disabled={isUpdatingQuota}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ minWidth: "55px !important" }}
                  disabled={
                    updatedLimitValue < 1 ||
                    props.limit === updatedLimitValue ||
                    isUpdatingQuota
                  }
                  onClick={handleUpdate}
                  endIcon={
                    isUpdatingQuota ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null
                  }
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          ) : (
            props.limit
          )}
        </TableCell>
        <TableCell align="center" width={100}>
          {!isEdit && (
            <Box>
              <IconButton onClick={handleMoreClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={isMoreOpen}
                onClose={handleMoreClose}
                PaperProps={{
                  style: {
                    minWidth: "20ch",
                  },
                }}
              >
                <List disablePadding onClick={handleMoreClose}>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => setIsEdit(true)}>
                      Edit
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => setIsDeleteConfirmation(true)}
                    >
                      Delete
                    </ListItemButton>
                  </ListItem>
                </List>
              </Menu>
            </Box>
          )}
        </TableCell>
      </TableRow>

      <ConfirmationDialog
        isOpen={isDeleteConfirmation}
        message="Are you sure you want to delete?"
        confirmButtonText="Yes, Delete"
        onConfirm={handleDelete}
        onCancel={() => setIsDeleteConfirmation(false)}
        confirmIsNegative
        processingRequest={isDeletingQuota}
      />
    </>
  );
}
