import Api from "../lib/api";
import { API_BASE_URL } from "../constants/api.url";
import { DtcUserValidationError } from "../lib/errors";
import { US_STATES } from "../../constants/Constants";

export class FormStepsService {
  private static instance: FormStepsService;
  private constructor() {}

  public static getInstance(): FormStepsService {
    if (!FormStepsService.instance) {
      FormStepsService.instance = new FormStepsService();
    }
    return FormStepsService.instance;
  }

  public submitConsumerOrder = async (requestBody: any): Promise<any> => {
    return await Api.post(
      `${API_BASE_URL.PRIVATE}/consumer-orders`,
      requestBody
    );
  };

  public getQuotaRemainingUnits = async (state: string): Promise<any> => {
    const currentDate = new Date();
    return await Api.get(`${API_BASE_URL.PRIVATE}/orders/progress`, [
      { key: "year", value: currentDate.getFullYear() },
      { key: "month", value: currentDate.getMonth() + 1 },
      { key: "state", value: state },
    ]);
  };

  public dtcValidateUser = async (user: any): Promise<any> => {
    const { data } = await Api.post(
      `${API_BASE_URL.PRIVATE}/consumers/validation`,
      user
    );
    if (!data.validationRulesPassed) {
      throw new DtcUserValidationError(data.reasonsNotPassed);
    }
    return data;
  };

  public getFormStep = (step: number, answers: any, conditions: any) => {
    const formStepMap: any = {
      1: this.getFormStepOne,
      2: this.getFormStepTwo,
      3: this.getFormStepThree,
      4: this.getFormStepFour,
      5: this.getFormStepFive,
      6: this.getFormStepSix,
      7: this.getFormStepSeven,
      8: this.getFormStepEight,
      9: this.getFormStepNine,
      10: this.getFormStepTen,
      11: this.getEndOfForm,
    };
    return formStepMap[step](answers, conditions);
  };

  private getFormStepOne = (answers: any, conditions: any = null) => {
    return [
      {
        id: 1,
        questionIndex: 1,
        userId: null,
        question:
          "Please select the state associated with your shipping address:",
        questionType: "US_STATES_DROPDOWN",
        choices: [
          {
            id: "CO",
            choice: "Colorado",
            position: 1,
          },
          {
            id: "KY",
            choice: "Kentucky",
            position: 2,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: false,
        field: "state",
      },
    ];
  };

  private getFormStepTwo = (answers: any, conditions: any = null) => {
    return [
      {
        id: 55,
        questionIndex: 1,
        userId: "c69970e2-1ada-40bf-9e91-9a9b34001627",
        question: "I would like to request:",
        questionType: "CLICKABLE",
        choices: [
          {
            id: "59467-0679-01",
            choice: "Kloxxado Nasal Liquid 8 MG",
            position: 2,
          },
          {
            id: "69547-0353-02",
            choice: "Narcan Nasal Liquid 4 MG",
            position: 1,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "DRUG_SELECTION",
        mandatory: true,
        important: true,
        field: "ndc",
      },
      {
        id: 38,
        questionIndex: 2,
        userId: "c69970e2-1ada-40bf-9e91-9a9b34001627",
        question:
          "If requesting new product or a refill, please select from the following options:",
        questionType: "CLICKABLE",
        choices: [
          {
            id: "New Request",
            choice: "New Request",
            position: 1,
          },
          {
            id: "Expired",
            choice: "Expired",
            position: 3,
          },
          {
            id: "Used",
            choice: "Used",
            position: 2,
          },
          {
            id: "Lost",
            choice: "Lost",
            position: 4,
          },
          {
            id: "Damaged",
            choice: "Damaged",
            position: 5,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "QUESTION",
        mandatory: true,
        important: true,
        field: "requestReason",
      },
    ];
  };

  private getFormStepThree = (answers: any, conditions: any = null) => {
    let max = 2;

    const reasonAnswer = answers.find(
      (answer: any) => answer.questionId === 38
    );

    if (reasonAnswer.choiceIds[0] === "New Request") {
      max = 4;
    }

    if (conditions && conditions.remainingUnits < max) {
      max = conditions.remainingUnits;
    }

    let choices: any[] = [
      {
        id: 3,
        choice: "3",
        position: 3,
      },
      {
        id: 2,
        choice: "2",
        position: 2,
      },
      {
        id: 4,
        choice: "4",
        position: 4,
      },
      {
        id: 1,
        choice: "1",
        position: 1,
      },
    ];

    choices = choices.filter((choice: any) => choice.id <= max);

    return [
      {
        id: 56,
        questionIndex: 1,
        userId: "abdc7d49-59e7-4b55-bdd6-9b6acdbab6de",
        question: "Number of kits requested? (2 units/kit)",
        questionType: "DROPDOWN",
        choices: choices,
        comment:
          "Please be mindful that you can order up to 4 kits (2 units/kit) the first time, but only 2 kits at each refill. Also, refills can be ordered no sooner than 2 months apart.",
        rightAnswer: null,
        category: "QUESTION",
        mandatory: true,
        important: true,
        field: "qty",
      },
    ];
  };

  private getFormStepFour = (answers: any, conditions: any = null) => {
    return [
      {
        id: 2,
        questionIndex: 1,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Why are you requesting Naloxone?",
        questionType: "CLICKABLE",
        choices: [
          {
            id: "Other individual interested in carrying Naloxone",
            choice: "Other individual interested in carrying Naloxone",
            position: 3,
          },
          {
            id: "I'm a family member, friend or other individual in a position to assist an individual at risk",
            choice:
              "I'm a family member, friend or other individual in a position to assist an individual at risk",
            position: 2,
          },
          {
            id: "I'm an individual at risk of experiencing an opioid-related overdose",
            choice:
              "I'm an individual at risk of experiencing an opioid-related overdose",
            position: 1,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "QUESTION",
        mandatory: true,
        important: false,
        field: "why",
      },
    ];
  };

  private getFormStepFive = (answers: any, conditions: any = null) => {
    const stateList: any = US_STATES;
    let selectedState = answers.find((answer: any) => answer.field === "state")
      .choiceIds[0];
    selectedState = stateList[selectedState];
    let questions = [
      {
        id: 37,
        questionIndex: 19,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "reCaptcha",
        questionType: "RECAPTCHA",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
        field: "reCaptcha",
      },
      {
        id: 23,
        questionIndex: 6,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "State",
        questionType: "US_STATE_FREETEXT",
        choices: [],
        comment: selectedState,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      },
      {
        id: 8,
        questionIndex: 18,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Select Your Gender",
        field: "gender",
        questionType: "DROPDOWN",
        choices: [
          {
            id: "Unknown",
            choice: "Unknown",
            position: 4,
          },
          {
            id: "Female",
            choice: "Female",
            position: 1,
          },
          {
            id: "Non-Binary",
            choice: "Non-Binary",
            position: 3,
          },
          {
            id: "Male",
            choice: "Male",
            position: 2,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 20,
        questionIndex: 16,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Phone Number",
        field: "phone",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 32,
        questionIndex: 13,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "State",
        questionType: "US_STATES_DROPDOWN",
        choices: [
          {
            id: "CO",
            choice: "Colorado",
            position: 1,
          },
          {
            id: "KY",
            choice: "Kentucky",
            position: 2,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 30,
        questionIndex: 11,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Address",
        field: "address",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 7,
        questionIndex: 10,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question:
          "Please select the box if the shipping and resident address are the same.",
        questionType: "CHECKBOX",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      },
      {
        id: 35,
        questionIndex: 9,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Address Type",
        field: "shippingAddressType",
        questionType: "DROPDOWN",
        choices: [
          {
            id: "Neighbor",
            choice: "Neighbor",
            position: 4,
          },
          {
            id: "Office",
            choice: "Office",
            position: 5,
          },
          {
            id: "Other",
            choice: "Other",
            position: 6,
          },
          {
            id: "Home",
            choice: "Home",
            position: 1,
          },
          {
            id: "Friend",
            choice: "Friend",
            position: 3,
          },
          {
            id: "Family",
            choice: "Family",
            position: 2,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      },
      {
        id: 21,
        questionIndex: 3,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Address",
        field: "shippingAddress",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      },
      {
        id: 18,
        questionIndex: 2,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Last Name",
        field: "lastName",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 24,
        questionIndex: 7,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Zip Code",
        field: "shippingZip",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      },
      {
        id: 34,
        questionIndex: 4,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Address 2 (Apt., Suite, etc.)",
        field: "shippingAddress2",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: false,
        important: true,
      },
      {
        id: 33,
        questionIndex: 14,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Zip Code",
        field: "zip",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 17,
        questionIndex: 1,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "First Name",
        field: "firstName",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 22,
        questionIndex: 5,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "City",
        field: "shippingCity",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      },
      {
        id: 31,
        questionIndex: 12,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "City",
        field: "city",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 25,
        questionIndex: 17,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Date of Birth",
        field: "dob",
        questionType: "LOCAL_DATE",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
      {
        id: 19,
        questionIndex: 15,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "Email",
        field: "email",
        questionType: "FREETEXT",
        choices: [],
        comment: null,
        rightAnswer: null,
        category: "DEMOGRAPHIC",
        mandatory: true,
        important: true,
      },
    ];

    if (selectedState === "Kentucky")
      questions.push({
        id: 58,
        questionIndex: 8,
        userId: "875343a2-f902-4162-ac6f-54ba51c4318f",
        question: "County",
        field: "shippingCounty",
        questionType: "DROPDOWN",
        choices: [
          {
            id: "JACKSON",
            choice: "JACKSON",
            position: 9,
          },
          {
            id: "BELL",
            choice: "BELL",
            position: 1,
          },
          {
            id: "ELLIOTT",
            choice: "ELLIOTT",
            position: 6,
          },
          {
            id: "HARLAN",
            choice: "HARLAN",
            position: 8,
          },
          {
            id: "BREATHITT",
            choice: "BREATHITT",
            position: 3,
          },
          {
            id: "KNOX",
            choice: "KNOX",
            position: 12,
          },
          {
            id: "LAUREL",
            choice: "LAUREL",
            position: 13,
          },
          {
            id: "MCCREARY",
            choice: "MCCREARY",
            position: 21,
          },
          {
            id: "OWSLEY",
            choice: "OWSLEY",
            position: 24,
          },
          {
            id: "ROWAN",
            choice: "ROWAN",
            position: 30,
          },
          {
            id: "WHITLEY",
            choice: "WHITLEY",
            position: 32,
          },
          {
            id: "LEE",
            choice: "LEE",
            position: 15,
          },
          {
            id: "MENIFEE",
            choice: "MENIFEE",
            position: 22,
          },
          {
            id: "CLAY",
            choice: "CLAY",
            position: 5,
          },
          {
            id: "PULASKI",
            choice: "PULASKI",
            position: 28,
          },
          {
            id: "MARTIN",
            choice: "MARTIN",
            position: 20,
          },
          {
            id: "PERRY",
            choice: "PERRY",
            position: 25,
          },
          {
            id: "PIKE",
            choice: "PIKE",
            position: 26,
          },
          {
            id: "MORGAN",
            choice: "MORGAN",
            position: 23,
          },
          {
            id: "JOHNSON",
            choice: "JOHNSON",
            position: 10,
          },
          {
            id: "FLOYD",
            choice: "FLOYD",
            position: 7,
          },
          {
            id: "WOLFE",
            choice: "WOLFE",
            position: 33,
          },
          {
            id: "LINCOLN",
            choice: "LINCOLN",
            position: 18,
          },
          {
            id: "MAGOFFIN",
            choice: "MAGOFFIN",
            position: 19,
          },
          {
            id: "WAYNE",
            choice: "WAYNE",
            position: 31,
          },
          {
            id: "LAWRENCE",
            choice: "LAWRENCE",
            position: 14,
          },
          {
            id: "LESLIE",
            choice: "LESLIE",
            position: 16,
          },
          {
            id: "ROCKCASTLE",
            choice: "ROCKCASTLE",
            position: 29,
          },
          {
            id: "CARTER",
            choice: "CARTER",
            position: 4,
          },
          {
            id: "LETCHER",
            choice: "LETCHER",
            position: 17,
          },
          {
            id: "BOYD",
            choice: "BOYD",
            position: 2,
          },
          {
            id: "KNOTT",
            choice: "KNOTT",
            position: 11,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "SHIPPING",
        mandatory: true,
        important: true,
      });

    return questions;
  };

  private getFormStepSix = (answers: any, conditions: any = null) => {
    return [
      {
        id: 9,
        questionIndex: 1,
        userId: "c8e8a9e1-126f-4001-9367-20a6b41b3501",
        question: "Naloxone Training Video",
        questionType: "VIDEO",
        choices: [
          {
            id: 67,
            choice:
              "https://storage.googleapis.com/pp-temp/NARCAN%20training%20video%20-%20%20Instructions%20for%20administration%20of%20NARCAN%C2%AE%20Nasal%20Spray%204mg.mp4",
            position: null,
          },
        ],
        comment: null,
        rightAnswer: null,
        category: "QUESTION",
        mandatory: true,
        important: false,
      },
    ];
  };

  private getFormStepSeven = (ansewrs: any) => {
    return [
      {
        id: 10,
        questionIndex: 1,
        userId: "c8e8a9e1-126f-4001-9367-20a6b41b3501",
        question: "Which of the following is NOT an opioid overdose symptom?",
        questionType: "CLICKABLE_ANSWER",
        choices: [
          {
            id: 69,
            choice: "Stopped or slowed breathing",
            position: 2,
          },
          {
            id: 71,
            choice: "Erratic physical movements",
            position: 4,
          },
          {
            id: 70,
            choice: "Unresponsiveness to shaking, shouting, and a sternal rub",
            position: 3,
          },
          {
            id: 68,
            choice: "Lips and nail beds turning blue or grey",
            position: 1,
          },
        ],
        comment:
          "Opioids affect a part of the brain that regulates breathing. An individual experiencing an opioid overdose will be non-responsive, have slow or stopped breathing, and their lips and nail bed will turn blue or gray because their body is not getting enough oxygen. <br/> <br/> So, erratic physical movements is the only choice above that is NOT a symptom of an opioid overdose.",
        rightAnswer: {
          id: 71,
          choice: "Erratic physical movements",
          position: null,
        },
        category: "QUESTION",
        mandatory: true,
        important: false,
      },
    ];
  };

  private getFormStepEight = (answers: any, conditions: any = null) => {
    return [
      {
        id: 11,
        questionIndex: 1,
        userId: "c8e8a9e1-126f-4001-9367-20a6b41b3501",
        question:
          "If you are forced to leave an unresponsive person alone after administering Naloxone, you should:",
        questionType: "CLICKABLE_ANSWER",
        choices: [
          {
            id: 72,
            choice: "Put them in the rescue position",
            position: 1,
          },
          {
            id: 74,
            choice: "Text them to let them know what happened",
            position: 3,
          },
          {
            id: 73,
            choice: "Leave them extra Naloxone for when they wake up",
            position: 2,
          },
          {
            id: 75,
            choice: "Put them in a bathtub with ice",
            position: 4,
          },
        ],
        comment:
          "An individual experiencing an opioid overdose is in a life threatening emergency. If you must leave the person to get help, ensure you put them in the rescue position which facilitates fluids draining from the person's mouth and will ensure their airway is open.",
        rightAnswer: {
          id: 72,
          choice: "Put them in the rescue position",
          position: null,
        },
        category: "QUESTION",
        mandatory: true,
        important: false,
      },
    ];
  };

  private getFormStepNine = (answers: any, conditions: any = null) => {
    return [
      {
        id: 12,
        questionIndex: 1,
        userId: "c8e8a9e1-126f-4001-9367-20a6b41b3501",
        question:
          "It's important to advise a person not to use opioids, alcohol, or other drugs within a few hours of experiencing an opioid overdose because:",
        questionType: "CLICKABLE_ANSWER",
        choices: [
          {
            id: 76,
            choice: "Naloxone wears off 30-90 minutes after administration",
            position: 1,
          },
          {
            id: 79,
            choice: "All of the above",
            position: 4,
          },
          {
            id: 77,
            choice:
              "Using more drugs may increase their likelihood of a second overdose",
            position: 2,
          },
          {
            id: 78,
            choice:
              "Using more opioids will not significantly reduce their withdrawal symptoms",
            position: 3,
          },
        ],
        comment:
          "All of the above choices are correct. <br/> People who have experience and opioid overdose report that not panicking, giving them space, and minimizing loud noises are all helpful post-overdose responses. Offering food and drink, opportunities for fresh air, and other efforts to provide a calm environment are often appreciated.",
        rightAnswer: {
          id: 79,
          choice: "All of the above",
          position: null,
        },
        category: "QUESTION",
        mandatory: true,
        important: false,
      },
    ];
  };

  private getFormStepTen = (answers: any, conditions: any = null) => {
    return [
      {
        id: 13,
        questionIndex: 1,
        userId: "c8e8a9e1-126f-4001-9367-20a6b41b3501",
        question:
          "If you've used your Naloxone to respond to an overdose, you should:",
        questionType: "CLICKABLE_ANSWER",
        choices: [
          {
            id: 80,
            choice:
              "Fill out the reversal form located on this website which will also prompt us to send you more Naloxone",
            position: 1,
          },
          {
            id: 81,
            choice:
              "Talk to the person who overdosed about seeking support at a harm reduction program near them",
            position: 2,
          },
          {
            id: 82,
            choice: "Give yourself a pat on the back, you’ve saved a life",
            position: 3,
          },
          {
            id: 79,
            choice: "All of the above",
            position: 4,
          },
        ],
        comment:
          "All of the above choices are correct. <br/> Filling out our reversal form helps us illustrate why getting Naloxone into the hands of community members like you is so important. It will also prompt us to send you replacement kits. If the person who has overdosed is not engaged at an in-person syringe exchange program or online harm reduction network, let them know they have a variety of options for support. Reach out to us directly if you would like help having this conversation.",
        rightAnswer: {
          id: 79,
          choice: "All of the above",
          position: null,
        },
        category: "QUESTION",
        mandatory: true,
        important: false,
      },
    ];
  };

  private getEndOfForm = async (answers: any, conditions: any = null) => {
    return [
      {
        id: 16,
        questionIndex: 1,
        userId: "c8e8a9e1-126f-4001-9367-20a6b41b3501",
        question: "Your order of Naloxone is on its way!",
        questionType: "END",
        choices: [],
        comment:
          "Check your inbox. We sent an email confirming your order of Naloxone and providing you with a unique MRN number (Medical Record Number). Please create an account to track shipments, gather more information, and reorder more Naloxone. <br/> <br/> Getting a life-saving medication should be simple - now it is. Receive the FDA-approved overdose antidote Naloxone (used in an opioid-related emergency) without hassle. No prescription is necessary to purchase.",
        rightAnswer: null,
        category: "QUESTION",
        mandatory: true,
        important: true,
      },
    ];
  };
}
