import React from "react";
import { connect } from "react-redux";
import { Button, Grid, TextField, MenuItem } from "@mui/material";
import {
  CommonStyles,
  StyledSmallModal,
  getMuiTheme,
} from "../../hooks/styles";
import Loading from "../../components/loading";
import {
  StyledFormControl,
  StyledTextField,
} from "../../hooks/styles/formStyles";
import AutoComplete from "../../components/auto-complete";
import { validateEmail } from "../../utils/formator";
import { toast } from "react-toastify";

interface UserFormProps {
  user: any;
  customers: any[];
  getUsers: () => void;
  inviteUser: (payload: any) => void;
  updateUser: (payload: any) => void;
  closeModal: () => void;
}

const UserFormModal: React.FC<UserFormProps> = (props) => {
  const classes = CommonStyles(getMuiTheme("light"));

  const getInitInputs = () => ({
    firstName: props.user?.firstName || "",
    lastName: props.user?.lastName || "",
    email: props.user?.email || ("" as string),
    role: props.user?.claims.role || "",
    customers: props.user?.claims.customers || [],
  });

  const [inputs, setInputs] = React.useState<any>(getInitInputs());
  const [formErrors, setFormErrors] = React.useState<any>({});

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const handleCancel = () => {
    props.closeModal();
  };

  const handleSetInputs = (key: string, value: any) => {
    if (key === "role" && value !== "customer") {
      setInputs({ ...inputs, role: value, customers: [] });
    } else {
      setInputs({ ...inputs, [key]: value });
    }
    setFormErrors({ ...formErrors, [key]: null });
    setSubmitError("");
  };

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, " $1").trim();
  };

  const handleSave = () => {
    const errors: any = {};
    for (let [key, value] of Object.entries(inputs)) {
      if (value === "") {
        errors[key] = `${formatString(key)} is required`;
      }
      if (key === "email" && !validateEmail(value)) {
        errors[key] = `A valid ${formatString(key)} is required`;
      }
      if (
        inputs.role === "customer" &&
        Array.isArray(value) &&
        value.length === 0
      ) {
        errors[key] = `At least one customer is required`;
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      props.getUsers();
      props.closeModal();
    };

    const onError = () => {
      setIsLoading(false);
      toast.error(`Failed to ${props.user ? "update" : "submit"} user.`);
    };

    const requestBody = props.user ? getEditRequestBody() : getAddRequestBody();

    delete requestBody.createdAt;

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError,
    };

    const submitFn = props.user ? props.updateUser : props.inviteUser;

    submitFn(payload);
  };

  const getAddRequestBody = () => ({
    firstName: inputs.firstName,
    lastName: inputs.lastName,
    email: inputs.email,
    claims: {
      role: inputs.role,
      customers: inputs.customers.length > 0 ? inputs.customers : undefined,
    },
  });

  const getEditRequestBody = () => ({
    ...props.user,
    ...{
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      claims: {
        customers: inputs.customers,
        role: inputs.role,
      },
    },
  });

  return (
    <StyledSmallModal>
      <div>
        <h3 id="simple-modal-title">{props.user ? "Edit User" : "Add User"}</h3>
        <Grid container>
          <Grid item xs={12}>
            <StyledFormControl>
              <StyledTextField
                label="Email"
                variant="outlined"
                value={inputs.email}
                error={formErrors.email ? true : false}
                helperText={formErrors.email ? formErrors.email : ""}
                onChange={(event) => {
                  handleSetInputs("email", event.target.value);
                }}
                required
              />
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl>
              <StyledTextField
                label="First Name"
                variant="outlined"
                value={inputs.firstName}
                error={formErrors.firstName ? true : false}
                helperText={formErrors.firstName ? formErrors.firstName : ""}
                onChange={(event) => {
                  handleSetInputs("firstName", event.target.value);
                }}
                required
              />
            </StyledFormControl>
            <StyledFormControl>
              <StyledTextField
                label="Last Name"
                variant="outlined"
                value={inputs.lastName}
                error={formErrors.lastName ? true : false}
                helperText={formErrors.lastName ? formErrors.lastName : ""}
                onChange={(event) => {
                  handleSetInputs("lastName", event.target.value);
                }}
                required
              />
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl>
              <StyledTextField
                variant="outlined"
                id="user-role-selector"
                value={inputs.role}
                placeholder="Select Role"
                label="Role"
                select
                required
                error={formErrors.role ? true : false}
                helperText={formErrors.role ? formErrors.role : ""}
                onChange={(event: any) => {
                  const value = event.target.value;
                  handleSetInputs("role", value);
                }}
              >
                <MenuItem value={"superAdmin"}>Super Admin</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"customer"}>Customer</MenuItem>
              </StyledTextField>
            </StyledFormControl>
            {inputs.role === "customer" && (
              <StyledFormControl>
                <AutoComplete
                  multiple={true}
                  label="Customers"
                  options={(props.customers || []).map((customer: any) => {
                    return {
                      label: customer.customerName,
                      value: customer.customerId,
                    };
                  })}
                  value={(props.customers || [])
                    .map((customer: any) => {
                      return {
                        label: customer.customerName,
                        value: customer.customerId,
                      };
                    })
                    .filter((customer: any) =>
                      (inputs.customers || []).includes(customer.value)
                    )}
                  onChange={(event: any, customers: any[]) => {
                    handleSetInputs(
                      "customers",
                      (customers || []).map((customer: any) => customer.value)
                    );
                  }}
                  error={formErrors.customers ? true : false}
                  errorText={
                    formErrors.customers
                      ? "At least one customer is required"
                      : ""
                  }
                />
              </StyledFormControl>
            )}
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ? (
            <Loading message="" />
          ) : (
            <Grid item xs={12}>
              <StyledFormControl>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="secondary"
                >
                  {props.user ? "SAVE" : "SEND"}
                </Button>
              </StyledFormControl>
              <StyledFormControl>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  variant="contained"
                  color="primary"
                >
                  CANCEL
                </Button>
              </StyledFormControl>
            </Grid>
          )}
        </Grid>
      </div>
    </StyledSmallModal>
  );
};

const mapStateToProps = (state: any) => ({
  customers: state.adminDashboard.customers,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserFormModal);
