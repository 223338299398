import React from "react";
import {Alert, Typography} from "@mui/material";

function NotEligibleDisclaimer({userSelectedBrand}) {
  return (
    <Alert severity={"warning"} icon={false}>
      <Typography fontSize={"large"}>
        Sorry we are unable to provide {userSelectedBrand ?? "this drug"} in your state. Please
        reach out to your local health department for additional resources to obtain this life
        saving drug.
      </Typography>
    </Alert>
  );
}

export default NotEligibleDisclaimer;
