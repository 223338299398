export const QUESTION_TYPE = Object.freeze({
  FREETEXT: "FREETEXT",
  LOCAL_DATE: "LOCAL_DATE",
  DROPDOWN: "DROPDOWN",
  RADIO_BUTTON: "RADIO_BUTTON",
  CHECKBOX: "CHECKBOX",
  FORM: "FORM",
  CLICKABLE: "CLICKABLE",
  VIDEO: "VIDEO",
  PDF: "PDF",
  CLICKABLE_ANSWER: "CLICKABLE_ANSWER",
  RECAPTCHA: "RECAPTCHA",
  US_STATES_DROPDOWN: "US_STATES_DROPDOWN",
  US_STATE_FREETEXT: "US_STATE_FREETEXT",
  INFO: "INFO",
  END: "END",
});

export const QUESTION_CATEGORY = Object.freeze({
  DEMOGRAPHIC: "DEMOGRAPHIC",
  SHIPPING: "SHIPPING",
  OPTIONAL: "OPTIONAL",
  DRUG_SELECTION: "DRUG_SELECTION",
  REFILL_REASON: "REFILL_REASON",
});
