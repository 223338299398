import local from './local.json';
import dev from "./dev.json";
import qa from "./qa.json";
import prod from "./prod.json";

const configFiles: any = {
  dev: dev,
  qa: qa,
  prod: prod
};

function getConfig() {
  // @ts-ignore
  const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

  
  if (!subdomain) {
    return local;
  }
  

  return configFiles[subdomain];
}

export default (getConfig());
