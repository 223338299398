import { call, put } from "redux-saga/effects";
import { AdminDashboardActions, UIActions } from "../actions/index";
import { AdminDashboardService } from "../services/admin-dashboard.service";

export function* updateUINavigateState(
  action: ReturnType<typeof UIActions.setNavigationState>
) {
  try {
    yield put(UIActions.navigationStateSuccess(action.payload));
  } catch (err) {
    console.log("saga error", err);
  }
}

export function* preLoadData(
  action: ReturnType<typeof UIActions.preLoadData>
): any {
  try {
    const poller = action.payload.poller();
    yield put(UIActions.setBackendPoller(poller));
    const { data } = yield call(
      AdminDashboardService.getInstance().getCustomers
    );
    yield put(UIActions.setPreloadedCustomers(data));
    yield put(AdminDashboardActions.setCustomers(data));
    const productRepsonse = yield call(
      AdminDashboardService.getInstance().getProducts
    );
    yield put(AdminDashboardActions.setProducts(productRepsonse.data));
  } catch (err) {
    console.log("PRELOAD DATA SAGA ERROR:", err);
  }
}
