import React from "react";
import { connect } from "react-redux";
import { Button, Grid, TextField, MenuItem } from "@mui/material";
import {
  CommonStyles,
  StyledSmallModal,
  getMuiTheme,
} from "../../hooks/styles";
import Loading from "../../components/loading";
import {
  StyledFormControl,
  StyledTextField,
} from "../../hooks/styles/formStyles";
import AutoComplete from "../../components/auto-complete";
import { validateEmail } from "../../utils/formator";
import { toast } from "react-toastify";

interface CustomerFormProps {
  authUser: any;
  customer: any;
  getCustomers: () => void;
  updateCustomer: (payload: any) => void;
  createCustomer: (payload: any) => void;
  closeModal: () => void;
}

const CustomerFormModal: React.FC<CustomerFormProps> = (props) => {
  const classes = CommonStyles(getMuiTheme("light"));

  const getInitInputs = () => ({
    customerId: props.customer?.customerId || "",
  });

  const [inputs, setInputs] = React.useState<any>(getInitInputs());
  const [formErrors, setFormErrors] = React.useState<any>({});

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const handleCancel = () => {
    props.closeModal();
  };

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value });
    setFormErrors({ ...formErrors, [key]: null });
    setSubmitError("");
  };

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, " $1").trim();
  };

  const handleSave = () => {
    const errors: any = {};
    for (let [key, value] of Object.entries(inputs)) {
      if (value === "") {
        errors[key] = `${formatString(key)} is required`;
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      props.getCustomers();
      props.closeModal();
    };

    const onError = () => {
      setIsLoading(false);
      toast.error(
        `Failed to ${props.customer ? "update" : "submit"} customer.`
      );
    };

    const requestBody = props.customer
      ? getEditRequestBody()
      : getAddRequestBody();

    delete requestBody.createdAt;

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError,
    };
    const submitFn = props.customer
      ? props.updateCustomer
      : props.createCustomer;
    submitFn(payload);
  };

  const getAddRequestBody = () => ({
    customerId: inputs.customerId,
  });

  const getEditRequestBody = () => ({
    ...props.customer,
    ...{
      customerId: inputs.customerId,
    },
  });

  return (
    <StyledSmallModal>
      <div>
        <h3 id="simple-modal-title">
          {props.customer ? "Edit Customer" : "Add Customer"}
        </h3>
        <Grid container>
          <Grid item xs={12}>
            <StyledFormControl>
              <StyledTextField
                label="Customer Id"
                variant="outlined"
                value={inputs.customerId}
                InputProps={{ inputProps: { min: 1 } }}
                error={formErrors.customerId ? true : false}
                helperText={formErrors.customerId ? formErrors.customerId : ""}
                onChange={(event) => {
                  handleSetInputs("customerId", event.target.value);
                }}
                required
              />
            </StyledFormControl>
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ? (
            <Loading message="" />
          ) : (
            <Grid item xs={12}>
              <StyledFormControl>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="secondary"
                >
                  {props.customer ? "SAVE" : "SUBMIT"}
                </Button>
              </StyledFormControl>
              <StyledFormControl>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  variant="contained"
                  color="primary"
                >
                  CANCEL
                </Button>
              </StyledFormControl>
            </Grid>
          )}
        </Grid>
      </div>
    </StyledSmallModal>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFormModal);
