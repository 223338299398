import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import Reaptcha from "@panalbish/reaptcha-enterprise";
import config from "../../config";

const isQaEnvironment = config.environment === "qa";

//Related bug https://github.com/sarneeh/reaptcha/issues/254
function Recaptcha({ onVerify, onExpire, questionId, setRecaptchaPool }) {
  const [isCaptchaLoading, setCaptchaLoading] = useState(true);
  const captchaRef = useRef();

  useEffect(() => {
    //Qa team wants the captcha to be disabled for Qa environment. But to enable the "Next" button, some random random value must be set
    if (isQaEnvironment) {
      onVerify("any-string-to-enable-next-button");
    }
    return () => {
      setRecaptchaPool((prev) =>
        prev.filter((ref) => ref?.current?.props?.id === questionId)
      );
    };
  }, []);

  useEffect(() => {
    if (setRecaptchaPool && captchaRef) {
      setRecaptchaPool((prev) => prev.concat(captchaRef));
    }
  }, [captchaRef]);

  return isQaEnvironment ? (
    <div className="captcha_placeholder_for_qa" />
  ) : (
    <>
      {isCaptchaLoading && <CircularProgress color={"primary"} size={30} />}
      <Reaptcha
        sitekey={config.recaptchaKey}
        ref={captchaRef}
        id={questionId}
        //for reset, use captchaRef?.current?.reset();
        onLoad={() => setCaptchaLoading(false)}
        onVerify={onVerify}
        onExpire={onExpire}
      />
    </>
  );
}

Recaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func.isRequired,
};

export default Recaptcha;
