import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { UIActions } from "../../../redux/actions";
import { hasAccess } from "../../../utils/accessLevelHelper";

const SideMenuBar = (props: any) => {
  useEffect(() => {
    console.log("active menu", props.activeMenu);
    if (!props.activeMenu) {
      props.dispatch(
        UIActions.setNavigationState({
          title: "",
          url: window.location.pathname.split("?")[0],
        })
      );
    }
  }, []);

  const navigateMenu = (title: string, url: string) => {
    props.dispatch(
      UIActions.setNavigationState({
        title: title,
        url: url,
      })
    );
  };

  const generateMenuActiveStyle = (path: string) => {
    if (props.activeMenu?.url === path) {
      return { background: "#e0e0e0" };
    }
    return {};
  };

  return (
    <div
      style={{
        position: "relative",
        maxHeight: "calc(100vh - 110px)",
        height: "100%",
        overflow: "auto",
      }}
    >
      <>
        {hasAccess("/progress") && (
          <Link
            to={"/progress"}
            onClick={() => navigateMenu("Progress", "/progress")}
          >
            <ListItem style={generateMenuActiveStyle("/progress")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText primary="Progress" />
            </ListItem>
          </Link>
        )}

        {hasAccess("/quotas") && (
          <Link
            to={"/quotas"}
            onClick={() => navigateMenu("Quotas", "/quotas")}
          >
            <ListItem style={generateMenuActiveStyle("/quotas")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <FactCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Quotas" />
            </ListItem>
          </Link>
        )}

        {hasAccess("/consumer-orders") && (
          <Link
            to={"/consumer-orders"}
            onClick={() => navigateMenu("Orders", "/consumer-orders")}
          >
            <ListItem style={generateMenuActiveStyle("/consumer-orders")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Consumer Orders" />
            </ListItem>
          </Link>
        )}

        {hasAccess("/bulk-orders") && (
          <Link
            to={"/bulk-orders"}
            onClick={() => navigateMenu("Orders", "/bulk-orders")}
          >
            <ListItem style={generateMenuActiveStyle("/bulk-orders")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Bulk Orders" />
            </ListItem>
          </Link>
        )}

        {hasAccess("/customers") && (
          <Link
            to={"/customers"}
            onClick={() => navigateMenu("Customers", "/customers")}
          >
            <ListItem style={generateMenuActiveStyle("/customers")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem>
          </Link>
        )}

        {hasAccess("/report-subscriptions") && (
          <Link
            to={"/report-subscriptions"}
            onClick={() =>
              navigateMenu("Report Subscriptions", "/report-subscriptions")
            }
          >
            <ListItem style={generateMenuActiveStyle("/report-subscriptions")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <NotificationImportantIcon />
              </ListItemIcon>
              <ListItemText primary="Report Subscriptions" />
            </ListItem>
          </Link>
        )}

        {hasAccess("/user-management") && (
          <Link
            to={"/user-management"}
            onClick={() => navigateMenu("User Management", "/user-management")}
          >
            <ListItem style={generateMenuActiveStyle("/user-management")}>
              <ListItemIcon
                style={{ minWidth: 40 }}
                onClick={() => props.setOpen(true)}
              >
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Users" />
            </ListItem>
          </Link>
        )}
      </>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  userNav: true,
  navState: state.ui.navState,
  activeMenu: state.ui.menuState,
});
export default connect(mapStateToProps)(SideMenuBar);
