import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import StateSelectorPage from "./StateSelectorPage";
import axios from "axios";
import { toast } from "react-toastify";
import FormPage from "./FormPage";
import {
  QUESTION_CATEGORY,
  QUESTION_TYPE,
} from "../../../constants/QuestionConstants";
import { FormStepsActions } from "../../../redux/actions";

export const FORM_MAIN_PAGE_ELEMENT_ID = "FormMainPage-id";

const NEXT_REQUEST_BODY_DEFAULT_STATE = [];
const QUESTION_SCHEMA_TO_RENDER_DEFAULT_STATE = {
  //isForm, formTitle are only for
  isForm: false,
  formTitle: "", //string
  questions: [], //The actual questions
};

/**
 * The main component where the form rendering starts.
 * nextRequestPayload state variables is always updated in a way that it handles the request body for the next request.
 * When a response is received from the BE, a logic (check then block of submitRequest method) is written that would convert that response into the request-body for the next request.
 * The inside form handlers only need to fill this nextRequestPayload carefully.
 */
function FormMainPage(props) {
  //Holds the object containing the details of first question i.e State selection
  const [allStates, setAllStates] = useState([]);
  const [stateSelectionQuestion, setStateSelectionQuestion] = useState(null);
  const [selectedStateCode, setSelectedStateCode] = useState(null);
  // The payload for the next request. This is filled inside the then() of submitRequest() method. Idea is to generate the request-body of next BE request from the received response of current BE request
  const [nextRequestPayload, setNextRequestPayload] = useState(
    NEXT_REQUEST_BODY_DEFAULT_STATE
  );
  const [questionSchemaToRender, setQuestionSchemaToRender] = useState(
    QUESTION_SCHEMA_TO_RENDER_DEFAULT_STATE
  );
  const [isLoading, setLoading] = useState(false);
  const [isStateNotEligible, setStateNotEligible] = useState(false);
  const [reachedEndOfForm, setReachedEndOfForm] = useState(false);
  const [userSelectedBrand, setUserSelectedBrand] = useState(null);
  const [notAcceptableError, setNotAcceptableError] = useState(null); //Backend returns messages for some specific use-cases, for if user ordered too early from the last order, which is not allowed
  const [isFirstStep, setIsFirstStep] = useState(true);
  //inside the forms, if any Recaptcha is being used, add the ref of that recaptcha tp this array.
  //This becomes useful when any backend error occurs on the page containing the recaptcha, that recaptcha should be reset
  const [recaptchaPool, setRecaptchaPool] = useState([]);

  /**
   * Inside a string, convert Naloxone with Narcan/Kloxxado
   * @param {string} label current label
   * @returns {string} converted label in which 'Naloxone' is replaced with Narcan/Kloxxado
   */
  function convertLabelToSelectedDrugNameText(label) {
    if (!Boolean(label)) return label;
    label = label.replaceAll("Naloxone", userSelectedBrand);
    label = label.replaceAll("naloxone", userSelectedBrand);
    return label;
  }

  /**
   * On each step, if the scrollbar is at the bottom and click on Next button, the browser remembers that scroll position and doesn't display the top fields.
   * Scrolling to top is done manually, on each questions length change
   */
  const questionIds = questionSchemaToRender?.questions.map(
    (question) => question.id
  );

  useEffect(() => {
    //Send request with empty array as body. Empty array is the request body for first request.
    if (isFirstStep) sendForm([]);
  }, [isFirstStep]);

  useEffect(() => {
    props.formStepResponse && extractBackendResponse(props.formStepResponse);
  }, [props.formStepResponse]);

  const sendForm = (requestBody) => {
    setLoading(true);
    setStateNotEligible(false);
    props.submitStep({
      requestBody: requestBody,
      onComplete: (errors) => {
        setLoading(false);
        if (errors) {
          setNotAcceptableError(errors);
          setReachedEndOfForm(true);
        }
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 20);
      },
    });
  };

  let shouldStopNavigation =
    questionIds?.length > 0 && !isFirstStep && !reachedEndOfForm;

  const extractBackendResponse = (data) => {
    setIsFirstStep(data && data[0]?.userId === null);

    //Convert Naloxone text to the drug/brand selection made by user
    data = data?.map((questionObj) => {
      const updatedQuestionTitle = convertLabelToSelectedDrugNameText(
        questionObj.question
      );
      const updatedChoices = questionObj?.choices?.map((choiceObj) => {
        return {
          ...choiceObj,
          choice: convertLabelToSelectedDrugNameText(choiceObj.choice),
        };
      });
      const updatedComment = convertLabelToSelectedDrugNameText(
        questionObj.comment
      );
      let updatedRightAnswer = null;
      if (questionObj.rightAnswer) {
        updatedRightAnswer = {
          ...questionObj.rightAnswer,
          choice: convertLabelToSelectedDrugNameText(
            questionObj?.rightAnswer?.choice
          ),
        };
      }

      return {
        ...questionObj,
        question: updatedQuestionTitle,
        choices: updatedChoices,
        comment: updatedComment,
        rightAnswer: updatedRightAnswer,
      };
    });

    if (data?.length === 0) {
      setStateNotEligible(true);
      setNextRequestPayload(NEXT_REQUEST_BODY_DEFAULT_STATE);
      setQuestionSchemaToRender(QUESTION_SCHEMA_TO_RENDER_DEFAULT_STATE);
    } else {
      /**
       * Previous we use to differentiate state question and other with using index from now BE
       * is not sending index so differentiation happening with id....
       * **/
      if (
        data &&
        isFirstStep &&
        data?.some(
          (question) =>
            question?.questionType === QUESTION_TYPE.US_STATES_DROPDOWN
        )
      ) {
        const stateQuestion = data?.find(
          (question) =>
            question?.questionType === QUESTION_TYPE.US_STATES_DROPDOWN
        );
        setStateSelectionQuestion(stateQuestion);
        setAllStates(stateQuestion?.choices);
      } else {
        setStateSelectionQuestion(null);
        let containsDemographicForm = data?.some(
          (question) => question?.category === QUESTION_CATEGORY.DEMOGRAPHIC
        );
        let containsShippingForm = data?.some(
          (question) => question?.category === QUESTION_CATEGORY.SHIPPING
        );

        if (containsDemographicForm && containsShippingForm) {
          setQuestionSchemaToRender({
            isForm: true,
            formTitle: "Required Information",
            questions: data,
          });
        } else {
          setQuestionSchemaToRender({
            isForm: false,
            formTitle: "",
            questions: data,
          });
        }
      }

      //The state selector dropdown must only come on the first step
      if (data && !isFirstStep) {
        setStateSelectionQuestion(null);
      }

      //If the end of form has reached, don't stop the navigation
      if (
        data?.some((question) => question.questionType === QUESTION_TYPE.END)
      ) {
        setReachedEndOfForm(true);
      }

      const requestBodyTemp = [];
      for (let i = 0; i < data?.length; i++) {
        const questionSchema = data[i];
        const { id, userId, comment, questionType, choices } = questionSchema; //comment contains the default value for some questionType

        requestBodyTemp[i] = {
          questionId: id,
          userId,
          choiceIds: [],
          value:
            questionType === QUESTION_TYPE.CHECKBOX && choices?.length === 0
              ? false
              : questionType === QUESTION_TYPE.DROPDOWN
              ? null
              : comment,
        };
      }
      setNextRequestPayload(requestBodyTemp);
    }
    setLoading(false);
    setTimeout(() => {
      // document.getElementById("MainContent").scrollIntoView({behavior: "smooth", inline: "start"});
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 20);
  };

  const submitRequest = (requestBody) => {
    let requestBodyCopy = [...requestBody];
    requestBodyCopy = requestBodyCopy?.map((reqBodySingleQuestion) => {
      const { questionId } = reqBodySingleQuestion;
      // !!IMPORTANT!! -- For CLICKABLE_ANSWER, the request body MUST not contain the choiceIds. That should be empty
      if (
        questionSchemaToRender?.questions.find(
          (question) => question?.id === questionId
        )?.questionType === QUESTION_TYPE.CLICKABLE_ANSWER
      )
        return { ...reqBodySingleQuestion, choiceIds: [] };
      return { ...reqBodySingleQuestion };
    });
    sendForm(requestBodyCopy);
  };

  const handleStateSelection = ({ questionId, questionIndex, choice }) => {
    setSelectedStateCode(choice);
    const choiceIds = [choice];
    const reqBody = [{ questionId, questionIndex, choiceIds, field: "state" }];
    submitRequest(reqBody);
  };

  const handleNext = () => {
    submitRequest(nextRequestPayload);
  };

  function handleRestart() {
    //Reset all useState
    setIsFirstStep(true);
    setStateSelectionQuestion(null);
    setSelectedStateCode(null);
    setNextRequestPayload(NEXT_REQUEST_BODY_DEFAULT_STATE);
    setQuestionSchemaToRender(QUESTION_SCHEMA_TO_RENDER_DEFAULT_STATE);
    setStateNotEligible(false);
    setReachedEndOfForm(false);
    setUserSelectedBrand(null);
    setNotAcceptableError(null);
  }

  return (
    <>
      <Box
        minHeight={400}
        className={"FormMainPage"}
        id={FORM_MAIN_PAGE_ELEMENT_ID}
      >
        <Backdrop
          open={isLoading}
          sx={{ color: "#FFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color={"inherit"} />
        </Backdrop>
        <>
          <Box mt={4}>
            {stateSelectionQuestion ? (
              <StateSelectorPage
                stateSelectorQuestion={stateSelectionQuestion}
                handleStateSelection={handleStateSelection}
                selectedStateCode={selectedStateCode}
                isStateNotEligible={isStateNotEligible}
              />
            ) : nextRequestPayload !== NEXT_REQUEST_BODY_DEFAULT_STATE ? (
              <FormPage
                nextRequestPayload={nextRequestPayload}
                setNextRequestPayload={setNextRequestPayload}
                handleNext={handleNext}
                questionSchemaToRender={questionSchemaToRender}
                isStateNotEligible={isStateNotEligible}
                allStates={allStates}
                userSelectedBrand={userSelectedBrand}
                setUserSelectedBrand={setUserSelectedBrand}
                notAcceptableError={notAcceptableError}
                setRecaptchaPool={setRecaptchaPool}
              />
            ) : null}
          </Box>
        </>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  formStepResponse: state.formSteps.questions,
});

const mapDispatchToProps = (dispatch) => ({
  submitStep: (payload) => dispatch(FormStepsActions.submitFormStep(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormMainPage);
