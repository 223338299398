import {capitalize} from "@mui/material";

/**
 * For dynamic labeling below method will be used to make the supplied labels to Camelcase
 * using MUI capitalize function.
 *
 * **/
export function camelCaseToLabelString(camelCaseVariable) {
  if (!camelCaseVariable) return "";
  const result = camelCaseVariable.replace(/([A-Z])/g, " $1");
  return capitalize(result ?? "");
}

/**
 * Below method will sort every choice according to the positions from BE
 * response payload.
 *
 * **/
export function sortChoices(choices) {
  return choices
    ?.sort((a, b) => {
      return a?.position - b?.position;
    })
    .map((choices) => {
      return choices?.id;
    })
    .map((id) => {
      return {
        id: choices.find((req) => req.id === id)?.id,
        choice: choices.find((req) => req.id === id)?.choice,
      };
    });
}

/**
 * Below function handles all onChange values other than demoForm like (radio,dropdown....)
 * */
export const handleChoiceSelection = ({
  setNextRequestPayload,
  nextRequestPayload,
  questionId,
  selectedChoice,
  field,
  value,
}) => {
  setNextRequestPayload(
    nextRequestPayload?.map((requestBody_) => {
      if (requestBody_?.questionId === questionId) {
        return {
          ...requestBody_,
          choiceIds: selectedChoice ? [selectedChoice] : [],
          value,
          field: field || undefined,
        };
      }
      return requestBody_;
    })
  );
};
