import { styled } from "@mui/material";

export const StyledModal = styled("div")(({ theme }) => ({
  top: `${50}%`,
  left: `${50}%`,
  transform: `translate(-${50}%, -${50}%)`,
  padding: "20px",
  maxWidth: "1000px",
  maxHeight: "100%",
  overflow: "scroll",
  position: "absolute",
  width: "auto",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #e0e0e0",
  borderRadius: "3px",
  boxShadow: theme.shadows[5],
}));

export const StyledSmallModal = styled("div")(({ theme }) => ({
  top: `${50}%`,
  left: `${50}%`,
  transform: `translate(-${50}%, -${50}%)`,
  padding: "20px",
  maxWidth: "650px",
  maxHeight: "100%",
  overflow: "scroll",
  position: "absolute",
  width: "auto",
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #e0e0e0",
  borderRadius: "3px",
  boxShadow: theme.shadows[5],
}));
