export function formatPhoneNumber(str) {
  if (!str) {
    return str;
  }
  if (str.toString().length > 12) {
    return str;
  }

  //Filter only numbers from the input
  const cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }

  return cleaned;
}

/**
 * Only numbers allowed in range 00000 - 99999
 * @param str the entered value
 * @returns {string} formatted
 */
export function formatZip(str) {
  if (!str) {
    return str;
  }
  //Filter only numbers from the input
  const cleaned = ("" + str).replace(/\D/g, "");

  return cleaned?.length > 5 ? cleaned.slice(0, 5) : cleaned;
}

/**
 * Zip digits must be 5
 * @param value
 * @returns {boolean}
 */
export function validateZip(value) {
  let value_ = value;
  if (typeof value === "number") value_ = Number(value).toString();
  value_ = formatZip(value_);
  return value_?.length === 5;
}

/**
 * For a number to be valid, it must contain 10 digits and 2 hyphens to format them
 * @param value
 * @returns {boolean|boolean}
 */
export function validatePhone(value) {
  const hyphenCount = (value?.match(/-/g) || []).length;
  return value ? value.toString().replaceAll("-", "").length === 10 && hyphenCount === 2 : false;
}

const VALID_EMAIL_REGEX = new RegExp(
  "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9!#$%&'*+/=?^_`{|}~-]+(\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)+|\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])$",
  "i"
);

/**
 * Validates the email
 * @param email the entered email
 * @returns {boolean} true if email is valid, else false
 */
export function validateEmail(email) {
  return VALID_EMAIL_REGEX.test(email);
}
