import {takeLatest, debounce} from "redux-saga/effects";
import {UI, USER_MANAGEMENT, FORM_STEPS, ADMIN_DASHBOARD} from "../constants/actions";
import * as ui from "./ui.saga";
import * as userManagement from "./user-management.saga";
import * as formSteps from "./form-steps.saga";
import * as adminDashboard from "./admin-dashboard.saga";

export default function* rootSaga() {
  yield takeLatest(UI.NAVIGATE, ui.updateUINavigateState);
  yield takeLatest(UI.PRELOAD_DATA, ui.preLoadData);

  // USER MANAGEMENT SAGAS
  yield takeLatest(USER_MANAGEMENT.LOGIN, userManagement.login);
  yield debounce(
    20000,
    USER_MANAGEMENT.REFRESH_SESSION_IN_BACKGROUND,
    userManagement.refreshSession
  );
  yield takeLatest(
    USER_MANAGEMENT.REFRESH_SESSION,
    userManagement.refreshSession
  );
  yield takeLatest(USER_MANAGEMENT.SETUP_USER, userManagement.setupUser);
  yield takeLatest(USER_MANAGEMENT.LIST_REQUEST, userManagement.getUsers);
  yield takeLatest(USER_MANAGEMENT.INVITE_USER, userManagement.inviteUser);
  yield takeLatest(USER_MANAGEMENT.UPDATE_USER, userManagement.updateUser);
  yield takeLatest(USER_MANAGEMENT.DELETE_USER, userManagement.deleteUser);
  yield takeLatest(USER_MANAGEMENT.LOGOUT, userManagement.logout);
  yield takeLatest(
    USER_MANAGEMENT.PING_USER_SERVICE,
    userManagement.pingUserService
  );
  yield takeLatest(USER_MANAGEMENT.RESEND_INVITE, userManagement.resendInvite);
  yield takeLatest(USER_MANAGEMENT.SET_PASSWORD, userManagement.setPassword);
  yield takeLatest(
    USER_MANAGEMENT.SEND_REGISTRATION_FORM,
    userManagement.register
  );

  // FORM STEP SAGAS
  yield takeLatest(FORM_STEPS.GET_STEP_QUESTIONS, formSteps.submitFormStep);
  yield takeLatest(
    FORM_STEPS.SUBMIT_CONSUMER_ORDER,
    formSteps.submitConsumerOrder
  );

  // ADMIN DASHBOARD SAGAS
  yield takeLatest(ADMIN_DASHBOARD.GET_QUOTAS, adminDashboard.getQuotas);
  yield takeLatest(ADMIN_DASHBOARD.CREATE_QUOTA, adminDashboard.createQuota);
  yield takeLatest(ADMIN_DASHBOARD.DELETE_QUOTA, adminDashboard.deleteQuota);
  yield takeLatest(ADMIN_DASHBOARD.UPDATE_QUOTA, adminDashboard.updateQuota);
  yield takeLatest(ADMIN_DASHBOARD.GET_PROGRESS, adminDashboard.getProgress);

  // CUSTOMER SAGAS
  yield takeLatest(ADMIN_DASHBOARD.ADD_CUSTOMER, adminDashboard.addCustomer);
  yield takeLatest(ADMIN_DASHBOARD.GET_CUSTOMERS, adminDashboard.getCustomers);

  // ORDERS SAGAS
  yield takeLatest(
    ADMIN_DASHBOARD.GET_BULK_ORDERS,
    adminDashboard.getBulkOrders
  );
  yield takeLatest(
    ADMIN_DASHBOARD.GET_CONSUMER_ORDERS,
    adminDashboard.getConsumerOrders
  );
  yield takeLatest(
    ADMIN_DASHBOARD.CREATE_BULK_ORDER,
    adminDashboard.createBulkOrder
  );
  yield takeLatest(
    ADMIN_DASHBOARD.UPDATE_BULK_ORDER,
    adminDashboard.updateBulkOrder
  );
}
