import React from "react";
import { Container, Paper, CssBaseline } from "@mui/material";
import { connect } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../themes/naloxoneRightNow";
import { Link } from "@mui/material";
import { IMAGES_URL } from "../../../constants/Images";

const NotFoundPage = (props: any) => {
  const authUser: any = sessionStorage.getItem("authUser");
  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <Container maxWidth="sm">
          <Paper>
            <img
              src={IMAGES_URL.NAX_LOGO}
              style={{ width: "50%", margin: "5% 25%" }}
              alt="fdi"
            />
            <div style={{ textAlign: "center" }}>
              <h3>Page Not Found</h3>
              {authUser ? (
                <Link href="/progress">Return to Admin Portal</Link>
              ) : (
                <Link href="/">Return to Home Page</Link>
              )}
            </div>
          </Paper>
        </Container>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
