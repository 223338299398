import React from "react";
import { Alert, Box, Stack, styled, Typography, Link } from "@mui/material";
import { paddings } from "../../themes/naloxoneRightNow";
import AssessmentToolBase from "../common/assessment-tool";
import FormMainPage from "../../components/assessment-tool-components/medication-form/FormMainPage";

function Form() {
  return (
    <AssessmentToolBase>
      <>
        <FormMainPage />
      </>
    </AssessmentToolBase>
  );
}

export default Form;
