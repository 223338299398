import React from "react";
import { connect } from "react-redux";
import Loading from "../../components/loading";
import authClient from "../../auth/ping";
import { UserManagementActions } from "../../redux/actions";
import { useHistory } from "react-router-dom";

const LoginPage = (props: any) => {
  const history = useHistory();
  const [authError, setAuthError] = React.useState<any>(false);

  const redirectToPing = () => {
    const url = window.location.pathname;
    authClient.clearSession();

    let state = authClient.generateRandomValue();
    let nonce = authClient.generateRandomValue();
    sessionStorage.setItem("state", state);
    sessionStorage.setItem("nonce", nonce);
    sessionStorage.setItem("url", url);

    authClient.authorize(state, nonce);
  };

  React.useEffect(() => {
    const params: any = authClient.parseAuthCode();
    if (!params.code) {
      redirectToPing();
    }

    if (params.code) {
      props.completeLogin({
        pingCode: params.code,
        onSuccess: (authUser: any) => {
          const adminLandingUrl =
            authUser && authUser.claims.role === "customer"
              ? "/bulk-orders"
              : "/progress";
          history.push(adminLandingUrl);
        },
        onError: (message: string) => {
          console.log("ERROR MESSAGE", message);
          setAuthError(message);
        },
      });
    }
  }, []);

  return (
    <div className="login-container center-flex">
      {authError ? (
        <p style={{ color: "red" }}>
          An authentication failure occurred. Message: {authError}
        </p>
      ) : (
        <Loading message="" />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  completeLogin: (payload: any) =>
    dispatch(UserManagementActions.login(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
