import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
  Link as LinkMui,
} from "@mui/material";
import { Link } from "react-router-dom";
import FormWrap from "../../components/assessment-tool-components/layout/FormWrap";
import FormActions from "../../components/assessment-tool-components/layout/FormActions";
import { MARKETING_SITE_PRIVACY } from "../../constants/marketingSite";
import AssessmentToolBase from "../common/assessment-tool";

export default function Landing() {
  const [checked, setChecked] = useState(false);

  return (
    <AssessmentToolBase>
      <>
        <Typography variant={`h2`} gutterBottom>
          Save a life! Carry Naloxone.
        </Typography>
        <FormWrap>
          <Box>
            <Typography variant={`body1`} gutterBottom>
              The United States continues to experience a public health crisis
              of opioid- related harm. Overdose is currently one of the leading
              causes of death for Americans under 50 years old and is
              contributing to a decline in national life expectancy. To attack
              this epidemic, federal, state, and local governments have sought
              effective means to reduce the opioid-related harm.
            </Typography>
            <Typography variant={`body1`} gutterBottom>
              As of June 2019, officials in 29 states have issued Statewide
              Standing Orders, which allow for licensed pharmacists to dispense
              Narcan without the recipient visiting a doctor. In total, 34
              states, including Washington D.C., have provided pharmacy access
              to Narcan either through standing orders or through other
              statewide means.
            </Typography>
            <Typography variant={`body1`} gutterBottom>
              FFF Enterprises Inc. and HarborPath are passionate about making 
              this life saving drug available to individuals at risk, friends 
              and family members and other individuals interested in carrying 
              Narcan.
            </Typography>
            <Typography variant={`body1`} gutterBottom>
              A person who administers Narcan should do so in the good faith
              belief that the person receiving Narcan is experiencing an
              overdose while they exercise reasonable care, including receiving
              instruction on how to use Narcan.
            </Typography>
            <Box mb={4}>
              <FormControlLabel
                value={checked}
                label={
                  <Typography>
                    <b>Please select the box to acknowledge our&nbsp;</b>
                    <LinkMui underline="always" href={MARKETING_SITE_PRIVACY}>
                      <b>Privacy Statement.</b>
                    </LinkMui>
                  </Typography>
                }
                control={
                  <Checkbox
                    onChange={(e) => {
                      const { checked } = e?.target;
                      setChecked(checked);
                    }}
                  />
                }
              />
            </Box>
          </Box>

          <FormActions>
            <FormControl>
              <Link to={"/welcome"}>
                <Button
                  color={`secondary`}
                  variant={`contained`}
                  disableRipple
                  disabled={!checked}
                >
                  Next
                </Button>
              </Link>
            </FormControl>
          </FormActions>
        </FormWrap>
      </>
    </AssessmentToolBase>
  );
}
