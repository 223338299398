import * as React from "react";
import { Button } from "@mui/material";
import { UserManagementActions } from "../../redux/actions";
import { connect } from "react-redux";
import { StyledFormControl } from "../../hooks/styles/formStyles";
import { StyledModal } from "../../hooks/styles";

function getImportModalStyle() {
  const modalTop = 50;
  const modalLeft = 50;

  return {
    top: `${modalTop}%`,
    left: `${modalLeft}%`,
    transform: `translate(-${modalTop}%, -${modalLeft}%)`,
    maxHeight: "100%",
    overflowX: "scroll" as "scroll",
  };
}

const ResendInvite: React.FC<any> = (props) => {
  return (
    <StyledModal>
      <h2>Resend Invite Email</h2>
      <p>
        Are you sure you want to resend an invite email to{" "}
        {props.thisUser.email}?
      </p>
      <>
        <StyledFormControl>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.resendInvite({ email: props.thisUser.email });
              props.close();
            }}
          >
            Yes
          </Button>
        </StyledFormControl>
        <StyledFormControl>
          <Button variant="contained" color="primary" onClick={props.close}>
            No
          </Button>
        </StyledFormControl>
      </>
    </StyledModal>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  resendInvite: (payload: string) =>
    dispatch(UserManagementActions.resendInviteEmail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendInvite);
