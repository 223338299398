import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {QUESTION_CATEGORY, QUESTION_TYPE} from "../../../constants/QuestionConstants";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Select,
} from "@mui/material";
import VideoPlayer from "../VideoPlayer";
import {camelCaseToLabelString} from "../../../utils/textHelper";
import ClickableAnswersPage from "../medication-form/ClickableAnswersPage";
import PdfViewer from "../PdfViewer";
import DOMPurify from "dompurify";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Clickable from "./Clickable";

/**
 * Each question backend returns in response, should be passed to this component. This is the component which converts question object to a UI.
 */
function Question(props) {
  const {
    choices,
    type,
    category,
    label,
    selectedChoices,
    handleChange,
    forcefullyDisabledNextButton,
    rightAnswerId,
    comment,
    setPageType,
    setUserSelectedBrand,
    mandatory,
  } = props;

  /**
   * To access type in State at FormPage.js to enable next button this setPageType will holds
   * the type value for Last pages(INFO/END..etc).
   * For last pages no payload with choicesIds to BE to normalize that logic this hook will handle accordingly.
   * **/
  useEffect(() => {
    if (typeof setPageType === "function") {
      setPageType(type);
    }
  }, []);

  return (
    <Box className={"Question"}>
      <FormControl fullWidth>
        <FormLabel id={label}>{label}</FormLabel>
        <>
          {type === QUESTION_TYPE.CLICKABLE && (
            <Stack spacing={4} width={"fit-content"}>
              {choices?.map((val) => (
                <Clickable
                  key={val?.id}
                  label={val?.choice}
                  checked={Boolean(
                    selectedChoices && selectedChoices?.some((id) => id === val?.id)
                  )}
                  onClick={() => {
                    const {choice, id} = val ?? {};
                    if (category === QUESTION_CATEGORY.DRUG_SELECTION) {
                      if (choice?.toLowerCase()?.includes("narcan")) setUserSelectedBrand("Narcan");
                      if (choice?.toLowerCase()?.includes("kloxxado"))
                        setUserSelectedBrand("Kloxxado");
                    }
                    if (selectedChoices?.some((choice) => choice === id)) handleChange(null);
                    else handleChange(id);
                  }}
                />
              ))}
            </Stack>
          )}

          {type === QUESTION_TYPE.RADIO_BUTTON && (
            <RadioGroup row onChange={(e) => handleChange(e?.target?.value)}>
              {choices?.map((val) => (
                <FormControlLabel
                  key={val?.id}
                  value={val?.id}
                  control={<Radio size="small" color="secondary" />}
                  label={camelCaseToLabelString(val.choice)}
                />
              ))}
            </RadioGroup>
          )}

          {type === QUESTION_TYPE.DROPDOWN && (
            <Box maxWidth={350} minWidth={350}>
              <Select
                labelId={label}
                onChange={(e) => handleChange(e?.target?.value)}
                required={mandatory}
                value={selectedChoices ? selectedChoices[0] ?? "" : ""}
                fullWidth
              >
                {choices
                  ?.sort((a, b) => (a?.position > b?.position ? -1 : 1))
                  ?.map((choiceSchema) => (
                    <MenuItem key={choiceSchema?.id} value={choiceSchema?.id}>
                      {choiceSchema?.choice}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          )}

          {type === QUESTION_TYPE.VIDEO && (
            <Box>
              <VideoPlayer
                url={choices?.length ? choices[0]?.choice : ""}
                onEnded={() => forcefullyDisabledNextButton(false)}
              />
            </Box>
          )}

          {type === QUESTION_TYPE.PDF && (
            <PdfViewer
              pdfUrl={choices?.length ? choices[0]?.choice : ""}
              onCompleteRead={() => forcefullyDisabledNextButton(false)}
            />
          )}

          {type === QUESTION_TYPE.CLICKABLE_ANSWER && (
            <ClickableAnswersPage
              choices={choices}
              selectedChoices={selectedChoices}
              handleChange={handleChange}
              rightAnswerId={rightAnswerId}
              comment={comment}
            />
          )}
          {type === QUESTION_TYPE.INFO || type === QUESTION_TYPE.END ? (
            <Box>
              <Typography display="inline" sx={{fontSize: 16, color: "#171717"}} variant="body1">
                <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(comment)}} />
              </Typography>
            </Box>
          ) : comment && type !== QUESTION_TYPE.CLICKABLE_ANSWER ? (
            <Box my={1} display={"flex"} alignItems={"flex-start"}>
              <InfoIcon color={"info"} fontSize={"small"} sx={{mr: 1}} />
              <Typography fontSize={"small"} color={"textSecondary"}>
                {comment}
              </Typography>
            </Box>
          ) : null}
        </>
      </FormControl>
    </Box>
  );
}

Question.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  comment: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.number.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedChoices: PropTypes.array,
  rightAnswerId: PropTypes.number,

  /**
   * Returns the selected choice
   */
  handleChange: PropTypes.func,
  handleEvaluationChoiceSelection: PropTypes.func,
  /**
   * Though the 'Next' button enable/disabled is handled using useEffect inside FormPage component, but if a forceful handler is required, use this.
   * True is want to set button as disabled, else false
   */
  forcefullyDisabledNextButton: PropTypes.func,
  setPageType: PropTypes.func,
  nextRequestPayload: PropTypes.array.isRequired,

  /**
   * Required for the question type DRUG_SELECTION. When user selects any brand, the FE stores that information
   * to later change text from Nalaxone to Narcan or Kloxxado.
   * For more: read function convertLabelToSelectedDrugNameText in component FormMainPage
   */
  setUserSelectedBrand: PropTypes.func,
};

export default Question;
