import React from "react";
import {styled} from "@mui/material";
import {margins} from "../../../themes/naloxoneRightNow";

const StyledFormFieldsWrap = styled("div")`
  margin-bottom: ${margins.gutterBottom * 1.5}px;
`;

export default function FormFieldsWrap({children}) {
  return <StyledFormFieldsWrap>{children}</StyledFormFieldsWrap>;
}
