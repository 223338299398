import { union } from 'lodash';

export const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const passwordRegex = {
  oneNumber: /^(?=.*\d)/,
  oneSpecialChar: /^(?=.*[~!@#$%^&*_?])/,
  oneLowercase: /^(?=.*[a-z])/,
  oneUppercase: /^(?=.*[A-Z])/,
  lengthLimits: /^.{8,255}$/
}


/*
* all resources of the logged-in user, corresponding to menu choices 
* bc you can only give other roles you yourself have
*
* The exception is the handling of Help video permissions:
* A user with "help_admin" resource can assign "help_viewer" roles to others,
* while a "help_viewer" cannot
* 
*/
export const getUserResources = (claims: any) => {
  let resources: string[] = [];
  if (!claims) {
    return [];
  }
  
  const customerId = Object.keys(claims)[0]
  if (!customerId) {
    return [];
  }

  Object.keys(claims[customerId]).forEach((resourceName) => {
    //viewers can't necessarily give other people viewer permissions,
    //unless they are also help_admin
    if (resourceName !== 'help_viewer') {
      resources.push(resourceName);
    }

    if (resourceName === 'help_admin') {
      resources.push('help_viewer');
    }    
  })

  return resources;
}

export const getUserRoles = (claims: any, resource: string) => {
  if (!claims || !resource) {
    return [];
  }

  const customerId = Object.keys(claims)[0];

  if (!customerId) {
    return [];
  }

  const userRole = claims[customerId][resource];
  
  if (!userRole) {
    return [];
  }
  if (userRole === 'OWNER') {
    return ['OWNER', 'VIEWER'];
  }
  return ['VIEWER'];
}

export const initUserType = (claims: any) => {
  if (!claims) {
    return ''
  }
  if (claims.accessGroups) {
    const userTypes = Object.keys(claims.accessGroups)

    if (userTypes.length > 0) {
      const userType = userTypes[0]
      if (userType === 'customer') {
        return claims.accessGroups[userType].includes('ALL') ? 'admin' : 'customer'
      }
      return userType
    }
    return ''
  }
  return Object.keys(claims).includes('ALL') ? 'admin' : 'customer'
}

export const initCustomers = (claims: any) => {
  if (!claims) {
    return []
  }
  if (claims.accessGroups) {
    return claims.accessGroups.customer ? claims.accessGroups.customer : []
  }
  return Object.keys(claims)
}

export const initAccessGroupId = (claims: any) => {
  if (!claims) {
    return ''
  }
  if (claims.accessGroups) {
    let userType = initUserType(claims)
    userType = userType === 'admin' ? 'customer' : userType
    return userType ? claims.accessGroups[userType][0] : ''
  }
  return ''
}

export const initRoles = (claims: any) => {
  if (!claims) {
    return {}
  }
  if (claims.roles) {
    return claims.roles
  }
  return {}
}

/*
* Return an array of personas assigned to this user (if any)
* use allAvailablePersonas object to map full data to names given in claims.personas
* exclude those marked "hidden"
*/
export const getEditedUserPersonas = (claims: any,  allAvailablePersonas: any) => {
  if (!claims || !claims.personas || !claims.personas.length || !allAvailablePersonas.length) {
    return [];
  }

  const allPersonaObjs = claims.personas.map((personaName: string) => {
    return allAvailablePersonas.find( (personaObj: any) => personaObj.identifier === personaName );
  });

  return allPersonaObjs.filter((po: any) => {
    return !(po.hidden)
  });
}

export const getEditedUserHelpViewerPermissions = (claims: any) => {
   return (claims && claims.roles && claims.roles['help_viewer']) ? claims.roles['help_viewer'] : [];
}

export const generateDataList = (data: any[]) => {
  const dataList: any[] = [];
  if (data) {
    data.forEach((item: any, key: number) => {

      let tenantsString = '';
      if (item.claims && Object.keys(item.claims).length > 0) {
        let claimsObj = Object.keys(item.claims);
        claimsObj.forEach((tenant, index) => {
          tenantsString += tenant;
          if (index < (claimsObj.length) - 1) {
            tenantsString += ',';
          }
        });
      }
      item.tenantsString = tenantsString;
      dataList.push(item);
    })
  }
  return dataList;
}

export const onDownloadUsers = (buildHead: any, buildBody: any, cols: any, data: any) => {
  cols[5].label = 'Customers'
  data = data.map((row: any) => {
      const claims = row.data[4]
      const userType = initUserType(claims)
      if (userType === 'admin') {
          row.data[5] = 'ALL'
          return row
      }
      if (claims.accessGroups) {
          if (!claims.accessGroups['customer']) {
              row.data[5] = `${userType}: ${claims.accessGroups[userType][0]}`
              return row
          }
          row.data[5] = claims.accessGroups['customer'].join(',')
          return row
      }
      row.data[5] = Object.keys(claims).join(',')
      return row
  })

  return "\uFEFF" + buildHead(cols) + buildBody(data);
}

export const combineRolesAndPersonas = (roles: any, personas: string[], allPersonaObjs: object[]) => {
  //first organize roles from the personas into a roles obj, with the highest permission winning in case of dupes
  const assembledRolesFromPersonas:any = {};

  personas.forEach((personaName) => {
    const thisPersonaObj:any = allPersonaObjs.find((thisPersona: any) => {
      return thisPersona.identifier === personaName;
    });

    if (thisPersonaObj && thisPersonaObj.roles) {
      Object.keys(thisPersonaObj.roles).forEach((thisRoleKey: any) => {
        const thisRoleValue = thisPersonaObj.roles[thisRoleKey];

        //if a role was defined on two personas, give the user the higher role
        if (!(assembledRolesFromPersonas[thisRoleKey])) {
          assembledRolesFromPersonas[thisRoleKey] = thisRoleValue;
        }
        else {
          assembledRolesFromPersonas[thisRoleKey] = pickHigherRole(thisRoleValue, assembledRolesFromPersonas[thisRoleKey]);
        }
      });
    }
  });

  //no "normal" roles, so use those from personas only!
  if (!roles) {
    return assembledRolesFromPersonas;
  }

  //Now that all roles from the personas are assembled, merge them with the "normal" roles
  Object.keys(assembledRolesFromPersonas).forEach((thisPersonaRoleKey: any) => {
    const thisPersonaRoleValue = assembledRolesFromPersonas[thisPersonaRoleKey];

    if (!(roles[thisPersonaRoleKey])) {
      roles[thisPersonaRoleKey] = thisPersonaRoleValue;
    }
    else {
      roles[thisPersonaRoleKey] = pickHigherRole(thisPersonaRoleValue, roles[thisPersonaRoleKey]);
    }
  });

  return roles;

  function pickHigherRole(roleValue1: string|string[], roleValue2: string|string[]) {

    //handle special help_viewer roles by giving them any folder permission contained in either array
    if (Array.isArray(roleValue1) && Array.isArray(roleValue2)) {
      return union(roleValue1, roleValue2);
    }

    //ROLES: ['OWNER', 'VIEWER']
    if (roleValue1 === 'OWNER' || roleValue2 === 'OWNER') {
      return 'OWNER';
    }
    else if (roleValue1 === 'VIEWER' || roleValue2 === 'VIEWER') {
      return 'VIEWER';
    }
    else {
      console.error('invalid roles specified!');
    }
  }
} 

export const getRoleLabel = (resourceName: string, roleValue: string|string[]) => {
  let label: string = `${resourceName}: `;

  if (Array.isArray(roleValue)) {
    if (!roleValue.length) {
      label += 'None'
    }
    else {
      roleValue.forEach((item: any) => {
        label += `${item}, `;
      });

      label = label.slice(0, -2);
    }
  }
  else {
    label += roleValue;
  }

  return label;
}


