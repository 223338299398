import React from "react";
import clsx from "clsx";
import {
  Container,
  Paper,
  CssBaseline,
  FormControl,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IMAGES_URL } from "../../constants/Images";
import { emailRegex, passwordRegex } from "../../utils/users.helper";
import { UserManagementActions } from "../../redux/actions";
import { connect } from "react-redux";
import Loading from "../../components/loading";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../themes/naloxoneRightNow";

const UserSetup = (props: any) => {
  const [verificationCode, setVerificationCode] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [passwordState, setPasswordState] = React.useState({
    lengthLimits: false,
    oneSpecialChar: false,
    oneNumber: false,
    oneUppercase: false,
    oneLowercase: false,
  } as any);
  const [codeFocused, setCodeFocused] = React.useState(false);
  const [emailFocused, setEmailFocused] = React.useState(false);
  const [passwordFocused, setPasswordFocused] = React.useState(false);
  const [passwordConfirmFocused, setPasswordConfirmFocused] =
    React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const passwordRequirements = () => (
    <>
      <span style={{ margin: "0.2em, auto" }}>
        <b>Password Requirements:</b>
        <br></br>
        <span style={{ color: !passwordState.lengthLimits ? "red" : "green" }}>
          be between 8 and 255 characters
        </span>
        <br></br>
        <span
          style={{ color: !passwordState.oneSpecialChar ? "red" : "green" }}
        >
          have at least 1 of the following characters: {`~!@#$%^&*_?`}
        </span>
        <br></br>
        <span style={{ color: !passwordState.oneNumber ? "red" : "green" }}>
          have at least 1 number
        </span>
        <br></br>
        <span style={{ color: !passwordState.oneUppercase ? "red" : "green" }}>
          have at least 1 uppercase letter
        </span>
        <br></br>
        <span style={{ color: !passwordState.oneLowercase ? "red" : "green" }}>
          have at least 1 lowercase letter
        </span>
        <br></br>
      </span>
    </>
  );

  const checkPasswordRequirement = (password: string) => {
    const result: any = {};
    Object.entries(passwordRegex).forEach((keyValue: any[]) => {
      result[keyValue[0]] = keyValue[1].test(password);
    });
    setPasswordState(result);
  };

  const handleTextFieldChange = (event: any, field: string) => {
    if (field === "code") {
      setVerificationCode(event.target.value);
    }
    if (field === "email") {
      setEmail(event.target.value);
    }
    if (field === "password") {
      checkPasswordRequirement(event.target.value);
      setPassword(event.target.value);
    }
    if (field === "passwordConfirm") {
      setPasswordConfirm(event.target.value);
    }
  };

  const passwordIsValid = () => {
    return (
      password &&
      Object.values(passwordState).length > 0 &&
      !Object.values(passwordState).includes(false)
    );
  };

  const emailIsValid = () => {
    return email && emailRegex.test(email);
  };

  const codeIsValid = () => {
    return verificationCode ? true : false;
  };

  const passwordConfirmIsValid = () => {
    return passwordConfirm && passwordConfirm === password;
  };

  const formIsValid = () => {
    if (!codeIsValid()) {
      return false;
    }
    if (!emailIsValid()) {
      return false;
    }
    if (!passwordIsValid()) {
      return false;
    }
    if (!passwordConfirmIsValid()) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!formIsValid()) {
      return;
    }
    props.submitUserSetup({
      email: email,
      password: password,
      code: verificationCode,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline />
        <Container maxWidth="sm">
          <Paper>
            <img
              src={IMAGES_URL.NAX_LOGO}
              style={{ width: "50%", margin: "5% 25%" }}
              alt="fdi"
            />
            <div style={{ textAlign: "center" }}>
              <h3>User Setup</h3>
              <form noValidate autoComplete="off">
                <h4 style={{ marginBottom: "0.2em" }}>
                  Enter Verification Code
                </h4>
                <FormControl
                  style={{
                    margin: "0.5em",
                    minWidth: "75%",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    label="Verification Code"
                    variant="outlined"
                    type="text"
                    value={verificationCode}
                    onBlur={() => setCodeFocused(true)}
                    error={codeFocused && !codeIsValid() ? true : false}
                    helperText={
                      codeFocused && !codeIsValid()
                        ? "Please enter a verification code"
                        : ""
                    }
                    onChange={(event) => {
                      handleTextFieldChange(event, "code");
                    }}
                    required
                  />
                </FormControl>
                <h4 style={{ marginBottom: "0.2em" }}>Confirm Your Email</h4>
                <FormControl
                  style={{
                    margin: "0.5em",
                    minWidth: "75%",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onBlur={() => setEmailFocused(true)}
                    error={emailFocused && !emailIsValid() ? true : false}
                    helperText={
                      emailFocused && !emailIsValid()
                        ? "Please enter a valid email"
                        : ""
                    }
                    onChange={(event) => {
                      handleTextFieldChange(event, "email");
                    }}
                    required
                  />
                </FormControl>
                <br></br>
                <h4 style={{ marginBottom: "0.2em" }}>Set Your Password</h4>
                <FormControl
                  style={{
                    margin: "0.5em",
                    minWidth: "75%",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={passwordFocused && !passwordIsValid() ? true : false}
                    onFocus={() => setPasswordFocused(true)}
                    helperText={
                      passwordFocused && !passwordIsValid()
                        ? passwordRequirements()
                        : ""
                    }
                    onChange={(event) => {
                      handleTextFieldChange(event, "password");
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <br></br>
                <FormControl
                  style={{
                    margin: "0.5em",
                    minWidth: "75%",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={passwordConfirm}
                    onBlur={() => setPasswordConfirmFocused(true)}
                    error={
                      passwordConfirmFocused && !passwordConfirmIsValid()
                        ? true
                        : false
                    }
                    helperText={
                      passwordConfirmFocused && !passwordConfirmIsValid() ? (
                        <span style={{ color: "red" }}>
                          Password confirmation doesn't match
                        </span>
                      ) : (
                        ""
                      )
                    }
                    onChange={(event) => {
                      handleTextFieldChange(event, "passwordConfirm");
                    }}
                    required
                  />
                </FormControl>
                <br></br>
                <br></br>
                {!props.isLoading && (
                  <FormControl
                    style={{
                      margin: "0.5em",
                      minWidth: "75%",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      style={{
                        margin: "10px 0 0 10px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </Button>
                  </FormControl>
                )}
                {props.error && (
                  <span style={{ color: "red" }}>
                    <br></br>
                    {props.error === "setup complete" ? (
                      <>
                        An account for this user has already been setup.
                        <br></br>
                        Please login at{" "}
                        <a href="/">app.rightnowinventory.com</a>
                      </>
                    ) : (
                      <>
                        User setup failed. Please contact support.
                        <br />
                        Error Message: {props.error}
                      </>
                    )}
                  </span>
                )}
                {props.isLoading ? <Loading message="Submitting..." /> : ""}
              </form>
            </div>
          </Paper>
        </Container>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => ({
  isLoading: state.ui.isLoading,
  error: state.userManagement.setupError,
});

const mapDispatchToProps = (dispatch: any) => ({
  submitUserSetup: (payload: any) =>
    dispatch(UserManagementActions.sendUserSetup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSetup);
