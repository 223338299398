import config from '../config'

export const MARKETING_SITE_ROOT_URL = config.marketingSite.url

export const TRACK_MY_SHIPMENT = "https://www.fedex.com/en-us/tracking.html";
export const MARKETING_SITE_NEWS = MARKETING_SITE_ROOT_URL + "/category/news";
export const MARKETING_SITE_CONTACT_US = MARKETING_SITE_ROOT_URL + "/contact-us";
export const MARKETING_SITE_OPIOIDS = MARKETING_SITE_ROOT_URL + "/opioids";
export const MARKETING_SITE_TRAINING = MARKETING_SITE_ROOT_URL + "/training";
export const MARKETING_SITE_RESOURCES = MARKETING_SITE_ROOT_URL + "/resources";
export const MARKETING_SITE_ABOUT_US = MARKETING_SITE_ROOT_URL + "/about-us";
export const MARKETING_SITE_TERMS_OF_USE = MARKETING_SITE_ROOT_URL + "/terms-of-use";
export const MARKETING_SITE_TERMS_OF_SALE = MARKETING_SITE_ROOT_URL + "/terms-of-sale";
export const MARKETING_SITE_PRIVACY = MARKETING_SITE_ROOT_URL + "/privacy-statement";
export const MARKETING_SITE_ = MARKETING_SITE_ROOT_URL + "";
