export const hasAccess = (path: string) => {
  const authUserString: string = sessionStorage.getItem("authUser") || "";
  if (!authUserString) {
    return false;
  }

  const authUser = JSON.parse(authUserString);

  const role = authUser.claims.role;
  const roleAccessLevels: any = {
    customer: 1,
    admin: 2,
    superAdmin: 3,
  };
  const routeAccessLevels: any = {
    "/progress": 2,
    "/quotas": 2,
    "/bulk-orders": 1,
    "/user-management": 3,
    "/customers": 2,
    "/report-subscriptions": 2,
    "/consumer-orders": 2,
  };
  if (routeAccessLevels[path] > roleAccessLevels[role]) {
    return false;
  }
  return true;
};
