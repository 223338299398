import React from "react";
import { Alert, Box, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { paddings } from "../../themes/naloxoneRightNow";
import AssessmentToolBase from "../common/assessment-tool";

const StyledBox = styled(Box)`
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 20px;
  border: 2px solid #e3e3e3;
  line-height: 20px;
  padding: 17px ${paddings.inputIndent}px 20px ${paddings.inputIndent}px;
  border-radius: 5px;
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba(19, 50, 87, 0.04);
  }
`;

function Welcome() {
  return (
    <AssessmentToolBase>
      <>
        <Box className={"WelcomePage"}>
          <Box mb={4}>
            <Alert icon={false} severity={"info"}>
              <Typography variant="body1">
                Naloxone Right Now is a service provided in partnership by FFF
                Enterprises Inc. and HarborPath. Through our secure online
                portal, our streamlined ordering process makes it easy and
                discreet for consumers and organizations to get the overdose
                medications they need, when they need them. Please select one of
                the following to proceed.
              </Typography>
            </Alert>
          </Box>
          <Stack alignItems={"center"} spacing={2}>
            <Link to={"/form"}>
              <StyledBox>I’m a Consumer</StyledBox>
            </Link>
            {/* <a href={"https://nufactor.com"}>
          <StyledBox>I’m an Organization</StyledBox>
        </a> */}
          </Stack>
        </Box>
      </>
    </AssessmentToolBase>
  );
}

export default Welcome;
