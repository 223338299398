import createReducer from "./createReducer";
import { FormStepsActions } from "../actions";
import { FORM_STEPS } from "../constants/actions";

export const formSteps = createReducer(
  {},
  {
    [FORM_STEPS.SET_STEP_QUESTIONS](
      state = {},
      action: ReturnType<typeof FormStepsActions.setFormStepResponse>
    ) {
      return { ...state, questions: action.payload };
    },
    [FORM_STEPS.SET_STEP](
      state = {},
      action: ReturnType<typeof FormStepsActions.setFormStep>
    ) {
      return { ...state, number: action.payload };
    },
    [FORM_STEPS.SET_CONSUMER_ORDER_PAYLOAD](
      state = {},
      action: ReturnType<typeof FormStepsActions.setConsumerOrderPayload>
    ) {
      return { ...state, consumerOrderPayload: action.payload };
    },
    [FORM_STEPS.SET_UNITS_REMAINING](
      state = {},
      action: ReturnType<typeof FormStepsActions.setUnitsRemaining>
    ) {
      return { ...state, remainingUnits: action.payload };
    },
    [FORM_STEPS.SET_ANSWERS](
      state = {},
      action: ReturnType<typeof FormStepsActions.setFormAnswers>
    ) {
      return { ...state, answers: action.payload };
    },
  }
);
