import React from 'react';
import {styled} from "@mui/material";
import {margins} from "../../../themes/naloxoneRightNow";

const StyledFormWrap = styled("div")`
  margin-bottom: ${margins.gutterBottom * 2}px;
`;

export default function FormWrap({children}) {
  return <StyledFormWrap>{children}</StyledFormWrap>;
}
