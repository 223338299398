import * as React from "react";
import ReactDOM from "react-dom/client";
import {Provider, ReactReduxContext} from "react-redux";
import configureStore from "./redux/store/store";
import "./assets/css/app.css";
import Routes from "./routes/routes";
//hotjar.initialize(config.hotjar.trackingId, 1);
//if user previously selected dark theme, we save it in the DB and also localStorage
//this way, we avoid a "flash" by pre-setting Body dark-mode css before the user service has returned
const preloadDarkTheme = localStorage.getItem("fdiPortal_themePreference") === "dark";
const bgColor = preloadDarkTheme ? "#303030" : "#fff";
const textColor = preloadDarkTheme ? "#fff" : "#000";
const css = `
    body {
      background-color: ${bgColor};
      color: ${textColor};
      height: 100%;
      margin: 0;
    },
    html {
      height: 100%
    }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <>
    <style>{css}</style>
    <Provider store={configureStore({})} context={ReactReduxContext}>
      <Routes></Routes>
    </Provider>
  </>
);
