import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

const ActionsMenu: React.FC<any> = (props: any) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
  return (
    <div>
      <IconButton
        aria-haspopup="true"
        aria-controls="long-menu"
        aria-label="more"
        style={{ padding: 0 }}
        onClick={(e) => {
          setMenuAnchor(e.currentTarget);
          setMenuOpen(!menuOpen);
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        keepMounted
        id="long-menu"
        open={menuOpen}
        anchorEl={menuAnchor}
        MenuListProps={{ id: "actions-menu" }}
        PaperProps={{
          style: {
            height: "auto",
            width: "20ch",
          },
        }}
        onClose={() => {
          setMenuOpen(false);
          setMenuAnchor(null);
        }}
      >
        {props.actionItems.map((actionItem: any, index: number) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                actionItem.onClick();
                setMenuOpen(false);
                setMenuAnchor(null);
              }}
              id={menuOpen ? actionItem.id : ""}
            >
              {actionItem.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ActionsMenu;
