import {Container, Grid, Link, Stack, Typography, styled} from "@mui/material";
import CustomMenu from "./CustomMenu";
import {theme} from "../../themes/naloxoneRightNow";
import logo from "../../assets/images/logo-inverse.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagramSquare,
  faLinkedinIn,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import * as React from "react";
import {
  MARKETING_SITE_ABOUT_US,
  MARKETING_SITE_CONTACT_US,
  MARKETING_SITE_NEWS,
  MARKETING_SITE_OPIOIDS,
  MARKETING_SITE_PRIVACY,
  MARKETING_SITE_RESOURCES,
  MARKETING_SITE_TERMS_OF_SALE,
  MARKETING_SITE_TERMS_OF_USE,
  MARKETING_SITE_TRAINING,
  TRACK_MY_SHIPMENT,
} from "../../constants/marketingSite";

const StyledFooter = styled("footer")`
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.light};
  padding: 90px 0;

  a {
    color: inherit;
  }

  ul {
    height: 100%;
  }
  li {
    line-height: 40px;
  }
`;

const StyledSocialStack = styled("div")`
  display: none;
  justify-content: center;
  margin: 60px 0 36px 0;
  a {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #b8c2cd;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: 24px;
      max-height: 24px;
    }
  }
  a + a {
    margin-left: 24px;
  }
`;

const StyledFooterLegalMenuWrap = styled("div")`
  margin: 0 0 8px 0;
  li {
    line-height: 1;
  }
  li + li a {
    padding-left: 16px;
    margin-left: 16px;
    border-left: 1px solid ${theme.palette.primary.light};
  }
  li:last-child a {
    color: ${theme.typography.subtitle1.color};
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    ul {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
    }
    li + li a {
      padding: 0;
      margin: 0;
      border-left: 0;
    }
  }
`;

export default function Footer() {
  const columnOneMenuItems = [
    {label: `Opioids`, href: MARKETING_SITE_OPIOIDS},
    {label: `Training`, href: MARKETING_SITE_TRAINING},
    {label: `Resources`, href: MARKETING_SITE_RESOURCES},
    {label: `News`, href: MARKETING_SITE_NEWS},
    {label: `About Us`, href: MARKETING_SITE_ABOUT_US},
  ];

  const columnTwoMenuItems = [
    {label: `Track My Shipment`, href: TRACK_MY_SHIPMENT},
    {label: `Contact Us`, href: MARKETING_SITE_CONTACT_US},
  ];

  const legalMenuItems = [
    {label: `Terms of Use`, href: MARKETING_SITE_TERMS_OF_USE},
    {label: `Terms of Sale`, href: MARKETING_SITE_TERMS_OF_SALE},
    {label: `Privacy Statement`, href: MARKETING_SITE_PRIVACY},
    {label: `Do Not Sell My Personal Information`, href: `#`},
  ];

  return (
    <StyledFooter>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <CustomMenu items={columnOneMenuItems} direction={`vertical`} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CustomMenu items={columnTwoMenuItems} direction={`vertical`} align={`left`} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: {xs: `flex-start`, md: `flex-end`},
              mt: {xs: `48px`, md: 0},
            }}
          >
            <img src={logo} alt={`Naloxone Right Now inverse logo`} width={"222px"} />
            <Typography variant={`body2`} sx={{color: theme.palette.primary.light, mt: `16px`}}>
              44000 Winchester Road <br />
              Temecula, CA 92590
            </Typography>
          </Grid>
        </Grid>

        <Stack direction={`column`} alignItems={`center`} justifyContent={`center`}>
          <StyledSocialStack>
            <Link href={`#`}>
              <FontAwesomeIcon size={`xl`} icon={faFacebookF} color={theme.palette.primary.main} />
            </Link>
            <Link href={`#`}>
              <FontAwesomeIcon
                size={`xl`}
                icon={faInstagramSquare}
                color={theme.palette.primary.main}
              />
            </Link>
            <Link href={`#`}>
              <FontAwesomeIcon size={`xl`} icon={faLinkedinIn} color={theme.palette.primary.main} />
            </Link>
            <Link href={`#`}>
              <FontAwesomeIcon
                size={`xl`}
                icon={faYoutubeSquare}
                color={theme.palette.primary.main}
              />
            </Link>
          </StyledSocialStack>

          <StyledFooterLegalMenuWrap>
            <CustomMenu items={legalMenuItems} />
          </StyledFooterLegalMenuWrap>

          <Typography variant={`body2`} sx={{color: theme.palette.primary.light}}>
            &copy; {new Date().getFullYear()} Naloxone Right Now
          </Typography>
        </Stack>
      </Container>
    </StyledFooter>
  );
}
