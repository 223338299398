import React from "react";
import { connect } from "react-redux";
import BasePage from "../common/base";
import AdminPageContainer from "../../components/admin-components/page-container";
import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { UserManagementActions } from "../../redux/actions";
import { toast } from "react-toastify";

const ReportSubscriptions = (props: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [subscriptions, setSubscriptions] = React.useState<any>({});

  React.useEffect(() => {
    setSubscriptions({
      quotaThresholds: props.authUser?.subscriptions?.quotaThresholds || false,
      weeklyOrders: props.authUser?.subscriptions?.weeklyOrders || false,
      monthlyOrders: props.authUser?.subscriptions?.monthlyOrders || false,
    });
  }, [props.authUser]);

  const updateSubscription = (event: any, subscriptionName: string) => {
    setSubscriptions({
      ...subscriptions,
      [subscriptionName]: event.target.checked,
    });
  };

  const updateUser = () => {
    setIsLoading(true);
    const requestBody = {
      subscriptions: subscriptions,
      id: props.authUser.id,
    };
    const payload = {
      requestBody: requestBody,
      success: () => {
        setIsLoading(false);
        props.refreshAuthUser();
        toast.success("Report subscription settings have been saved.");
      },
      error: () => {
        setIsLoading(false);
        toast.error("Failed to update subscriptions.");
      },
    };

    props.updateUser(payload);
  };

  return (
    <BasePage title={"Report Subscriptions"}>
      <AdminPageContainer>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  disabled={isLoading}
                  checked={subscriptions.quotaThresholds || false}
                  onChange={(event: any) => {
                    updateSubscription(event, "quotaThresholds");
                  }}
                />
              }
              label="Subscribe to Daily Quota Progress Report"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  disabled={isLoading}
                  checked={subscriptions.weeklyOrders || false}
                  onChange={(event: any) => {
                    updateSubscription(event, "weeklyOrders");
                  }}
                />
              }
              label="Subscribe to Weekly Orders Report"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  disabled={isLoading}
                  checked={subscriptions.monthlyOrders || false}
                  onChange={(event: any) => {
                    updateSubscription(event, "monthlyOrders");
                  }}
                />
              }
              label="Subscribe to Monthly Orders Report"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" size="small" onClick={updateUser}>
              Save
            </Button>
          </Grid>
        </Grid>
      </AdminPageContainer>
    </BasePage>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (payload: any) =>
    dispatch(UserManagementActions.updateUser(payload)),
  refreshAuthUser: () => dispatch(UserManagementActions.pingUserService()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSubscriptions);
