import React from "react";
import {FormControl, styled} from "@mui/material";
import {positions} from "../../../themes/naloxoneRightNow";

const StyledFormControl = styled((props) => <FormControl {...props} />)(() => ({
  ".MuiFormLabel-root": {},
  ".MuiOutlinedInput-notchedOutline legend": {
    display: `none`,
  },
  ".MuiInputLabel-root": {
    fontSize: `20px`,
    color: `#707070`,
    opacity: 0.5,
  },
  ".MuiInputLabel-shrink": {
    fontSize: `14px`,
    top: `${positions.label.top}px`,
    left: `${positions.label.left}px`,
  },
}));

export default function SelectFormControl(props) {
  return <StyledFormControl {...props} />;
}
