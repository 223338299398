/**
 * Converts a date in format like "2022-01-01" to a local timezone javascript date. <br/>
 * Doing this using new Date(date) creates a date instance in UTC. This makes trouble for the timezones which are behind UTC (e.g EST, PST), resulting in 1 day off.
 * @param dateOnlyString date string like "2022-01-01"
 * @returns {null|Date} a javascript date with browser's timezone
 */
export function fromDateOnlyToLocalDateTime(dateOnlyString) {
  if (dateOnlyString) {
    const dateArray = dateOnlyString.split("-");
    const year = dateArray[0];
    const month = parseInt(dateArray[1], 10) - 1;
    const date = dateArray[2];
    return new Date(year, month, date);
  } else return null;
}
