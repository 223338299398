import { action } from 'typesafe-actions';
import { USER_MANAGEMENT } from '../constants/actions';

export const getUsers   = (filter: any) => action(USER_MANAGEMENT.LIST_REQUEST, filter);
export const setUsers   = (data: any) => action(USER_MANAGEMENT.LIST_RESPONSE, data);
export const inviteUser = (payload: any) => action(USER_MANAGEMENT.INVITE_USER, payload);
export const updateUser = (payload: any) => action(USER_MANAGEMENT.UPDATE_USER, payload);
export const deleteUser = (payload: any) => action(USER_MANAGEMENT.DELETE_USER, payload);
export const login = (payload: any) => action(USER_MANAGEMENT.LOGIN, payload);
export const sendUserSetup = (payload: any) =>
  action(USER_MANAGEMENT.SETUP_USER, payload);
export const setUserSetupError = (payload: any) =>
  action(USER_MANAGEMENT.SETUP_ERROR, payload);
export const setAuthUser = (payload: any) => action(USER_MANAGEMENT.SET_AUTH_USER, payload);
export const refreshSession = (payload: any) => action(USER_MANAGEMENT.REFRESH_SESSION, payload);
export const refreshSessionInBackground = () => action(USER_MANAGEMENT.REFRESH_SESSION_IN_BACKGROUND);
export const logout = (history: any) => action(USER_MANAGEMENT.LOGOUT, history);
export const pingUserService = () => action(USER_MANAGEMENT.PING_USER_SERVICE);
export const resendInviteEmail = (payload: any) =>
  action(USER_MANAGEMENT.RESEND_INVITE, payload);
export const setOneTimePassword = (payload: any) =>
  action(USER_MANAGEMENT.SET_PASSWORD, payload);
export const sendRegistrationForm = (payload: any) =>
  action(USER_MANAGEMENT.SEND_REGISTRATION_FORM, payload);
