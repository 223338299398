import * as React from "react";
import { FormControl, Button, Modal, Tooltip } from "@mui/material";
import dataList from "../../constants/dataList";
import MUIDataTable from "mui-datatables";
import Loading from "../../components/loading";
import UserFormModal from "./user-form-modal.component";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteForever } from "@mui/icons-material";
import DeleteModal from "../../components/deleteModal";
import ActionsMenu from "../../components/actions-menu";
import moment from "moment";
import ResendInviteModal from "./resendInvite.modal";
import SetPasswordModal from "./setPassword.modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchFilter from "../../components/searchFilter";

interface UserManagementProps {
  authUser?: any;
  users: any[];
  totalUsers: any;
  globalRowsPerPage: any;
  getUsers: (filter: any) => void;
  inviteUser: (payload: any) => void;
  resendInvite: (payload: any) => void;
  setPassword: (payload: any) => void;
  updateUser: (payload: any) => void;
  deleteUser: (payload: any) => void;
}

export const UserManagementComponent: React.FC<UserManagementProps> = (
  props
) => {
  // const classes = CommonStyles();
  const firstRender = React.useRef(false);
  const [modalOpen, setModalOpen] = React.useState<string>("");
  const [deleteId, setDeleteId] = React.useState(null as any);
  const [deleteEmail, setDeleteEmail] = React.useState(null as any);
  const [userToEdit, setUserToEdit] = React.useState(null as any);
  const [sortOrder, setSortOrder] = React.useState(null as any);
  const [filters, setFilters] = React.useState<any>({});
  const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
  const [pagination, setPageState] = React.useState({
    offset: 0,
    limit: props.globalRowsPerPage,
    page: 0,
  });

  const renderActionsMenu: any = (tableMeta: any) => {
    const actionItems = [
      {
        onClick: () => {
          setUserToEdit(props.users[tableMeta.rowIndex]);
          setModalOpen("EDIT");
        },
        id: "edit-user",
        title: "Edit",
      },
      {
        onClick: () => {
          const thisUser = props.users[tableMeta.rowIndex];
          setDeleteId(thisUser.id);
          setDeleteEmail(thisUser.email);
          setModalOpen("DELETE");
        },
        id: "delete-user",
        title: "Delete",
      },
      {
        onClick: () => {
          setUserToEdit(props.users[tableMeta.rowIndex]);
          setModalOpen("INVITE");
        },
        id: "invite-user",
        title: "Resend Invite",
      },
      {
        onClick: () => {
          setUserToEdit(props.users[tableMeta.rowIndex]);
          setModalOpen("PASSWORD");
        },
        id: "set-password",
        title: "Reset Password",
      },
    ];
    return <ActionsMenu actionItems={actionItems} />;
  };

  const columnData = [
    {
      label: "First Name",
      name: "firstName",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Email",
      name: "email",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Last Login",
      name: "lastLogin",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Role",
      name: "role",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "",
      name: "",
      change: false,
      viewColumns: false,
      display: true,
      sort: false,
      download: false,
      customBodyRender: (value: any, tableMeta: any) => {
        return renderActionsMenu(tableMeta);
      },
    },
  ];

  const formatData = (data: any[]) => {
    const roleMap: any = {
      superAdmin: "Super Admin",
      admin: "Admin",
      customer: "Customer",
    };
    return data.map((row: any) => {
      return {
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        lastLogin: row.lastLogin
          ? moment(row.lastLogin).format("YYYY-MM-DD hh:mm")
          : "UNKNOWN",
        role: roleMap[row.claims.role],
      };
    });
  };

  const getTableColumns = () => {
    return columnData.map((column: any, index: number) => {
      return {
        label: column.label,
        name: column.name,
        options: {
          filter: true,
          display: tableStatePersist
            ? tableStatePersist.columns[index].display
            : column.display,
          change: column.change,
          sort: column.sort ?? true,
          viewColumns: column.viewColumns,
          download: column.download === false ? false : true,
          ...(column.customBodyRender && {
            customBodyRender: column.customBodyRender,
          }),
        },
      };
    });
  };

  const getFilter = (reset?: boolean) => {
    let payloadFilters: any[] = Object.keys(filters)
      .filter((filterKey: string) => filters[filterKey])
      .map((filterKey: string) => {
        return { key: filterKey, value: filters[filterKey] };
      });
    return payloadFilters;
  };

  const filterUsers = (reset?: boolean) => {
    props.getUsers(getFilter(reset));
  };

  const showLoadingMsg = () => !props.authUser && props.users.length === 0;

  const handleModalClose = () => {
    setModalOpen("");
    setUserToEdit(null);
  };

  const triggerAddModal = () => {
    setUserToEdit(null);
    setModalOpen("ADD");
  };

  React.useEffect(() => {
    if (Object.keys(filters).length === 0) filterUsers();
  }, [filters]);

  return (
    <div>
      {showLoadingMsg() ? (
        <div>
          <Loading message="" />
        </div>
      ) : (
        <div>
          {/* <div className={classes.searchWrap}>

			</div> */}
          <div style={{ marginBottom: "50px" }}>
            <SearchFilter
              config={[
                {
                  label: "First Name",
                  name: "firstName",
                  type: "text",
                },
                {
                  label: "Last Name",
                  name: "lastName",
                  type: "text",
                },
                {
                  label: "Email",
                  name: "email",
                  type: "text",
                },
                {
                  label: "Role",
                  name: "role",
                  type: "autoComplete",
                  selectOptions: [
                    { label: "Customer", value: "customer" },
                    { label: "Admin", value: "admin" },
                    { label: "Super Admin", value: "superAdmin" },
                  ],
                },
                {
                  label: "Last Login",
                  name: "lastLogin",
                  type: "date",
                },
              ]}
              onFilterChange={setFilters}
              filters={filters}
              handleSearch={() => filterUsers()}
              resetFilters={() => setFilters({})}
            />
          </div>
          <div>
            <FormControl>
              <Button
                variant="outlined"
                onClick={() => {
                  triggerAddModal();
                }}
              >
                Add User
              </Button>
            </FormControl>
          </div>
          <div style={{ marginTop: 20 }}>
            <MUIDataTable
              data={formatData(props.users)}
              columns={getTableColumns()}
              options={{
                rowsPerPage: pagination.limit,
                rowsPerPageOptions: dataList.PageLimitOptions,
                onChangeRowsPerPage: (numberOfRows: number) => {
                  setPageState({ ...pagination, limit: numberOfRows });
                },
                filterType: "dropdown",
                responsive: "simple",
                filter: false,
                search: false,
                download: true,
                print: true,
                selectableRows: "none",
                serverSide: false,
                page: pagination.page,
                count: props.totalUsers,
                onColumnSortChange: (
                  changedColumn: string,
                  direction: string
                ) => {
                  setSortOrder(`${changedColumn} ${direction.toUpperCase()}`);
                },
                onTableChange: (tableAction: any, tableState: any) => {
                  switch (tableAction) {
                    case "changePage":
                      if (tableState.page > pagination.page) {
                        setPageState({
                          offset: pagination.offset + props.globalRowsPerPage,
                          limit: props.globalRowsPerPage,
                          page: tableState.page,
                        });
                      } else if (tableState.page < pagination.page) {
                        setPageState({
                          offset: pagination.offset - props.globalRowsPerPage,
                          limit: props.globalRowsPerPage,
                          page: tableState.page,
                        });
                      }
                      break;
                    case "viewColumnsChange":
                      setTableStatePersist(tableState);
                      break;
                  }
                },
              }}
            />
          </div>
          {/* :
				<EmptyContent message="Change the filter criteria above to view product information." />
			} */}
          <Modal
            open={["EDIT", "ADD"].includes(modalOpen)}
            onClose={(e: any, reason: string) => {
              if (reason === "backdropClick") return;
              handleModalClose();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <UserFormModal
                getUsers={filterUsers}
                user={userToEdit}
                inviteUser={props.inviteUser}
                updateUser={props.updateUser}
                closeModal={handleModalClose}
              />
            </div>
          </Modal>
          <Modal
            open={modalOpen === "DELETE"}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <DeleteModal
                deleteId={deleteId}
                title={"Delete User"}
                message={`Are you sure you want to delete the user with email ${deleteEmail}`}
                onDelete={props.deleteUser}
                onSuccess={filterUsers}
                closeModal={handleModalClose}
              />
            </div>
          </Modal>
          <Modal
            open={modalOpen === "INVITE"}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <ResendInviteModal
                thisUser={userToEdit}
                resendInvite={props.resendInvite}
                close={handleModalClose}
              />
            </div>
          </Modal>
          <Modal
            open={modalOpen === "PASSWORD"}
            onClose={handleModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div>
              <SetPasswordModal
                thisUser={userToEdit}
                setPassword={props.setPassword}
                close={handleModalClose}
              />
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
