import {AdminDashboardActions} from "../actions";
import {ADMIN_DASHBOARD} from "../constants/actions";
import createReducer from "./createReducer";

export const adminDashboard = createReducer(
  {},
  {
    [ADMIN_DASHBOARD.GET_QUOTAS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.getQuotas>
    ) {
      return { ...state, isLoading: false };
    },
    [ADMIN_DASHBOARD.SET_BULK_ORDERS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setBulkOrders>
    ) {
      return { ...state, bulkOrders: action.payload };
    },
    [ADMIN_DASHBOARD.SET_CONSUMER_ORDERS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setConsumerOrders>
    ) {
      return { ...state, consumerOrders: action.payload };
    },
    [ADMIN_DASHBOARD.SET_QUOTAS_LOADING](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setQuotasLoading>
    ) {
      return { ...state, isLoading: action.payload.isLoading };
    },
    [ADMIN_DASHBOARD.SET_QUOTAS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setQuotas>
    ) {
      return { ...state, quotas: action.payload };
    },
    [ADMIN_DASHBOARD.SET_CUSTOMERS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setCustomers>
    ) {
      return { ...state, customers: action.payload };
    },
    [ADMIN_DASHBOARD.SET_PRODUCTS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setProducts>
    ) {
      return { ...state, products: action.payload };
    },
    [ADMIN_DASHBOARD.SET_US_STATES](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setUsStates>
    ) {
      return { ...state, usStates: action.payload };
    },
    [ADMIN_DASHBOARD.DELETING_QUOTA](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setIsDeletingQuota>
    ) {
      return { ...state, isDeletingQuota: action.payload };
    },
    [ADMIN_DASHBOARD.DELETING_QUOTA](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setIsUpdatingQuota>
    ) {
      return { ...state, isUpdatingQuota: action.payload };
    },

    // Progress Tab
    [ADMIN_DASHBOARD.GET_PROGRESS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.getProgress>
    ) {
      return { ...state, isLoading: false };
    },
    [ADMIN_DASHBOARD.SET_PROGRESS](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setProgress>
    ) {
      return { ...state, progress: action.payload };
    },
    [ADMIN_DASHBOARD.SET_PROGRESS_LOADING](
      state = {},
      action: ReturnType<typeof AdminDashboardActions.setProgressLoading>
    ) {
      return { ...state, isProgressLoading: action.payload };
    },
  }
);
