import React from "react";
import {Container, styled} from "@mui/material";
import {theme} from "../../../themes/naloxoneRightNow";

const StyledContentWrap = styled("main")`
  padding: 40px 0;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 20px 0;
  }
`;

export default function MainContent({children}) {
  return (
    <Container className="Hello" id="MainContent">
      <StyledContentWrap>{children}</StyledContentWrap>
    </Container>
  );
}
