import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import BasePage from "../common/base";
import AdminPageContainer from "../../components/admin-components/page-container";
import QuotaTable from "./quota-table.component";

const Quotas = () => {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    isFirstRender.current = false;
  }, [dispatch]);

  return (
    <BasePage title={"Quotas"}>
      <AdminPageContainer>
        <QuotaTable />
      </AdminPageContainer>
    </BasePage>
  );
};

export default Quotas;
