import {Button, Stack, TableCell, TableRow, TextField} from "@mui/material";
import React, {useState} from "react";
import SelectState from "./dropdown/select-state";
import {NewQuotaProps} from "../../interfaces/quota";
import {AdminDashboardActions} from "../../redux/actions";
import {useDispatch} from "react-redux";

function NewQuota(props: NewQuotaProps) {
  const [userSelectedState, setUserSelectedState] = useState("");
  const [userSelectedLimit, setUserSelectedLimit] = useState(0);

  const dispatch = useDispatch();

  function handleSave() {
    if (userSelectedState === "" || userSelectedLimit === 0) {
      alert("Please fill in all fields");
      return;
    }
    dispatch(
      AdminDashboardActions.createQuota({
        state: userSelectedState,
        quotaPeriod: "MONTHLY",
        quantity: userSelectedLimit,
      })
    );
    setUserSelectedState("");
    setUserSelectedLimit(0);
    props.onConfirm();
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <SelectState
            userSelectedState={userSelectedState}
            setUserSelectedState={setUserSelectedState}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          MONTHLY
        </TableCell>
        <TableCell align="center">
          <TextField
            type="number"
            variant="outlined"
            style={{ width: "100px" }}
            inputProps={{ min: 0, style: { textAlign: "center" } }}
            value={userSelectedLimit}
            onChange={(e) => setUserSelectedLimit(parseInt(e.target.value))}
          />
        </TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
      <Stack direction={"row"} spacing={1} p={2}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setUserSelectedState("");
            setUserSelectedLimit(0);
            props.onCancel();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </>
  );
}

export default NewQuota;
