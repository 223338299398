import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Alert, Box, IconButton, Tooltip, Typography} from "@mui/material";
import ReactPlayer from "react-player/lazy";
import PlayIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import ReplayIcon from "@mui/icons-material/ReplayOutlined";
import PauseIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import Replay5Icon from "@mui/icons-material/Replay5Outlined";
import {toast} from "react-toastify";
import config from "../../config"

function VideoPlayer({url, onEnded}) {
  const environment = config.environment
  const playerRef = useRef();
  const [playing, setPlaying] = useState();

  if (environment === 'qa') {
    onEnded();
  }

  return (
    <>
      <Box className={"VideoPlayerWrapper"} minWidth={"100%"} maxWidth={"100%"}>
        <ReactPlayer
          ref={playerRef}
          url={url}
          controls={environment !== 'prod'}
          width={"100%"}
          height={"100%"}
          playing={playing}
          onEnded={() => {
            setPlaying(false);
            onEnded && onEnded();
          }}
          onError={(error) => {
            toast.error("Failed to load video");
            console.error(error);
          }}
        />
        <Box display={"flex"} justifyContent={"center"}>
          <Tooltip title={playing ? "Pause" : "Play"}>
            <IconButton onClick={() => setPlaying((prev) => !prev)}>
              {playing ? <PauseIcon fontSize={"large"} /> : <PlayIcon fontSize={"large"} />}
            </IconButton>
          </Tooltip>
          <Tooltip title={"Back 5 seconds"}>
            <IconButton
              onClick={() =>
                playerRef?.current?.seekTo(parseInt(playerRef?.current?.getCurrentTime()) - 5)
              }
            >
              <Replay5Icon fontSize={"large"} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Restart"}>
            <IconButton onClick={() => playerRef?.current?.seekTo(0)}>
              <ReplayIcon fontSize={"large"} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {environment !== 'prod' && (
        <Alert severity="info" variant="filled">
          You don't need to play the video for QA environment, just click on the "Next" button to
          proceed. <br />
          <b>NOTE:</b> This disclaimer is visible only on the QA environment.
        </Alert>
      )}
    </>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  onEnded: PropTypes.func,
};

export default VideoPlayer;
