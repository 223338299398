import { makeStyles } from "@mui/styles";
import { globalColors } from "../../hooks/styles/muiTheme";

const drawerWidth = 230;

export const SideBarStyles: any = makeStyles((theme: any) => ({
  appMenu: {
    width: "100%",
  },
  navList: {
    width: drawerWidth,
  },
  menuItem: {
    width: "auto",
  },
  menuItemActive: {
    width: "auto",
    background:
      theme.palette.type === "dark"
        ? globalColors.FFF_DARK_BLUE
        : globalColors.FFF_BLUE,
  },
  menuItemIcon: {
    color: globalColors.FFF_BLUE,
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: "7px",
    },
  },
  menuItemText: {
    "& .MuiTypography-root": {
      whiteSpace: "normal",
      fontSize: 14,
      fontWeight: "bold",
    },
  },
  link: {
    "text-decoration": "inherit",
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      "text-decoration": "inherit",
      color: "inherit",
    },
  },
  supportLink: {
    position: "fixed",
    bottom: 0,
    width: drawerWidth + 10,
    textDecoration: "none",
    color: "inherit",
  },
}));

export default SideBarStyles;
