import firebase from "../lib/firebase";
import config from "../../config";
import axios from "axios";

export class FirebaseService {
  private static instance: FirebaseService;
  private constructor() {}

  public static getInstance(): FirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
    return FirebaseService.instance;
  }

  public getToken = async (customToken: string): Promise<any> => {
    await firebase.auth().signInWithCustomToken(customToken);
    const token = await firebase.auth().currentUser?.getIdToken();
    const refreshToken = firebase.auth().currentUser?.refreshToken;
    return { token: token, refreshToken: refreshToken };
  };

  public refreshToken = async (refreshToken: string): Promise<any> => {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refreshToken);
    const { data } = await axios.post(
      `https://securetoken.googleapis.com/v1/token?key=${config.firebase.apiKey}`,
      params
    );
    return data.id_token;
  };
}
