import { connect } from 'react-redux';
import { UserManagementActions, UIActions } from '../../redux/actions';
import { UserManagementComponent } from './user-management.component';

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  users: state.userManagement.users ? state.userManagement.users : [],
  totalUsers: state.userManagement.users
    ? state.userManagement.users.total_records
    : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage
    ? state.ui.globalRowsPerPage
    : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (filter: any) => dispatch(UserManagementActions.getUsers(filter)),
  inviteUser: (payload: any) =>
    dispatch(UserManagementActions.inviteUser(payload)),
  resendInvite: (payload: any) =>
    dispatch(UserManagementActions.resendInviteEmail(payload)),
  setPassword: (payload: any) =>
    dispatch(UserManagementActions.setOneTimePassword(payload)),
  updateUser: (payload: any) =>
    dispatch(UserManagementActions.updateUser(payload)),
  deleteUser: (payload: any) =>
    dispatch(UserManagementActions.deleteUser(payload)),
});

export const UserManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementComponent);