import React, {useState} from "react";
import {
  Typography,
  Grid,
  TextField,
  Box,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormWrap from "../../components/assessment-tool-components/layout/FormWrap";
import AssessmentToolBase from "../common/assessment-tool";
import {
  formatPhoneNumber,
  formatZip,
  validateEmail,
  validatePhone,
  validateZip,
} from "../../utils/formator";
import {connect} from "react-redux";
import {UserManagementActions} from "../../redux/actions";
import Loading from "../../components/loading";
import { useHistory } from "react-router-dom";

function RegisterUser(props: any) {
  const history = useHistory();
  const [legalBusinessName, setLegalBusinessName] = useState("");
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [attention, setAttention] = useState("");
  const [county, setCounty] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [orderConfirmationEmails, setOrderConfirmationEmails] = useState("");
  const [shipmentNotificationEmails, setShipmentNoticationEmails] =
    useState("");
  const [partOfGpo, setPartOfGpo] = useState("");
  const [gpoAffiliation, setGpoAffiliation] = useState("");
  const [facilityType, setFacilityType] = useState("");
  const [otherGpoPastYear, setOtherGpoPastYear] = useState("");
  const [currentGpoName, setCurrentGpoName] = useState("");
  const [otherGpoProducts, setOtherGpoProducts] = useState("");
  const [errorFields, setErrorFields] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const clearForm = () => {
    setLegalBusinessName("");
    setName("");
    setStreet("");
    setCity("");
    setState("");
    setZip("");
    setAttention("");
    setCounty("");
    setPhone("");
    setFax("");
    setContactName("");
    setContactPhone("");
    setContactEmail("");
    setOrderConfirmationEmails("");
    setShipmentNoticationEmails("");
    setPartOfGpo("");
    setGpoAffiliation("");
    setFacilityType("");
    setOtherGpoPastYear("");
    setCurrentGpoName("");
    setOtherGpoProducts("");
    setErrorFields({});
  };

  const buttonIsDisabled = () => {
    let errorsArray = [
      errorFields.legalBusinessName === undefined
        ? true
        : errorFields.legalBusinessName,
      errorFields.name === undefined ? true : errorFields.name,
      errorFields.street === undefined ? true : errorFields.street,
      errorFields.city === undefined ? true : errorFields.city,
      errorFields.state === undefined ? true : errorFields.state,
      errorFields.zip === undefined ? true : errorFields.zip,
      errorFields.attention === undefined ? true : errorFields.attention,
      errorFields.county === undefined ? true : errorFields.county,
      errorFields.phone === undefined ? true : errorFields.phone,
      errorFields.contactName === undefined ? true : errorFields.contactName,
      errorFields.contactEmail === undefined ? true : errorFields.contactEmail,
      errorFields.contactPhone === undefined ? true : errorFields.contactPhone,
      errorFields.facilityType === undefined ? true : errorFields.facilityType,
    ];

    if (partOfGpo === "Yes") {
      errorsArray.push(
        errorFields.gpoAffiliation === undefined
          ? true
          : errorFields.gpoAffiliation
      );
    }

    if (otherGpoPastYear && otherGpoPastYear !== "No") {
      errorsArray.push(
        errorFields.currentGpoName === undefined
          ? true
          : errorFields.currentGpoName
      );
      errorsArray.push(
        errorFields.otherGpoProducts === undefined
          ? true
          : errorFields.otherGpoProducts
      );
    }

    return errorsArray.some((errorField) => {
      return errorField === true;
    });
  };

  const submit = () => {
    setIsLoading(true);

    const requestBody: any = {
      "Legal Business Name": legalBusinessName,
      Name: name,
      Street: street,
      City: city,
      State: state,
      Zip: zip,
      Attention: attention,
      County: county,
      Phone: phone,
      Fax: fax,
      "Contact Name": contactName,
      "Contact Phone": contactPhone,
      "Contact Email": contactEmail,
      "Send Order Confirmation Emails?": orderConfirmationEmails || "No",
      "Send Shippment Notification Emails?": shipmentNotificationEmails || "No",
      "Is your business part of a GPO?": partOfGpo || "No",
      "If yes, which affiliation": gpoAffiliation,
      "Facility Type": facilityType,
      "Has your facility been affiliated with another GPO within the past year?":
        otherGpoPastYear || "No",
      "Current GPO Name": currentGpoName,
      "Products your facility currently purchases": otherGpoProducts,
    };

    props.sendRegistrationForm({
      requestBody: requestBody,
      success: () => {
        clearForm();
        setTimeout(() => {
          setIsLoading(false);
          history.push("/");
        }, 2500);
      },
      error: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <AssessmentToolBase>
      <Box>
        <Typography variant={`h2`} gutterBottom>
          GPO Membership Application
        </Typography>
        <Divider sx={{ mt: 0, mb: 4 }} />
        <Box mb={4}>
          <FormWrap>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant={`h6`}>Account Information</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="legal-business-name"
                  label="Legal Business Name"
                  value={legalBusinessName}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({
                      ...errorFields,
                      legalBusinessName: false,
                    });
                    setLegalBusinessName(e.target.value);
                    if (!e.target.value)
                      setErrorFields({
                        ...errorFields,
                        legalBusinessName: true,
                      });
                  }}
                  onBlur={() => {
                    if (!legalBusinessName)
                      setErrorFields({
                        ...errorFields,
                        legalBusinessName: true,
                      });
                  }}
                  error={errorFields.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="name"
                  label="Name"
                  value={name}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, name: false });
                    setName(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, name: true });
                  }}
                  onBlur={() => {
                    if (!name) setErrorFields({ ...errorFields, name: true });
                  }}
                  error={errorFields.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={`h6`}>Delivery Address</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="street"
                  label="Street"
                  value={street}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, street: false });
                    setStreet(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, street: true });
                  }}
                  onBlur={() => {
                    if (!street)
                      setErrorFields({ ...errorFields, street: true });
                  }}
                  error={errorFields.street}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="city"
                  label="City"
                  value={city}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, city: false });
                    setCity(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, city: true });
                  }}
                  onBlur={() => {
                    if (!city) setErrorFields({ ...errorFields, city: true });
                  }}
                  error={errorFields.city}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="state"
                  label="State"
                  value={state}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, state: false });
                    setState(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, state: true });
                  }}
                  onBlur={() => {
                    if (!state) setErrorFields({ ...errorFields, state: true });
                  }}
                  error={errorFields.state}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="zip"
                  label="Zip"
                  value={zip}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, zip: false });
                    const value = formatZip(e.target.value);
                    setZip(value);
                    if (!value) {
                      setErrorFields({ ...errorFields, zip: true });
                    }
                  }}
                  onBlur={() => {
                    if (!zip || !validateZip(zip))
                      setErrorFields({ ...errorFields, zip: true });
                  }}
                  error={errorFields.zip}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="attention"
                  label="Attention"
                  value={attention}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, attention: false });
                    setAttention(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, attention: true });
                  }}
                  onBlur={() => {
                    if (!attention)
                      setErrorFields({ ...errorFields, attention: true });
                  }}
                  error={errorFields.attention}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="county"
                  label="County"
                  value={county}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, county: false });
                    setCounty(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, county: true });
                  }}
                  onBlur={() => {
                    if (!county)
                      setErrorFields({ ...errorFields, county: true });
                  }}
                  error={errorFields.county}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  label="Phone"
                  value={phone}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, phone: false });
                    let value = e.target.value;
                    value = formatPhoneNumber(value);
                    setPhone(value);
                    if (!value) setErrorFields({ ...errorFields, phone: true });
                  }}
                  onBlur={() => {
                    if (!phone || !validatePhone(phone))
                      setErrorFields({ ...errorFields, phone: true });
                  }}
                  error={errorFields.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="fax"
                  label="Fax"
                  value={fax}
                  fullWidth
                  onChange={(e: any) => {
                    let value = e.target.value;
                    value = formatPhoneNumber(value);
                    setFax(value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="contactName"
                  label="Contact Name"
                  value={contactName}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, contactName: false });
                    setContactName(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, contactName: true });
                  }}
                  onBlur={() => {
                    if (!contactName)
                      setErrorFields({ ...errorFields, contactName: true });
                  }}
                  error={errorFields.contactName}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="contactPhone"
                  label="Contact Phone"
                  value={contactPhone}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, contactPhone: false });
                    let value = e.target.value;
                    value = formatPhoneNumber(value);
                    setContactPhone(value);
                    if (!value)
                      setErrorFields({ ...errorFields, contactPhone: true });
                  }}
                  onBlur={() => {
                    if (!contactPhone || !validatePhone(contactPhone))
                      setErrorFields({ ...errorFields, contactPhone: true });
                  }}
                  error={errorFields.contactPhone}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="contactEmail"
                  label="Contact Email"
                  value={contactEmail}
                  required={true}
                  fullWidth
                  onChange={(e: any) => {
                    setErrorFields({ ...errorFields, contactEmail: false });
                    setContactEmail(e.target.value);
                    if (!e.target.value)
                      setErrorFields({ ...errorFields, contactEmail: true });
                  }}
                  onBlur={() => {
                    if (!contactEmail || !validateEmail(contactEmail))
                      setErrorFields({ ...errorFields, contactEmail: true });
                  }}
                  error={errorFields.contactEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value={orderConfirmationEmails === "Yes"}
                  label={
                    <Typography>
                      <b>Send Order Confirmation Emails?</b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      onChange={(e) => {
                        const { checked } = e?.target;
                        setOrderConfirmationEmails(checked ? "Yes" : "No");
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value={shipmentNotificationEmails === "Yes"}
                  label={
                    <Typography>
                      <b>Send Shipment Notification Emails?</b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      onChange={(e) => {
                        const { checked } = e?.target;
                        setShipmentNoticationEmails(checked ? "Yes" : "No");
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value={partOfGpo === "Yes"}
                  label={
                    <Typography>
                      <b>
                        Is Your Business Part of a GPO? (Group Purchasing
                        Organization)
                      </b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      onChange={(e) => {
                        const { checked } = e?.target;
                        setPartOfGpo(checked ? "Yes" : "No");
                        if (!checked) {
                          setErrorFields({
                            ...errorFields,
                            gpoAffiliation: false,
                          });
                        }
                      }}
                    />
                  }
                />
              </Grid>
              {partOfGpo === "Yes" && (
                <>
                  <Grid item xs={3}>
                    <Typography sx={{ paddingTop: "10px" }}>
                      <b>If Yes, which affiliation?</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      id="gpoAffiliation"
                      label="GPO Affiliation"
                      value={gpoAffiliation}
                      required={true}
                      fullWidth
                      onChange={(e: any) => {
                        setErrorFields({
                          ...errorFields,
                          gpoAffiliation: false,
                        });
                        setGpoAffiliation(e.target.value);
                        if (!e.target.value)
                          setErrorFields({
                            ...errorFields,
                            gpoAffiliation: true,
                          });
                      }}
                      onBlur={() => {
                        if (!gpoAffiliation)
                          setErrorFields({
                            ...errorFields,
                            gpoAffiliation: true,
                          });
                      }}
                      error={errorFields.gpoAffiliation}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel
                    error={errorFields.facilityType}
                    sx={{
                      color: "#707070",
                      opacity: "0.5",
                      fontSize: "20px",
                      lineHeight: "1.8em",
                    }}
                    id="facilityTypeSelector"
                  >
                    Facility Type*
                  </InputLabel>
                  <Select
                    labelId={"facilityTypeSelector"}
                    id="selectFacilityType"
                    label="Facility Type"
                    onChange={(e: any) => {
                      setErrorFields({ ...errorFields, facilityType: false });
                      let value = e.target.value;
                      setFacilityType(value);
                      if (!value)
                        setErrorFields({ ...errorFields, facilityType: true });
                    }}
                    value={facilityType}
                    required
                    error={errorFields.facilityType}
                    onBlur={() => {
                      if (!facilityType)
                        setErrorFields({ ...errorFields, facilityType: true });
                    }}
                  >
                    <MenuItem value="">
                      <em>Select One</em>
                    </MenuItem>
                    <MenuItem value={"Non Profit Organization"}>
                      Non Profit Organization
                    </MenuItem>
                    <MenuItem value={"School/University"}>
                      School/University
                    </MenuItem>
                    <MenuItem value={"First Responder"}>
                      First Responder
                    </MenuItem>
                    <MenuItem value={"State or local government agency"}>
                      State or local government agency
                    </MenuItem>
                    <MenuItem value={"Addiction/Recovery Center"}>
                      Addiction/Recovery Center
                    </MenuItem>
                    <MenuItem value={"Healthcare Facility"}>
                      Healthcare Facility
                    </MenuItem>
                    <MenuItem value={"Community Based Organization"}>
                      Community Based Organization
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    Within the past year, has this facility been affiliated with
                    a pharmaceutical group purchasing organization (GPO) other
                    than HarborPath?
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value={otherGpoPastYear === "No"}
                  label={
                    <Typography>
                      <b>No</b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={otherGpoPastYear === "No"}
                      onChange={(e) => {
                        const { checked } = e?.target;
                        setOtherGpoPastYear(checked ? "No" : "");
                        if (checked) {
                          setCurrentGpoName("");
                          setOtherGpoProducts("");
                          setErrorFields({
                            ...errorFields,
                            currentGpoName: false,
                            otherGpoProducts: false,
                          });
                        }
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value={
                    otherGpoPastYear ===
                    "Yes, but the facility is switching to HarborPath.  Attached is a signed letter on the facility’s letterhead stating that it wishes to discontinue association with its current pharmaceutical GPO and use HarborPath instead."
                  }
                  label={
                    <Typography>
                      <b>
                        Yes, but the facility is switching to HarborPath.
                        Attached is a signed letter on the facility’s letterhead
                        stating that it wishes to discontinue association with
                        its current pharmaceutical GPO and use HarborPath
                        instead.
                      </b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={
                        otherGpoPastYear ===
                        "Yes, but the facility is switching to HarborPath.  Attached is a signed letter on the facility’s letterhead stating that it wishes to discontinue association with its current pharmaceutical GPO and use HarborPath instead."
                      }
                      onChange={(e) => {
                        const { checked } = e?.target;
                        setOtherGpoPastYear(
                          checked
                            ? "Yes, but the facility is switching to HarborPath.  Attached is a signed letter on the facility’s letterhead stating that it wishes to discontinue association with its current pharmaceutical GPO and use HarborPath instead."
                            : ""
                        );
                        if (!checked) {
                          setCurrentGpoName("");
                          setOtherGpoProducts("");
                          setErrorFields({
                            ...errorFields,
                            currentGpoName: false,
                            otherGpoProducts: false,
                          });
                        }
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value={
                    otherGpoPastYear ===
                    "Yes, and the facility will remain with current GPO."
                  }
                  label={
                    <Typography>
                      <b>Yes, and the facility will remain with current GPO.</b>
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={
                        otherGpoPastYear ===
                        "Yes, and the facility will remain with current GPO."
                      }
                      onChange={(e) => {
                        const { checked } = e?.target;
                        setOtherGpoPastYear(
                          checked
                            ? "Yes, and the facility will remain with current GPO."
                            : ""
                        );
                        if (!checked) {
                          setCurrentGpoName("");
                          setOtherGpoProducts("");
                          setErrorFields({
                            ...errorFields,
                            currentGpoName: false,
                            otherGpoProducts: false,
                          });
                        }
                      }}
                    />
                  }
                />
              </Grid>
              {otherGpoPastYear && otherGpoPastYear !== "No" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="currentGpoName"
                      label="Current GPO Name"
                      value={currentGpoName}
                      required={true}
                      fullWidth
                      onChange={(e: any) => {
                        setErrorFields({
                          ...errorFields,
                          currentGpoName: false,
                        });
                        setCurrentGpoName(e.target.value);
                        if (!e.target.value)
                          setErrorFields({
                            ...errorFields,
                            currentGpoName: true,
                          });
                      }}
                      onBlur={() => {
                        if (!currentGpoName)
                          setErrorFields({
                            ...errorFields,
                            currentGpoName: true,
                          });
                      }}
                      error={errorFields.currentGpoName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="otherGpoProducts"
                      label="Products your facility currrently purchases"
                      value={otherGpoProducts}
                      required={true}
                      fullWidth
                      onChange={(e: any) => {
                        setErrorFields({
                          ...errorFields,
                          otherGpoProducts: false,
                        });
                        setOtherGpoProducts(e.target.value);
                        if (!e.target.value)
                          setErrorFields({
                            ...errorFields,
                            otherGpoProducts: true,
                          });
                      }}
                      onBlur={() => {
                        if (!otherGpoProducts)
                          setErrorFields({
                            ...errorFields,
                            otherGpoProducts: true,
                          });
                      }}
                      error={errorFields.otherGpoProducts}
                    />
                  </Grid>
                </>
              )}
              {isLoading ? (
                <Loading message="" />
              ) : (
                <Grid item xs={12}>
                  <Button
                    disabled={buttonIsDisabled()}
                    color={"secondary"}
                    variant={"contained"}
                    onClick={submit}
                    disableRipple
                  >
                    Register
                  </Button>
                </Grid>
              )}
            </Grid>
          </FormWrap>
        </Box>
      </Box>
    </AssessmentToolBase>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  sendRegistrationForm: (payload: any) =>
    dispatch(UserManagementActions.sendRegistrationForm(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
