import React from "react";
import PropTypes from "prop-types";
import {Box, styled, Typography} from "@mui/material";
import {paddings} from "../../../themes/naloxoneRightNow";

const Wrapper = styled((props) => <Box {...props} />)(({theme, checked, answerstatus}) => ({
  ".MuiRadio-root": {
    display: "none",
  },
  cursor: "pointer",
  padding: `10px ${paddings.inputIndent}px 10px ${paddings.inputIndent}px`,
  fontSize: "20px",
  border:
    (checked && answerstatus !== "Incorrect !") === true
      ? `2px solid #133257`
      : (checked && answerstatus === "Incorrect !") === true
      ? `2px solid ${theme.palette.error.main}`
      : `2px solid #E3E3E3`,
  background:
    (checked && answerstatus !== "Incorrect !") === true
      ? `rgba(19, 50, 87, 0.04)`
      : (checked && answerstatus === "Incorrect !") === true
      ? theme.palette.error.main
      : "transparent",
  borderRadius: 5,
  minWidth: 350,
  // height: 64,
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  ":hover": {
    background: `rgba(19, 50, 87, 0.04)`,
  },
}));

function Clickable({checked, disabled, answerstatus, label, onClick}) {
  return (
    <Wrapper checked={checked} answerstatus={answerstatus} disabled={disabled} onClick={onClick}>
      <Typography fontSize={20} fontWeight={500}>
        {label}
      </Typography>
    </Wrapper>
  );
}

Clickable.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  answerstatus: PropTypes.string,
};

export default Clickable;
