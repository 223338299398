import React from "react";
import {Container, styled} from "@mui/material";

const StyledPageContainer = styled("main")(({theme}) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

export default function AdminPageContainer({children}: any) {
  return (
    <Container id="MainContent" maxWidth={false}>
      <StyledPageContainer>{children}</StyledPageContainer>
    </Container>
  );
}
