import { makeStyles } from "@mui/styles";
import { globalColors } from "../../hooks/styles/muiTheme";

export const CommonStyles: any = (theme: any) => ({
  root: {
    "& > .fa": {
      // margin: theme.spacing(2),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  pageWrap: {
    marginBottom: 28,
    padding: 9,
  },
  paper: {
    // padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControlSmall: {
    // margin: theme.spacing(1),
    marginLeft: 0,
    width: "12.5%",
  },
  formControl: {
    // margin: theme.spacing(1),
    marginLeft: 0,
    width: "24%",
  },
  formControlLarge: {
    // margin: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
  },
  formControlMedium: {
    // margin: theme.spacing(1),
    marginLeft: 0,
    width: "50%",
  },
  floatRight: {
    float: "right",
  },
  searchWrap: {
    height: 52,
    padding: 10,
    display: "inline-block",
    width: "100%",
  },
  pageActionsWrap: {
    height: 68,
    padding: 10,
  },
  searchBox: {
    padding: 9,
    border: "1 solid #f2f2f2",
    marginBottom: "10px!important",
    background: "#fff",
  },
  buttonFormControl: {
    marginBottom: "12px!important",
    marginLeft: 0,
    marginRight: "16px!important",
    minWidth: "60px!important",
    verticalAlign: "bottom",
  },
  searchButton: {
    margin: "12px 0 0 10px",
    fontSize: "10px!important",
  },
  tableButton: {
    margin: "10px",
    backgroundColor: `${globalColors.NAX_GREEN} !important`,
    fontSize: "10px!important",
  },
  tableTimePicker: {
    margin: "0",
    width: "150px",
  },
  tableFormControl: {
    margin: "0 0 0 10px",
  },
  viewAllBtn: {
    backgroundColor: `${globalColors.NAX_GREEN} !important`,
    fontSize: "10px",
  },
  addMoreButton: {
    color: `${globalColors.NAX_GREEN} !important`,
    "&:hover": {
      background: "none!important",
    },
    width: "40px!important",
    fontSize: "10px!important",
    marginTop: "10px!important",
  },
  cancelBtn: {
    margin: "0 5px 0 15px",
    padding: "6px 5px",
    color: "white",
    fontSize: "10px!important",
    backgroundColor: "rgba(0, 0, 0, 0.26) !important",
  },
  groupByWrapper: {
    position: "absolute",
    top: -5,
    left: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 10,
    [theme.breakpoints.down("lg")]: {
      top: -50,
      left: 0,
      right: "unset",
    },
  },
  groupByWrapperSmall: {
    position: "absolute",
    top: 55,
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 10,
  },
  groupByHeading: {
    marginRight: "6px",
  },
  groupByLinks: {
    margin: "0 20px 0 0",
  },
  groupByButtons: {
    // color: 'rgba(0, 0, 0, 0.87)'
  },
  expansionHeading: {
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
    fontSize: 14,
  },
  expansionSecondary: {
    fontSize: 14,
  },
  rightAlignFormControl: {
    // margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: "0px!important",
  },
  defaultButton: {
    backgroundColor: "#558db1 !important",
  },
  chipStyle: {
    marginRight: 5,
  },
  centerButton: {
    margin: "0 auto",
  },
  topBackButton: {
    textAlign: "right",
    marginBottom: 20,
  },
  errorMsg: {
    color: "#f44336",
    fontWeight: "bold",
    textAlign: "center",
  },
  closeBtn: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  confirmBox: {
    overflow: "hidden",
  },
  confirmMsg: {
    fontWeight: "bold",
    marginTop: 0,
    padding: "10px 10px 0 10px",
  },
  confrimControlWrap: {
    height: 30,
  },
  confrimControlBtn: {
    display: "inline-block",
    width: "50%",
    height: "100%",
    color: "black",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `#e6e6e6 !important`,
    },
  },
  expectedDeliveryWrap: {
    outline: "2px solid green",
    outlineOffset: 4,
  },
  expectedDeliveryText: {
    position: "absolute",
    top: -14,
    left: 5,
    padding: "0 2px",
    color: "green",
    fontSize: "10px",
    backgroundColor: "white",
    whiteSpace: "nowrap",
  },
  popperForm: {
    padding: 20,
  },
});
export default CommonStyles;
