import React from "react";
import { connect } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { StyledTextField } from "../../hooks/styles/formStyles";

function AutoComplete(props: any) {
  const renderDefaultOptionLabel = (option: any) =>
    `${option.label} (${option.value})`;
  return (
    <Autocomplete
      multiple={props.multiple}
      disablePortal
      id={`${props.label}-autocomplete`}
      options={props.options || []}
      getOptionLabel={props.renderOptionLabel || renderDefaultOptionLabel}
      value={props.value || null}
      sx={{ minWidth: "300px" }}
      onChange={props.onChange}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={props.label}
          error={props.error}
          helperText={props.error ? <>{props.errorText}</> : <></>}
        />
      )}
    />
  );
}

export default connect()(AutoComplete);
