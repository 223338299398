import React from "react";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Grid,
  Popper,
  TextField,
} from "@mui/material";
// import { DesktopDateRangePopup } from "../dateRangePopup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "../auto-complete";
import { CommonStyles } from "../../hooks/styles";
import { StyledTextField } from "../../hooks/styles/formStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SearchFilterProps {
  config: any[];
  filters: any;
  onFilterChange: (value: any) => void;
  handleSearch: () => void;
  resetFilters: () => void;
}

const filterIcons: any = {
  text: <TextFieldsIcon />,
  dateRange: <DateRangeIcon />,
  date: <DateRangeIcon />,
  select: <ExpandMoreIcon fontSize="medium" />,
  autoComplete: <ExpandMoreIcon />,
};

const filterInputs: any = {
  text: (inputProps: any) => (
    <StyledTextField
      autoFocus
      variant="outlined"
      placeholder={inputProps.label}
      onChange={(e: any) => inputProps.setValue(e.target.value)}
    />
  ),
  number: (inputProps: any) => (
    <StyledTextField
      autoFocus
      variant="outlined"
      placeholder={inputProps.label}
      type="number"
      onChange={(e: any) => {
        let value = e.target.value;
        if (inputProps.nonNegative && value <= 0) {
          value = 0;
        }
        e.target.value = value;
        inputProps.setValue(value);
      }}
    />
  ),
  autoComplete: (inputProps: any) => (
    <Autocomplete
      onChange={(e: any, newValue: any) => {
        let value = newValue;
        if (newValue.value) {
          value = newValue.value;
        }
        inputProps.setValue(value || null);
        inputProps.closePopper();
      }}
      style={{ width: 400 }}
      id="combo-box-demo"
      options={inputProps.selectOptions || []}
      label={inputProps.label}
      renderOptionLabel={inputProps.renderOptionLabel || undefined}
    />
  ),
  multiAutoComplete: (inputProps: any) => (
    <Autocomplete
      multiple
      disableCloseOnSelect
      onChange={(e: any, newValue: any) => {
        inputProps.setValue(newValue || null);
      }}
      style={{ minWidth: 400 }}
      id="combo-box-demo"
      options={inputProps.selectOptions || []}
      getOptionLabel={(option: any) =>
        `${option.name || option.description || option.id}(${option.id})`
      }
      // defaultValue={inputProps.selectOptions.filter((option: any) => inputProps.defaultValue.includes(option.id))}
      renderOption={(option: any, { selected }: any) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.name || option.description || option.id}(${option.id})`}
        </React.Fragment>
      )}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          placeholder={inputProps.label}
          autoFocus
          variant="outlined"
        />
      )}
    />
  ),
  date: (inputProps: any) => (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={""}
        value={inputProps.filters[inputProps.name] || ""}
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        onChange={(date) => {
          inputProps.setValue(date ? date.format("YYYY-MM-DD") : null);
        }}
        renderInput={(params: any) => (
          <StyledTextField {...params} autoFocus variant="outlined" />
        )}
      />
    </LocalizationProvider>
  ),
};

const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const [config] = React.useState<any[]>(props.config);
  const [filters, setFilters] = React.useState<any>(props.filters);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperAnchor, setPopperAnchor] = React.useState<any>(null);
  const [currentFilter, setCurrentFilter] = React.useState<any>(null);

  React.useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);

  const resetFilters = () => {
    setFilters({});
    props.resetFilters();
  };

  const renderFilterChip = (config: any) => {
    const icon = filterIcons["select"]; // Possibly use different icons for different filter types: filterIcons[config.type]
    let label = "";
    if (Array.isArray(filters[config.name])) {
      label = `${config.label}: ${filters[config.name].map(
        (filter: any) => filter.id
      )}`;
    } else {
      label = `${config.label}${
        filters[config.name] ? `: ${filters[config.name]}` : ``
      }`;
    }
    return (
      <Grid key={config.name} item>
        <Chip
          size="medium"
          style={{ padding: "15px", fontSize: "15px" }}
          icon={filters[config.name] ? null : icon}
          label={label}
          onClick={(e: any) => {
            setPopperOpen(true);
            setPopperAnchor(e.currentTarget);
            setCurrentFilter(config);
          }}
          onDelete={
            filters[config.name]
              ? () => {
                  props.onFilterChange({
                    ...filters,
                    [config.name]: undefined,
                  });
                }
              : undefined
          }
        />
      </Grid>
    );
  };

  const renderFilterInput = () => {
    const inputProps = {
      ...currentFilter,
      filters: filters,
      setValue: (value: any) => {
        props.onFilterChange({ ...filters, [currentFilter.name]: value });
      },
      selectOptions: currentFilter.selectOptions || null,
      defaultValue: currentFilter.defaultValue ?? "",
      closePopper: () => setPopperOpen(false),
    };
    return filterInputs[currentFilter.type](inputProps);
  };

  return (
    <>
      {popperOpen && (
        <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
          <Popper
            open={popperOpen}
            anchorEl={popperAnchor}
            placement="bottom-start"
          >
            {renderFilterInput()}
          </Popper>
        </ClickAwayListener>
      )}
      <Grid
        container
        spacing={2}
        style={{ width: "fit-content" }}
        justifyContent={"flex-start"}
        alignItems="center"
      >
        {config.map((filterConfig: any) => renderFilterChip(filterConfig))}
        <Grid item>
          <div
            style={{
              border: "1px #000 solid",
              padding: "5px 20px",
              borderRadius: 25,
              cursor: "pointer",
            }}
            onClick={resetFilters}
          >
            Reset
          </div>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.handleSearch();
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchFilter;
