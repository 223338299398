import React from "react";
import PropTypes from "prop-types";
import {FormControl, Grid, Stack, useTheme} from "@mui/material";
import FormActions from "./FormActions";
import FormFieldsWrap from "./FormFieldsWrap";
import FormWrap from "./FormWrap";

/**
 * A UI container component to render the forms. The DRY component displays the actual form and a actions area (e.g NEXT button) at bottom.
 * @param formElements the form elements
 * @param actions the action buttons
 * @returns {JSX.Element}
 * @constructor
 */
function FormFieldsContainer({formElements, actions}) {
  const theme = useTheme();
  return (
    <FormWrap className={"FormFieldsContainer"}>
      <FormFieldsWrap>
        <Grid container rowSpacing={4}>
          {formElements}
        </Grid>
      </FormFieldsWrap>
      {actions && (
        <FormActions>
          <FormControl fullWidth>
            <Stack direction={`row`} justifyContent={`right`}>
              {actions}
            </Stack>
          </FormControl>
        </FormActions>
      )}
    </FormWrap>
  );
}

FormFieldsContainer.propTypes = {
  /**
   * Wrap each element in <Grid item xs={12} sm={8}>
   */
  formElements: PropTypes.element.isRequired,
  actions: PropTypes.element,
};

export default FormFieldsContainer;
