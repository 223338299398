import React from "react";
import PropTypes from "prop-types";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack} from "@mui/material";

function ConfirmationDialog(props) {
  const {
    isOpen,
    onCancel,
    message,
    onConfirm,
    confirmIsNegative,
    confirmButtonText,
    processingRequest,
  } = props;

  return (
    <Dialog open={isOpen}>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <Button variant={"outlined"} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={confirmIsNegative ? "error" : "primary"}
            disabled={processingRequest}
            onClick={onConfirm}
            endIcon={processingRequest ? <CircularProgress color="inherit" size={25} /> : null}
          >
            {confirmButtonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  /**
   * Button color will be red
   */
  confirmIsNegative: PropTypes.bool,
  confirmButtonText: PropTypes.string.isRequired,
  processingRequest: PropTypes.bool,
};

export default ConfirmationDialog;
