import React from "react";
import {connect} from "react-redux";
import logo from "../../../assets/images/logo.svg";
import clsx from "clsx";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SideMenuBar from "./sideBar";
import MenuListComposition from "./menuList";
import {theme} from "../../../themes/naloxoneRightNow";
import {BasePageStyles, CommonStyles, StyledModal, getMuiTheme} from "../../../hooks/styles";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CssBaseline,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  List,
  Modal,
  Stack,
  Toolbar,
  Divider,
  styled,
} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import {UIActions, UserManagementActions} from "../../../redux/actions";
import {useHistory, Link} from "react-router-dom";
import {ThemeProvider} from "@emotion/react";
import {hasAccess} from "../../../utils/accessLevelHelper";
import {ToastContainer} from "react-toastify";

const drawerWidth = 300;

const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})<{
  open?: boolean;
}>(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({theme, open}) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const HeaderTitle = styled("h4")(({theme}) => ({
  paddingLeft: theme.spacing(5),
}));

const BasePage = (props: any) => {
  const history = useHistory();

  if (!hasAccess(window.location.pathname)) history.push("/404");

  const classes = BasePageStyles(getMuiTheme("light"));
  const commonClasses = CommonStyles(getMuiTheme("light"));
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [urlIsProcessed, setUrlIsProcessed] = React.useState(false);
  const [sessionRefreshError, setSessionRefreshError] = React.useState(false);
  const [secondsUntilLogout, setSecondsUntilLogout] = React.useState<number>(30);
  const [eventListenerActive, setEventListenerActive] = React.useState(false);
  const [timer, setTimer] = React.useState<any>(null);

  React.useEffect(() => {
    if (!urlIsProcessed) {
      props.setUrlFilter(window.location.search);
      setUrlIsProcessed(true);
    }
  }, [urlIsProcessed]);

  const intervalPoller = () => {
    const intervalPoller = setInterval(() => {
      props.pingUserService();
    }, 60000);
    return intervalPoller;
  };

  React.useEffect(() => {
    if (props.authUser && !props.dataIsPreloaded) {
      props.preloadData({poller: intervalPoller});
    }
  }, [props.authUser]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const clearError = () => {};

  const logoutTimer = () => {
    let seconds: number = 30;
    const interval = setInterval(() => {
      if (seconds === 0) {
        clearInterval(interval);
        handleLogout();
        return;
      }
      seconds = seconds - 1;
      setSecondsUntilLogout(seconds);
    }, 1000);
    return interval;
  };

  const onSessionExpired = () => {
    setTimer(logoutTimer());
  };

  const onSessionRefreshError = () => {
    setSessionRefreshError(true);
    setTimeout(() => {
      handleLogout();
    }, 3000);
  };

  const handleLogout = () => {
    props.logout(history);
  };

  const handleBackgroundRefreshSession = React.useCallback(() => {
    props.refreshSessionInBackground({});
    setEventListenerActive(true);
    if (!props.authUser) {
      document.body.removeEventListener("mousemove", handleBackgroundRefreshSession, true);
    }
  }, []);

  React.useEffect(() => {
    !eventListenerActive &&
      !props.sessionExpired &&
      document.body.addEventListener("mousemove", handleBackgroundRefreshSession, true);
  });

  React.useEffect(() => {
    if (props.sessionExpired) {
      document.body.removeEventListener("mousemove", handleBackgroundRefreshSession, true);
      setEventListenerActive(false);
      onSessionExpired();
    }
  }, [props.sessionExpired]);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position={"top-center"}
        hideProgressBar={false}
        closeOnClick={true}
        autoClose={12000}
      />
      <div className={clsx(classes.root, props.isLoading ? "show-loader" : "")}>
        <CssBaseline />
        <AppBar position="fixed" open={isDrawerOpen} sx={{height: 64}}>
          <Toolbar disableGutters>
            <Stack direction={`row`} justifyContent={`space-between`} sx={{flex: 1}}>
              <Box display="flex" alignItems="center">
                {!isDrawerOpen && (
                  <>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} ml={1}>
                      <img
                        src={logo}
                        className="logo-image"
                        alt={`Naloxone Right Now logo`}
                        width="180px"
                      />
                    </Box>
                    <IconButton
                      size={`large`}
                      aria-label={`Open mobile menu`}
                      aria-controls={`menu-appbar`}
                      aria-haspopup={true}
                      onClick={handleDrawerOpen}
                      color={`inherit`}
                      disableRipple
                      sx={{borderRadius: 0, ml: 4}}
                    >
                      <MenuIcon fontSize={"large"} />
                    </IconButton>
                  </>
                )}
                <HeaderTitle>{props.title}</HeaderTitle>
              </Box>
              <Box
                sx={{
                  display: {xs: `none`, lg: `flex`},
                  fontWeight: 600,
                  alignItems: `center`,
                }}
              >
                <MenuListComposition />
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <DrawerHeader>
            <Box component={Link} to={"/"} display={"flex"} alignItems={"center"}>
              <img
                src={logo}
                className="logo-image"
                alt={`Naloxone Right Now logo`}
                width="180px"
              />
            </Box>
            <IconButton size={`large`} onClick={handleDrawerClose} sx={{ml: 2}}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={{padding: 0}}>
            <SideMenuBar open={isDrawerOpen} setOpen={setIsDrawerOpen} />
          </List>
        </Drawer>
        <Main open={isDrawerOpen}>
          <Toolbar disableGutters />
          {props.children}
          {props.errorMessage && (
            <div onClick={clearError} className={classes.errorAlert}>
              <Alert severity={props.errorMessage.severity}>
                <AlertTitle>{props.errorMessage.title}</AlertTitle>
                {props.errorMessage.errorMessage}
              </Alert>
            </div>
          )}
        </Main>

        <Modal
          open={props.sessionExpired || false}
          onClose={(e: any, reason: string) => {
            if (reason === "escapeKeyDown" || reason === "backdropClick") return;
            props.setSessionExpired(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <StyledModal>
            {!sessionRefreshError && (
              <p>Your session has expired. Logging out in {secondsUntilLogout} seconds.</p>
            )}
            {sessionRefreshError && (
              <p className={commonClasses.errorMsg}>
                Sorry, your session could not be refreshed. Logging out now.
              </p>
            )}
            {!sessionRefreshError && (
              <Grid container spacing={0}>
                <Grid item xs>
                  <FormControl>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        props.refreshSession({
                          onError: onSessionRefreshError,
                        });
                        clearInterval(timer);
                      }}
                    >
                      Stay Logged In
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Sign Out
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </StyledModal>
        </Modal>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => ({
  activeMenu: null, //state.ui.menuState,
  isLoading: false, //state.ui.isLoading,
  errorMessage: null, //state.ui.errorMessage,
  navDrawerOpen: true, //state.ui.navDrawerOpen,
  selectedTheme: "light", //state.user.selectedTheme
  sessionExpired: state.ui.sessionExpired,
  authUser: state.userManagement.authUser,
  dataIsPreloaded: state.ui.dataIsPreloaded,
});

const mapDispatchToProps = (dispatch: any) => ({
  setNavDrawer: (open: boolean) => {},
  setUrlFilter: (queryString: string) => {},
  setSessionExpired: (flag: boolean) => dispatch(UIActions.setSessionExpired(flag)),
  refreshSession: (payload: any) => dispatch(UserManagementActions.refreshSession(payload)),
  refreshSessionInBackground: () => dispatch(UserManagementActions.refreshSessionInBackground()),
  clearAuthUser: () => dispatch(UserManagementActions.setAuthUser(null)),
  preloadData: (payload: any) => dispatch(UIActions.preLoadData(payload)),
  logout: (history: any) => dispatch(UserManagementActions.logout(history)),
  pingUserService: () => dispatch(UserManagementActions.pingUserService()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);
