import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import BasePage from "../common/base";
import {UserManagementContainer} from "./user-management.container";
import AdminPageContainer from "../../components/admin-components/page-container";

const UserManagement = () => {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    isFirstRender.current = false;
  }, [dispatch]);

  return (
    <BasePage title={"User Management"}>
      <AdminPageContainer>
        <UserManagementContainer />
      </AdminPageContainer>
    </BasePage>
  );
};

export default UserManagement;
