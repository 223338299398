import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import BasePage from "../common/base";
import AdminPageContainer from "../../components/admin-components/page-container";
import { AdminDashboardActions } from "../../redux/actions";
import ActionsMenu from "../../components/actions-menu";
import MUIDataTable from "mui-datatables";
import dataList from "../../constants/dataList";
import Loading from "../../components/loading";
import SearchFilter from "../../components/searchFilter";
import { US_STATES } from "../../constants/Constants";

const ConsumerOrders = (props: any) => {
  const usStates: any = US_STATES;
  const firstRender = React.useRef(false);
  const [modalOpen, setModalOpen] = React.useState<string>("");
  const [deleteId, setDeleteId] = React.useState(null as any);
  const [orderToEdit, setOrderToEdit] = React.useState(null as any);
  const [filters, setFilters] = React.useState<any>({});
  const [sortOrder, setSortOrder] = React.useState(null as any);
  const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
  const [pagination, setPageState] = React.useState({
    offset: 0,
    limit: props.globalRowsPerPage,
    page: 0,
  });

  const renderActionsMenu: any = (tableMeta: any) => {
    const actionItems = [
      {
        onClick: () => {
          setOrderToEdit(props.orders[tableMeta.rowIndex]);
          setModalOpen("EDIT");
        },
        id: "edit-order",
        title: "Edit",
      },
      // {
      //   onClick: () => {
      //     const thisUser = props.users[tableMeta.rowIndex];
      //     setDeleteId(thisUser.id);
      //     setDeleteEmail(thisUser.email);
      //     setModalOpen("DELETE");
      //   },
      //   id: "delete-user",
      //   title: "Delete",
      // }
    ];
    return <ActionsMenu actionItems={actionItems} />;
  };

  const columnData = [
    {
      label: "Product",
      name: "product",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "State",
      name: "state",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Order Date",
      name: "orderDate",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Quantity",
      name: "quantity",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Refill Reason",
      name: "reason",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Tracking Number",
      name: "trackingNumber",
      change: true,
      viewColumns: true,
      display: true,
    },
  ];

  const formatData = (data: any[]) => {
    return data.map((row: any) => {
      return {
        product: row.products?.name,
        state: row.state,
        orderDate: row.orderDate
          ? row.orderDate.replace("T", " ").replace("Z", "UTC")
          : "",
        quantity: row.quantity,
        reason: row.reason,
        trackingNumber: row.trackingNumber,
      };
    });
  };

  const getTableColumns = () => {
    return columnData.map((column: any, index: number) => {
      return {
        label: column.label,
        name: column.name,
        options: {
          filter: true,
          display: tableStatePersist
            ? tableStatePersist.columns[index].display
            : column.display,
          change: column.change,
          sort: column.sort ?? true,
          viewColumns: column.viewColumns,
          ...(column.customBodyRender && {
            customBodyRender: column.customBodyRender,
          }),
        },
      };
    });
  };

  React.useEffect(() => {
    if (Object.keys(filters).length === 0) filterOrders();
  }, [filters]);

  const getFilter = (reset?: boolean) => {
    // let payloadFilter: any[] = [
    //   // {key: 'limit', value: props.globalRowsPerPage},
    //   // {key: 'limit', value: props.globalRowsPerPage},
    //   // order: sortOrder ? [sortOrder] : ["orderDate DESC"],
    // ];

    // const searchFilters: any[] = Object.keys(filters)
    //   .filter((filterKey: string) => filters[filterKey])
    //   .map((filterKey: string) => {
    //     return { key: filterKey, value: filters[filterKey] };
    //   });

    // payloadFilter = payloadFilter.concat(payloadFilter, searchFilters);
    // return payloadFilter;

    let payloadFilter: any = {
      limit: props.globalRowsPerPage,
      offset: pagination.offset,
      order: sortOrder ? [sortOrder] : ["orderDate DESC"],
      where: { and: [] }, //payloadFilters ultimately end up here
    };

    Object.keys(filters).forEach((filterKey: string) => {
      let filterVal: any = filters[filterKey]
        ? [{ [filterKey]: filters[filterKey] }]
        : null;

      if (filterVal) {
        if (filterKey === "ndc") {
          const product = props.products.find(
            (product: any) => product.name === filters[filterKey]
          );
          filterVal = [
            {
              [filterKey]: product.ndc,
            },
          ];
        }
        if (filterKey === "orderDate") {
          let date = new Date(filters[filterKey]);
          const start = date.toISOString().split("T")[0];
          date.setDate(date.getDate() + 1);
          const end = date.toISOString().split("T")[0];
          filterVal = [
            { [filterKey]: { gt: start } },
            { [filterKey]: { lt: end } },
          ];
        }
        payloadFilter.where.and = payloadFilter.where.and.concat(filterVal);
      }
    });

    return JSON.stringify(payloadFilter);
  };

  const filterOrders = (reset?: boolean) => {
    props.getOrders(getFilter(reset));
  };

  const showLoadingMsg = () => !props.authUser && props.orders.length === 0;

  return (
    <BasePage title={"Consumer Orders"}>
      <AdminPageContainer>
        <div>
          {showLoadingMsg() ? (
            <div>
              <Loading message="" />
            </div>
          ) : (
            <div>
              {/* <div className={classes.searchWrap}>

			</div> */}
              {props.products.length > 0 && (
                <SearchFilter
                  config={[
                    {
                      label: "Product",
                      name: "ndc",
                      type: "autoComplete",
                      selectOptions: props.products.map((product: any) => ({
                        label: product.name,
                        value: product.name,
                      })),
                      renderOptionLabel: (option: any) => {
                        return option.value;
                      },
                    },
                    {
                      label: "Order Date",
                      name: "orderDate",
                      type: "date",
                    },
                    {
                      label: "State",
                      name: "state",
                      type: "autoComplete",
                      selectOptions: Object.keys(usStates).map(
                        (stateCode: string) => ({
                          label: usStates[stateCode],
                          value: stateCode,
                        })
                      ),
                    },
                    {
                      label: "Quantity",
                      name: "quantity",
                      type: "number",
                    },
                    {
                      label: "Refill Reason",
                      name: "reason",
                      type: "autoComplete",
                      selectOptions: [
                        {
                          label: "New Request",
                          value: "New Request",
                        },
                        {
                          label: "Expired",
                          value: "Expired",
                        },
                        {
                          label: "Used",
                          value: "Used",
                        },
                        {
                          label: "Lost",
                          value: "Lost",
                        },
                        {
                          label: "Damaged",
                          value: "Damaged",
                        },
                      ],
                      renderOptionLabel: (option: any) => {
                        return option.value;
                      },
                    },
                    {
                      label: "Tracking Id",
                      name: "trackingNumber",
                      type: "text",
                    },
                  ]}
                  onFilterChange={setFilters}
                  filters={filters}
                  handleSearch={() => filterOrders()}
                  resetFilters={() => setFilters({})}
                />
              )}

              <div style={{ marginTop: 20 }}>
                <MUIDataTable
                  data={formatData(props.orders)}
                  columns={getTableColumns()}
                  options={{
                    rowsPerPage: props.globalRowsPerPage,
                    rowsPerPageOptions: dataList.PageLimitOptions,
                    onChangeRowsPerPage: (numberOfRows: number) => {
                      setPageState({ ...pagination, limit: numberOfRows });
                    },
                    filterType: "dropdown",
                    responsive: "simple",
                    filter: false,
                    search: false,
                    download: true,
                    print: true,
                    selectableRows: "none",
                    serverSide: false,
                    page: pagination.page,
                    count: props.totalUsers,
                    onColumnSortChange: (
                      changedColumn: string,
                      direction: string
                    ) => {
                      if (changedColumn === "product") {
                        return false;
                      }
                    },
                    onTableChange: (tableAction: any, tableState: any) => {
                      switch (tableAction) {
                        case "changePage":
                          if (tableState.page > pagination.page) {
                            setPageState({
                              offset:
                                pagination.offset + props.globalRowsPerPage,
                              limit: props.globalRowsPerPage,
                              page: tableState.page,
                            });
                          } else if (tableState.page < pagination.page) {
                            setPageState({
                              offset:
                                pagination.offset - props.globalRowsPerPage,
                              limit: props.globalRowsPerPage,
                              page: tableState.page,
                            });
                          }
                          break;
                        case "viewColumnsChange":
                          setTableStatePersist(tableState);
                          break;
                      }
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </AdminPageContainer>
    </BasePage>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  orders: state.adminDashboard.consumerOrders
    ? state.adminDashboard.consumerOrders
    : [],
  customers: state.adminDashboard.customers
    ? state.adminDashboard.customers
    : [],
  products: state.adminDashboard.products ? state.adminDashboard.products : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getOrders: (filter: any) =>
    dispatch(AdminDashboardActions.getConsumerOrders(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerOrders);
