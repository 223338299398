import React from "react";
import { connect } from "react-redux";
import { Button, Grid, TextField, MenuItem } from "@mui/material";
import {
  CommonStyles,
  StyledSmallModal,
  getMuiTheme,
} from "../../hooks/styles";
import Loading from "../../components/loading";
import {
  StyledFormControl,
  StyledTextField,
} from "../../hooks/styles/formStyles";
import AutoComplete from "../../components/auto-complete";
import { validateEmail } from "../../utils/formator";
import { toast } from "react-toastify";

interface OrderFormProps {
  authUser: any;
  order: any;
  customers: any[];
  products: any[];
  getOrders: () => void;
  updateOrder: (payload: any) => void;
  createOrder: (payload: any) => void;
  closeModal: () => void;
}

const OrderFormModal: React.FC<OrderFormProps> = (props) => {
  const classes = CommonStyles(getMuiTheme("light"));

  const getInitInputs = () => ({
    customerId: props.order?.customerId || "",
    productId: props.order?.productId || "",
    quantity: props.order?.quantity || "",
    // requestedDeliveryDate: props.order?.requestedDeliveryDate || [],
  });

  const [inputs, setInputs] = React.useState<any>(getInitInputs());
  const [formErrors, setFormErrors] = React.useState<any>({});

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const handleCancel = () => {
    props.closeModal();
  };

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value });
    setFormErrors({ ...formErrors, [key]: null });
    setSubmitError("");
  };

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, " $1").trim();
  };

  const handleSave = () => {
    const errors: any = {};
    for (let [key, value] of Object.entries(inputs)) {
      if (value === "") {
        errors[key] = `${formatString(key)} is required`;
      }
      if (key === "quantity" && (value as number) < 1) {
        errors[key] = `${formatString(key)} must be greater than 0`;
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      props.getOrders();
      props.closeModal();
    };

    const onError = (errorMessage: string | null) => {
      setIsLoading(false);
      toast.error(
        errorMessage
          ? errorMessage
          : `Failed to ${props.order ? "update" : "submit"} order.`
      );
    };

    const requestBody = props.order
      ? getEditRequestBody()
      : getAddRequestBody();

    delete requestBody.createdAt;

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError,
    };
    const submitFn = props.order ? props.updateOrder : props.createOrder;
    submitFn(payload);
  };

  const getAddRequestBody = () => ({
    customerId: inputs.customerId,
    productId: inputs.productId,
    email: props.authUser.email,
    quantity: +inputs.quantity,
  });

  const getEditRequestBody = () => ({
    ...props.order,
    ...{
      customerId: inputs.customerId,
      productId: inputs.productId,
      email: props.authUser.email,
      quantity: inputs.quantity,
    },
  });

  return (
    <StyledSmallModal>
      <div>
        <h3 id="simple-modal-title">
          {props.order ? "Edit Order" : "New Order"}
        </h3>
        <Grid container>
          <Grid item xs={12}>
            <StyledFormControl>
              <AutoComplete
                label="Customer"
                options={(props.customers || []).map((customer: any) => {
                  return {
                    label: customer.customerName,
                    value: customer.customerId,
                  };
                })}
                value={(props.customers || [])
                  .map((customer: any) => {
                    return {
                      label: customer.customerName,
                      value: customer.customerId,
                    };
                  })
                  .find(
                    (customer: any) => customer.value === inputs.customerId
                  )}
                onChange={(event: any, customer: any) => {
                  handleSetInputs("customerId", customer?.value || "");
                }}
                error={formErrors.customerId ? true : false}
                errorText={
                  formErrors.customerId ? "A customer is required" : ""
                }
              />
            </StyledFormControl>
            <StyledFormControl>
              <AutoComplete
                label="Product"
                options={(props.products || []).map((product: any) => {
                  return { label: product.name, value: product.sapProductId };
                })}
                value={(props.products || [])
                  .map((product: any) => {
                    return { label: product.name, value: product.sapProductId };
                  })
                  .find((product: any) => product.value === inputs.productId)}
                onChange={(event: any, newValue: any) => {
                  handleSetInputs("productId", newValue?.value || "");
                }}
                error={formErrors.productId ? true : false}
                errorText={formErrors.productId ? "A customer is required" : ""}
              />
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl>
              <StyledTextField
                type="number"
                label="Quantity"
                variant="outlined"
                value={inputs.quantity}
                InputProps={{ inputProps: { min: 1 } }}
                error={formErrors.quantity ? true : false}
                helperText={formErrors.quantity ? formErrors.quantity : ""}
                onChange={(event) => {
                  handleSetInputs("quantity", event.target.value);
                }}
                required
              />
            </StyledFormControl>
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ? (
            <Loading message="" />
          ) : (
            <Grid item xs={12}>
              <StyledFormControl>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  variant="contained"
                  color="secondary"
                >
                  {props.order ? "SAVE" : "SUBMIT"}
                </Button>
              </StyledFormControl>
              <StyledFormControl>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  variant="contained"
                  color="primary"
                >
                  CANCEL
                </Button>
              </StyledFormControl>
            </Grid>
          )}
        </Grid>
      </div>
    </StyledSmallModal>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  customers: state.adminDashboard.customers,
  products: state.adminDashboard.products,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormModal);
