import { FormControl, TextField, styled } from "@mui/material";

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: 100,
  backgroundColor: "white",
}));
