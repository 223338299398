import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {QUESTION_CATEGORY, QUESTION_TYPE} from "../../../constants/QuestionConstants";
import DatePicker from "../mui/DatePicker";
import {
  formatPhoneNumber,
  formatZip,
  validateEmail,
  validatePhone,
  validateZip,
} from "../../../utils/formator";
import {toast} from "react-toastify";
import {format} from "date-fns";
import {fromDateOnlyToLocalDateTime} from "../../../utils/date";
import SelectFormControl from "../layout/SelectFormControl";
import { theme } from "../../../themes/naloxoneRightNow";
import Recaptcha from "../Recaptcha";

const HALF_WIDTH_COMPONENTS = ["State", "Zip Code"];

const SHIPPING_ADDRESS_MAX_CHARS = 40;
const SHIPPING_ADDRESS2_MAX_CHARS = 60;
const PERSONAL_INFO_ADDRESS_MAX_CHARS = 30;

function SubForm(props) {
  const {
    formSchema,
    nextRequestPayload,
    handleFieldChange,
    setNextButtonDisabledForceFully,
    allStates,
    nextButtonDisabled,
    setRecaptchaPool,
  } = props;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * If there are errors in provided data
   * payload --> {id: errorMessage}
   * {30: "Invalid Zip Code"}
   */
  const [erroredFields, setErroredFields] = useState({});
  const [isShippingAndHomeSame, setShippingAndHomeSame] = useState(false);
  let questionSortedByIndex = formSchema?.questions?.sort((a, b) =>
    a?.questionIndex > b?.questionIndex ? 0 : -1
  );

  const firstNameObject = questionSortedByIndex[0];
  const lastNameObject = questionSortedByIndex[1];

  const fieldsFilteredAfterName = questionSortedByIndex?.filter(
    (question) =>
      question.id !== firstNameObject?.id && question.id !== lastNameObject?.id
  );
  const shippingInfoFields = fieldsFilteredAfterName?.filter(
    (question) => question?.category === QUESTION_CATEGORY.SHIPPING
  );
  const personalInfoFields = fieldsFilteredAfterName?.filter(
    (question) => question?.category === QUESTION_CATEGORY.DEMOGRAPHIC
  );

  const shippingFieldsQuestionIds = shippingInfoFields?.map(
    (field) => field?.id
  );

  useEffect(() => {
    console.log(erroredFields);
    setNextButtonDisabledForceFully(Object.keys(erroredFields)?.length > 0);
  }, [erroredFields]);

  /**
   * onBlur handler for freetext
   * @param questionId
   * @param questionLabel
   * @param value input's current value
   */
  const handleFieldBlur = (questionId, questionLabel, value) => {
    let questionLabelLCase = questionLabel?.toLowerCase();
    const error = { questionId: null, errorMessage: null };
    if (questionLabelLCase?.includes("phone")) {
      if (!validatePhone(value)) {
        error.questionId = questionId;
        error.errorMessage = "Invalid Phone Format";
      }
    }
    if (questionLabelLCase?.includes("zip")) {
      if (!validateZip(value)) {
        error.questionId = questionId;
        error.errorMessage = "Invalid Zip";
      }
    }
    if (questionLabelLCase?.includes("email")) {
      if (!validateEmail(value)) {
        error.questionId = questionId;
        error.errorMessage = "Invalid Email";
      }
    }

    if (Boolean(error.questionId)) {
      setErroredFields((prevState) => ({
        ...prevState,
        [error.questionId]: error.errorMessage,
      }));
    } else {
      removeQuestionFromErroredFields(questionId);
    }
  };

  function removeQuestionFromErroredFields(questionId) {
    if (questionId) {
      const erroredFieldsCopy = { ...erroredFields };
      delete erroredFieldsCopy[questionId];
      setErroredFields(erroredFieldsCopy);
    }
  }

  const renderNameComponent = (questionSchema) => {
    const {
      id: questionId,
      question: questionLabel,
      mandatory,
    } = questionSchema ?? {};
    const value =
      nextRequestPayload?.find((req) => req.questionId === questionId)?.value ??
      "";
    return (
      <Grid item xs={6}>
        <TextField
          id={questionLabel}
          label={questionLabel}
          value={value}
          required={mandatory}
          onChange={(e) => {
            let value = e?.target?.value;
            handleFieldChange({
              questionId,
              value,
              field: questionSchema.field,
            });
            if (erroredFields?.hasOwnProperty(questionId)) {
              //if this input has error, check validity on each change
              handleFieldBlur(questionId, questionLabel, value);
            }
          }}
          onBlur={() => handleFieldBlur(questionId, questionLabel, value)}
          error={erroredFields?.hasOwnProperty(questionId)}
          helperText={erroredFields[questionId]}
          fullWidth
          InputLabelProps={{ disableAnimation: true }}
        />
      </Grid>
    );
  };

  const getPersonalFormQuesIdFromShippingQueLabel = (questionLabel) => {
    return personalInfoFields?.find((field) =>
      field?.question.toLowerCase().includes(questionLabel?.toLowerCase())
    )?.id;
  };

  const getPersonalFormQuesIdFromShippingQueId = (questionId) => {
    const questionLabel = shippingInfoFields?.find(
      (field) => field.id === questionId
    )?.question;
    if (questionLabel)
      return personalInfoFields?.find((field) =>
        field?.question.toLowerCase().includes(questionLabel?.toLowerCase())
      )?.id;
    else return null;
  };

  const renderCorrectFieldComponent = (questionSchema, isShipping) => {
    const {
      id: questionId,
      field,
      question: questionLabel,
      questionType,
      choices,
      comment,
      mandatory,
    } = questionSchema ?? {};

    let gridWidth = isSmallScreen ? 12 : 6;
    if (
      HALF_WIDTH_COMPONENTS.findIndex((value) => value === questionLabel) !== -1
    )
      gridWidth = isSmallScreen ? 6 : 3;

    let isReadOnly = false;
    const shippingFormHasSimilarField =
      shippingInfoFields?.findIndex((field) =>
        field?.question.toLowerCase().includes(questionLabel?.toLowerCase())
      ) !== -1;

    if (Boolean(comment)) isReadOnly = true;

    let maxCharacters = null;
    if (isShipping) {
      //rule sets from https://fffdev.atlassian.net/browse/DHCS-1971
      if (questionLabel === "Address") {
        maxCharacters = SHIPPING_ADDRESS_MAX_CHARS;
      }
      if (questionLabel === "Address2") {
        maxCharacters = SHIPPING_ADDRESS2_MAX_CHARS;
      }
    } else {
      if (questionLabel === "Address") {
        maxCharacters = PERSONAL_INFO_ADDRESS_MAX_CHARS;
      }
    }

    switch (questionType) {
      case QUESTION_TYPE.US_STATE_FREETEXT:
      case QUESTION_TYPE.FREETEXT: {
        let value = "";
        const defaultValue = formSchema?.questions?.find(
          (req) => req.id === questionId
        )?.comment;
        isReadOnly =
          (!isShipping &&
            isShippingAndHomeSame &&
            shippingFormHasSimilarField) ||
          Boolean(defaultValue);
        value =
          nextRequestPayload?.find((req) => req.questionId === questionId)
            ?.value || "";
        return (
          <Grid key={questionId} item xs={gridWidth}>
            <TextField
              id={questionLabel}
              required={mandatory}
              label={questionLabel}
              placeholder={
                maxCharacters ? `Maximum ${maxCharacters} characters` : null
              }
              value={value ?? ""}
              inputProps={{
                readOnly: isReadOnly,
              }}
              onChange={(e) => {
                let value = e?.target?.value;
                let questionLabelLCase = questionLabel?.toLowerCase();
                if (questionLabelLCase?.includes("phone"))
                  value = formatPhoneNumber(value);
                if (questionLabelLCase?.includes("zip")) {
                  value = formatZip(value);
                }
                if (maxCharacters) {
                  value = value?.substring(0, maxCharacters - 1);
                }
                handleFieldChange({
                  questionId,
                  value,
                  field: questionSchema.field,
                });
                if (erroredFields?.hasOwnProperty(questionId)) {
                  //if this input has error, check validity on each change
                  handleFieldBlur(questionId, questionLabel, value);
                }

                if (
                  isShipping &&
                  isShippingAndHomeSame &&
                  shippingFormHasSimilarField
                ) {
                  //if home and shipping address same, fill the value from shipping address
                  handleFieldChange({
                    questionId:
                      getPersonalFormQuesIdFromShippingQueLabel(questionLabel),
                    value,
                    field: questionSchema.field,
                  });
                }
              }}
              onBlur={() => handleFieldBlur(questionId, questionLabel, value)}
              error={erroredFields?.hasOwnProperty(questionId)}
              helperText={erroredFields[questionId]}
              fullWidth
              InputLabelProps={{ disableAnimation: true }}
            />
          </Grid>
        );
      }
      case QUESTION_TYPE.LOCAL_DATE: {
        return (
          <Grid key={questionId} item xs={gridWidth}>
            <DatePicker
              id={questionLabel}
              label={questionLabel}
              value={fromDateOnlyToLocalDateTime(
                nextRequestPayload?.find((req) => req.questionId === questionId)
                  ?.value
              )}
              required={mandatory}
              disableFuture
              onError={(error) => {
                if (error)
                  setErroredFields((prevState) => ({
                    ...prevState,
                    [questionId]: "Invalid date",
                  }));
                else removeQuestionFromErroredFields(questionId);
              }}
              error={erroredFields?.hasOwnProperty(questionId)}
              onChange={(dateString) => {
                if (dateString) {
                  if (dateString.toString().toLowerCase() !== "invalid date") {
                    if (dateString?.getUTCFullYear() > 1800)
                      handleFieldChange({
                        questionId,
                        value: format(dateString, "yyyy-MM-dd"),
                        field: questionSchema.field,
                      });
                  } else {
                    handleFieldChange({
                      questionId,
                      value: "Invalid Date",
                      field: questionSchema.field,
                    });
                  }
                } else {
                  handleFieldChange({
                    questionId,
                    value: null,
                    field: questionSchema.field,
                  });
                }
              }}
              helperText={erroredFields[questionId]}
            />
          </Grid>
        );
      }
      case QUESTION_TYPE.US_STATES_DROPDOWN:
      case QUESTION_TYPE.DROPDOWN: {
        let dropDownValue = "";
        isReadOnly =
          !isShipping && isShippingAndHomeSame && shippingFormHasSimilarField;
        dropDownValue = nextRequestPayload?.find(
          (req) => req.questionId === questionId
        )?.choiceIds;
        if (typeof dropDownValue === "string")
          dropDownValue = Array.of(
            choices?.find((choice_) => choice_.choice === dropDownValue)?.id
          );

        return (
          <Grid key={questionId} item xs={gridWidth}>
            <SelectFormControl variant={`outlined`} fullWidth>
              <InputLabel
                id={questionLabel + questionId}
                required={mandatory}
                disableAnimation
              >
                {questionLabel}
              </InputLabel>
              <Select
                label={questionLabel}
                labelId={questionLabel + questionId}
                onChange={(e) => {
                  const val = e?.target?.value;
                  handleFieldChange({
                    questionId,
                    selectedChoice: val,
                    field: questionSchema.field,
                  });
                }}
                required={mandatory}
                readOnly={isReadOnly}
                value={
                  dropDownValue
                    ? dropDownValue.length > 0
                      ? dropDownValue
                      : ""
                    : ""
                }
                fullWidth
              >
                {choices
                  ?.sort((a, b) => (a?.position > b?.position ? 1 : -1))
                  ?.map((choiceSchema) => (
                    <MenuItem key={choiceSchema?.id} value={choiceSchema?.id}>
                      {choiceSchema?.choice}
                    </MenuItem>
                  ))}
              </Select>
            </SelectFormControl>
          </Grid>
        );
      }
      case QUESTION_TYPE.RADIO_BUTTON: {
        return (
          <Grid key={questionId} item xs={gridWidth}>
            <FormControl>
              <FormLabel
                sx={{
                  "&.Mui-focused": {
                    color: "rgba(0,0,0,0.6);",
                  },
                }}
                required={mandatory}
                id={questionLabel}
              >
                {questionLabel}
              </FormLabel>
              <RadioGroup
                row
                onChange={(e) => {
                  const val = e?.target?.value;
                  handleFieldChange({
                    questionId,
                    selectedChoice: val,
                    field: questionSchema.field,
                  });
                }}
              >
                {choices
                  ?.sort((a, b) => (a?.position > b?.position ? 1 : -1))
                  ?.map((choiceSchema) => (
                    <FormControlLabel
                      key={choiceSchema?.id}
                      value={choiceSchema?.id}
                      control={<Radio size="small" color="secondary" />}
                      label={choiceSchema.choice}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        );
      }
      case QUESTION_TYPE.CHECKBOX: {
        const value = nextRequestPayload?.find(
          (req) => req.questionId === questionId
        )?.value;
        return (
          <Grid key={questionId} item xs={12}>
            <FormControlLabel
              key={questionId}
              value={Boolean(value)}
              name={questionLabel}
              control={
                <Checkbox
                  onChange={(e) => {
                    const val = e?.target?.checked;
                    handleFieldChange({ questionId, value: val });
                    handleShippingAndHomeAddressSame(val);
                    setShippingAndHomeSame(val);
                  }}
                />
              }
              label={questionLabel}
            />
          </Grid>
        );
      }
      case QUESTION_TYPE.RECAPTCHA: {
        return (
          <Grid key={questionId} item xs={12}>
            <Recaptcha
              onVerify={(response) =>
                handleFieldChange({
                  questionId,
                  value: response,
                  field: questionSchema.field,
                })
              }
              onExpire={() => {
                toast.error("Recaptcha expired. Please verify again.");
                handleFieldChange({ questionId, value: null }); //reset the value of this field
              }}
              setRecaptchaPool={setRecaptchaPool}
              questionId={questionId}
            />
          </Grid>
        );
      }
    }
  };

  /**
   * If Shipping and Home addresses are same, auto fill the address, city, state, zip inside Personal info
   *
   */
  const handleShippingAndHomeAddressSame = (status) => {
    if (status) {
      shippingFieldsQuestionIds.forEach((questionId) => {
        //similar shipping form field
        const shippingInfoValuePayload = nextRequestPayload?.find(
          (req) => req.questionId === questionId
        );
        const personalFormQuesId =
          getPersonalFormQuesIdFromShippingQueId(questionId);
        const isDropDown = shippingInfoValuePayload?.choiceIds?.length > 0;
        const isStateDropDown = shippingInfoFields?.some(
          (field) =>
            field?.id === questionId &&
            field?.questionType === QUESTION_TYPE.US_STATE_FREETEXT
        );
        let personalInfoFormField = personalInfoFields.find(
          (field) => field.questionId === personalFormQuesId
        );

        // While copying the values from Shipping address to Personal info address field, only paste the 30 characters.
        // rule sets from https://fffdev.atlassian.net/browse/DHCS-1971
        const isAddressField = personalInfoFormField?.question === "Address";

        if (personalFormQuesId) {
          if (isStateDropDown) {
            handleFieldChange({
              questionId: personalFormQuesId,
              selectedChoice: Array.of(
                allStates?.find(
                  (state) => state?.choice === shippingInfoValuePayload.value
                )?.id
              ),
              field: shippingInfoValuePayload.field
                ? shippingInfoValuePayload.field.replace("shipping", "personal")
                : undefined,
            });
          } else if (isDropDown) {
            handleFieldChange({
              questionId: personalFormQuesId,
              selectedChoice: shippingInfoValuePayload.choiceIds,
              field: shippingInfoValuePayload.field
                ? shippingInfoValuePayload.field.replace("shipping", "personal")
                : undefined,
            });
          } else {
            handleFieldChange({
              questionId: personalFormQuesId,
              value: isAddressField
                ? shippingInfoValuePayload?.value?.substring(
                    0,
                    PERSONAL_INFO_ADDRESS_MAX_CHARS - 1
                  )
                : shippingInfoValuePayload.value,
              field: shippingInfoValuePayload.field
                ? shippingInfoValuePayload.field.replace("shipping", "personal")
                : undefined,
            });
          }
        }
      });
    }
  };

  return (
    <Box>
      <FormLabel>{formSchema?.formTitle}</FormLabel>
      <Divider sx={{ mt: 0, mb: 4 }} />
      <Box mb={4}>
        <Grid container spacing={4}>
          {renderNameComponent(firstNameObject)}
          {renderNameComponent(lastNameObject)}
        </Grid>
      </Box>
      <Box mb={4}>
        <Typography
          fontWeight={"bold"}
          sx={{ fontSize: 18, color: "#2C3944", marginBottom: 2 }}
        >
          Shipping Information
        </Typography>
        <Grid container spacing={4}>
          {shippingInfoFields.map((questionSchema) =>
            renderCorrectFieldComponent(questionSchema, true)
          )}
        </Grid>
      </Box>
      <Box mb={4}>
        <Typography
          fontWeight={"bold"}
          sx={{ fontSize: 18, color: "#2C3944", marginBottom: 2 }}
        >
          Personal Information
        </Typography>
        <Grid container spacing={4}>
          {personalInfoFields.map((questionSchema) =>
            renderCorrectFieldComponent(questionSchema, false)
          )}
        </Grid>
      </Box>
      {nextButtonDisabled && (
        <Box mb={4}>
          <Alert severity={"error"}>
            Fields marked with asterisk (
            <span style={{ textAlign: "start" }}>*</span>) are required. Please
            provide all required values to enable the 'Next' button.
          </Alert>
        </Box>
      )}
    </Box>
  );
}

SubForm.propTypes = {
  formSchema: PropTypes.shape({
    isForm: PropTypes.bool,
    formTitle: PropTypes.string,
    questions: PropTypes.array,
  }),
  nextRequestPayload: PropTypes.array.isRequired,
  /**
   * Handle fields value change. Takes the questionId, selectedChoice and value (for FREETEXT)
   */
  handleFieldChange: PropTypes.func.isRequired,
  nextButtonDisabled: PropTypes.bool,
  setNextButtonDisabledForceFully: PropTypes.func.isRequired,
  allStates: PropTypes.array.isRequired,
};

export default SubForm;
