import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import BasePage from "../common/base";
import AdminPageContainer from "../../components/admin-components/page-container";
import { AdminDashboardActions } from "../../redux/actions";
import ActionsMenu from "../../components/actions-menu";
import { Button, FormControl, Modal } from "@mui/material";
import MUIDataTable from "mui-datatables";
import dataList from "../../constants/dataList";
import Loading from "../../components/loading";
import CustomerFormModal from "./customer-form.modal";
import SearchFilter from "../../components/searchFilter";

const Customers = (props: any) => {
  const firstRender = React.useRef(false);
  const [modalOpen, setModalOpen] = React.useState<string>("");
  const [filters, setFilters] = React.useState<any>({});
  const [sortOrder, setSortOrder] = React.useState(null as any);
  const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
  const [pagination, setPageState] = React.useState({
    offset: 0,
    limit: props.globalRowsPerPage,
    page: 0,
  });

  const renderActionsMenu: any = (tableMeta: any) => {
    const actionItems = [
      {
        onClick: () => {
          // setCustomerToEdit(props.customers[tableMeta.rowIndex]);
          setModalOpen("EDIT");
        },
        id: "edit-customer",
        title: "Edit",
      },
      // {
      //   onClick: () => {
      //     const thisUser = props.users[tableMeta.rowIndex];
      //     setDeleteId(thisUser.id);
      //     setDeleteEmail(thisUser.email);
      //     setModalOpen("DELETE");
      //   },
      //   id: "delete-user",
      //   title: "Delete",
      // }
    ];
    return <ActionsMenu actionItems={actionItems} />;
  };

  const columnData = [
    {
      label: "Customer",
      name: "customerName",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Address",
      name: "address",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Phone",
      name: "phone",
      change: true,
      viewColumns: true,
      display: true,
    },
    {
      label: "Account Type",
      name: "accountType",
      change: true,
      viewColumns: true,
      display: true,
    },
    // {
    //   label: "",
    //   name: "",
    //   change: false,
    //   viewColumns: false,
    //   display: true,
    //   sort: false,
    //   customBodyRender: (value: any, tableMeta: any) => {
    //     return renderActionsMenu(tableMeta);
    //   },
    // },
  ];

  const getTableColumns = () => {
    return columnData.map((column: any, index: number) => {
      return {
        label: column.label,
        name: column.name,
        options: {
          filter: true,
          display: tableStatePersist
            ? tableStatePersist.columns[index].display
            : column.display,
          change: column.change,
          sort: column.sort ?? true,
          viewColumns: column.viewColumns,
          ...(column.customBodyRender && {
            customBodyRender: column.customBodyRender,
          }),
        },
      };
    });
  };

  const formatTableData = (data: any[]) => {
    return data.map((row: any) => {
      console.log("ROW", row);
      return {
        customerName: row.customerName,
        address: `${row.street} ${row.city}, ${row.state} ${row.zip}`,
        phone: row.phone,
        accountType: row.accountType,
      };
    });
  };

  React.useEffect(() => {
    if (Object.keys(filters).length === 0) filterCustomers();
  }, [filters]);

  const getFilter = (reset?: boolean) => {
    let payloadFilter: any = {
      where: { and: [] }, //filters ultimately end up here
    };

    Object.keys(filters).forEach(
      (filterKey: string) =>
        filters[filterKey] &&
        payloadFilter.where.and.push({
          [filterKey]: { like: `%${filters[filterKey]}%` },
        })
    );

    return JSON.stringify(payloadFilter);
  };

  const filterCustomers = (reset?: boolean) => {
    props.getCustomers(getFilter());
  };

  const showLoadingMsg = () => !props.authUser && props.customers.length === 0;

  const handleModalClose = () => {
    setModalOpen("");
    // setCustomerToEdit(null);
  };

  const triggerAddModal = () => {
    // setCustomerToEdit(null);
    setModalOpen("ADD");
  };

  return (
    <BasePage title={"Customers"}>
      <AdminPageContainer>
        <div>
          {showLoadingMsg() ? (
            <div>
              <Loading message="" />
            </div>
          ) : (
            <div>
              {/* <div className={classes.searchWrap}>

			</div> */}
              <div style={{ marginBottom: "50px" }}>
                {props.uiCustomers.length > 0 && (
                  <SearchFilter
                    config={[
                      {
                        label: "Customer",
                        name: "customerName",
                        type: "autoComplete",
                        selectOptions: props.uiCustomers.map(
                          (customer: any) => ({
                            label: customer.customerName,
                            value: customer.customerName,
                          })
                        ),
                        renderOptionLabel: (option: any) => {
                          return option.value;
                        },
                      },
                      {
                        label: "Phone",
                        name: "phone",
                        type: "text",
                      },
                      {
                        label: "Account Type",
                        name: "accountType",
                        type: "text",
                      },
                    ]}
                    onFilterChange={setFilters}
                    filters={filters}
                    handleSearch={() => filterCustomers()}
                    resetFilters={() => setFilters({})}
                  />
                )}
              </div>

              {props.authUser?.claims.role === "superAdmin" && (
                <div>
                  <FormControl>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        triggerAddModal();
                      }}
                    >
                      Add Customer
                    </Button>
                  </FormControl>
                </div>
              )}
              <div style={{ marginTop: 20 }}>
                <MUIDataTable
                  data={formatTableData(props.customers)}
                  columns={getTableColumns()}
                  options={{
                    rowsPerPage: props.globalRowsPerPage,
                    rowsPerPageOptions: dataList.PageLimitOptions,
                    onChangeRowsPerPage: (numberOfRows: number) => {
                      setPageState({ ...pagination, limit: numberOfRows });
                    },
                    filterType: "dropdown",
                    responsive: "simple",
                    filter: false,
                    search: false,
                    download: true,
                    print: true,
                    selectableRows: "none",
                    serverSide: false,
                    page: pagination.page,
                    count: props.totalUsers,
                    onColumnSortChange: (
                      changedColumn: string,
                      direction: string
                    ) => {
                      setSortOrder(
                        `${changedColumn} ${direction.toUpperCase()}`
                      );
                    },
                    onTableChange: (tableAction: any, tableState: any) => {
                      switch (tableAction) {
                        case "changePage":
                          if (tableState.page > pagination.page) {
                            setPageState({
                              offset:
                                pagination.offset + props.globalRowsPerPage,
                              limit: props.globalRowsPerPage,
                              page: tableState.page,
                            });
                          } else if (tableState.page < pagination.page) {
                            setPageState({
                              offset:
                                pagination.offset - props.globalRowsPerPage,
                              limit: props.globalRowsPerPage,
                              page: tableState.page,
                            });
                          }
                          break;
                        case "viewColumnsChange":
                          setTableStatePersist(tableState);
                          break;
                      }
                    },
                  }}
                />
              </div>
              <Modal
                open={["EDIT", "ADD"].includes(modalOpen)}
                onClose={(e: any, reason: string) => {
                  if (reason === "backdropClick") return;
                  handleModalClose();
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div>
                  <CustomerFormModal
                    getCustomers={filterCustomers}
                    customer={null}
                    updateCustomer={() => {}}
                    createCustomer={props.addCustomer}
                    closeModal={handleModalClose}
                  />
                </div>
              </Modal>
            </div>
          )}
        </div>
      </AdminPageContainer>
    </BasePage>
  );
};

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  customers: state.adminDashboard.customers
    ? state.adminDashboard.customers
    : [],
  uiCustomers: state.ui.customers || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getCustomers: (filter: any) =>
    dispatch(AdminDashboardActions.getCustomers(filter)),
  addCustomer: (customerId: string) =>
    dispatch(AdminDashboardActions.addCustomer(customerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
