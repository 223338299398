import { makeStyles } from "@mui/styles";

export const ChartStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    marginTop: "50px",
    padding: "40px",
    backgroundColor: "#fff",
  },
}));
export default ChartStyles;
