import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {History} from "history";
import * as uiReducer from "./ui.reducer";
import * as userManagementReducer from "./user-management.reducer";
import * as formStepsReducer from "./form-steps";
import * as adminDashboardReducer from "./admin-dashboard.reducer";

const createRootReducer = (history: History<any>) =>
  combineReducers(
    Object.assign(
      {router: connectRouter(history)},
      uiReducer,
      userManagementReducer,
      formStepsReducer,
      adminDashboardReducer
    )
  );
export default createRootReducer;
