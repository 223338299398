const SegoeUiWoff = "../assets/fonts/Segoe-UI.woff";
const SegoeUiBoldWoff = "../assets/fonts/Segoe-UI-Bold.woff";
const SegoeUiBoldItalicWoff = "../assets/fonts/Segoe-UI-Bold-Italic.woff";
const SegoeUiItalicWoff = "../assets/fonts/Segoe-UI-Italic.woff";

const fonts = {
  segoeUi: {
    regular: {
      fontFamily: `'Segoe UI Regular', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `normal`,
      fontWeight: 400,
      src: SegoeUiWoff,
    },
    bold: {
      fontFamily: `'Segoe UI Bold', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `normal`,
      fontWeight: 600,
      src: SegoeUiBoldWoff,
    },
    boldItalic: {
      fontFamily: `'Segoe UI Bold Italic', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `italic`,
      fontWeight: 600,
      src: SegoeUiBoldItalicWoff,
    },
    italic: {
      fontFamily: `'Segoe UI Italic', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `italic`,
      fontWeight: 400,
      src: SegoeUiItalicWoff,
    },
  },
};

export default fonts;
