import { call, put, select } from "redux-saga/effects";
import { AdminDashboardActions } from "../actions";
import { AdminDashboardService } from "../services/admin-dashboard.service";
import { toast } from "react-toastify";
import { QuotaDataProps } from "../../interfaces/quota";

export function* getQuotas() {
  try {
    yield put(AdminDashboardActions.setQuotasLoading({ isLoading: true }));
    const { data } = yield call(AdminDashboardService.getInstance().getQuotas);
    yield put(AdminDashboardActions.setQuotas(data));
    yield put(AdminDashboardActions.setQuotasLoading({ isLoading: false }));
  } catch (e) {
    // TODO: Handle error
    console.log(e);
    toast.error("Something went wrong, please refresh the page and try again.");
    yield put(AdminDashboardActions.setQuotasLoading({ isLoading: false }));
  }
}

export function* createQuota(action: any) {
  try {
    yield put(AdminDashboardActions.setQuotasLoading({ isLoading: true }));
    yield call(AdminDashboardService.getInstance().createQuota, action.payload);
    yield put(AdminDashboardActions.getQuotas());
  } catch (e) {
    // TODO: Handle error
    console.log(e);
  }
}

export function* deleteQuota(action: any) {
  try {
    yield put(AdminDashboardActions.setIsDeletingQuota(true));
    yield call(AdminDashboardService.getInstance().deleteQuota, action.payload);
    yield put(AdminDashboardActions.setIsDeletingQuota(false));
    let { quotas } = yield select((state) => state.adminDashboard);
    yield put(
      AdminDashboardActions.setQuotas(
        quotas?.filter(
          (quota: QuotaDataProps) => quota.state !== action.payload
        )
      )
    );
  } catch (e) {
    // TODO: Handle error
    yield put(AdminDashboardActions.setIsDeletingQuota(false));
    console.log(e);
    toast.error("Failed to delete, please try again.");
  }
}

export function* updateQuota(action: any) {
  try {
    yield put(AdminDashboardActions.setIsUpdatingQuota(true));
    yield call(AdminDashboardService.getInstance().updateQuota, {
      state: action.payload.state,
      quantity: action.payload.quantity,
    });

    yield getQuotas();
    yield put(AdminDashboardActions.setIsUpdatingQuota(false));
    action.payload.onSuccess();
  } catch (e) {
    // TODO: Handle error
    yield put(AdminDashboardActions.setIsUpdatingQuota(false));
    console.log(e);
    toast.error("Failed to update, please try again.");
  }
}

export function* getProgress(action: any) {
  try {
    yield put(AdminDashboardActions.setProgressLoading(true));
    const { data } = yield call(
      AdminDashboardService.getInstance().getOrdersProgress,
      action.payload || []
    );
    yield put(AdminDashboardActions.setProgress(data));
    yield put(AdminDashboardActions.setProgressLoading(false));
  } catch (e) {
    // TODO: Handle error
    console.log(e);
    toast.error("Something went wrong, please refresh the page and try again.");
    yield put(AdminDashboardActions.setProgressLoading(false));
  }
}

export function* getCustomers(action: any) {
  try {
    const { data } = yield call(
      AdminDashboardService.getInstance().getCustomers,
      [{ key: "filter", value: action.payload }]
    );
    yield put(AdminDashboardActions.setCustomers(data));
  } catch (e) {
    // TODO: Handle error
    console.log(e);
    toast.error("Something went wrong, please refresh the page and try again.");
  }
}

export function* addCustomer(
  action: ReturnType<typeof AdminDashboardActions.addCustomer>
) {
  try {
    yield call(
      AdminDashboardService.getInstance().addCustomer,
      action.payload.requestBody.customerId
    );
    action.payload.success();
  } catch (err) {
    action.payload.error();
    if (err instanceof Error) {
      // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
    }
  }
}

export function* getBulkOrders(action: any) {
  try {
    const { data } = yield call(
      AdminDashboardService.getInstance().getBulkOrders,
      [{ key: "filter", value: action.payload }]
    );
    yield put(AdminDashboardActions.setBulkOrders(data));
  } catch (e) {
    // TODO: Handle error
    console.log(e);
    toast.error("Something went wrong, please refresh the page and try again.");
  }
}

export function* getConsumerOrders(action: any) {
  try {
    const { data } = yield call(
      AdminDashboardService.getInstance().getConsumerOrders,
      [{ key: "filter", value: action.payload }]
    );
    yield put(AdminDashboardActions.setConsumerOrders(data));
  } catch (e) {
    // TODO: Handle error
    console.log(e);
    toast.error("Something went wrong, please refresh the page and try again.");
  }
}

export function* createBulkOrder(
  action: ReturnType<typeof AdminDashboardActions.createBulkOrder>
) {
  try {
    yield call(
      AdminDashboardService.getInstance().createBulkOrder,
      action.payload.requestBody
    );
    action.payload.success();
  } catch (err: any) {
    let errorMessage = null;
    if (err?.response && err.response?.data && err.response.data?.error) {
      const { statusCode, message } = err.response.data.error;
      if (statusCode === 412) {
        errorMessage = message;
      }
    }
    action.payload.error(errorMessage);
  }
}

export function* updateBulkOrder(
  action: ReturnType<typeof AdminDashboardActions.updateBulkOrder>
) {
  try {
    yield call(
      AdminDashboardService.getInstance().updateBulkOrder,
      action.payload.requestBody
    );
    action.payload.success();
  } catch (err) {
    action.payload.error();
    if (err instanceof Error) {
      // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
    }
  }
}
